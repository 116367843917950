import React from "react";
import { Box, Flex, VStack, Text, CloseButton, useDisclosure, IconButton } from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";

type SuccessBannerProps = {
  title: string;
  message: string;
  onClose: () => void;
};

const SuccessBanner: React.FC<SuccessBannerProps> = ({ title, message, onClose }) => {
  const { isOpen: isVisible, onClose: closeBanner } = useDisclosure({ defaultIsOpen: true });

  const handleClose = () => {
    closeBanner();
    onClose();
  };

  if (!isVisible) return null;

  return (
    <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            zIndex="banner"
            transform={isVisible ? "translateY(0)" : "translateY(-100%)"}
            transition="transform 0.3s ease-in-out"
        >
       <Flex
                bg="purple.500"
                color="white"
                p={4}
                alignItems="center"
                justifyContent="center"
                boxShadow="md"
                borderBottomRadius="md"
                position="relative"
            >
                <Box textAlign="center">
                    <Text fontWeight="bold">{title}</Text>
                    <Text fontSize="sm">{message}</Text>
                </Box>
                <IconButton
                    icon={<CloseIcon />}
                    aria-label="Close notification"
                    size="sm"
                    variant="ghost"
                    color="white"
                    onClick={handleClose}
                    _hover={{ bg: "purple.600" }}
                    position="absolute"
                    right={2}
                    top="50%"
                    transform="translateY(-50%)"
                />
            </Flex>
        </Box>
  );
};

export default SuccessBanner;
