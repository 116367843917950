import { useState, useEffect } from "react";

export function useForwardGeocode(address?: string) {
  const [coords, setCoords] = useState<{ lat: number; lng: number }>();

  useEffect(() => {
    if (!address) return;
    let ignore = false;

    (async () => {
      try {
        // Replace this with your geocoding logic, e.g. using Radar.geocode()
        const geocoded = await fakeGeocodeApi(address);
        if (!ignore) setCoords(geocoded);
      } catch {
        // handle errors
      }
    })();

    return () => { ignore = true; };
  }, [address]);

  return coords;
}

// Example mock geocode function—replace or remove for real usage:
async function fakeGeocodeApi(addr: string) {
  return { lat: 40.7128, lng: -74.006 }; 
} 