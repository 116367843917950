import React from 'react';
import {
    Flex,
    Heading,
    HStack,
    Button,
    Avatar,
    IconButton,
    useColorModeValue,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverBody,
    Text,
    Box,
    Badge,
} from "@chakra-ui/react";
import { LogOutIcon as LogOut, MenuIcon, BellIcon } from "lucide-react";
import { AuthUser } from '../../context/auth';
import { useLogoutMutation, useUnreadNotificationsQuery, useMarkAsReadMutation } from '../../graphql/generated';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
    onOpen: () => void;
    user: AuthUser;
    title: string;
}

const useNotifications = (limit: number = 5) => {
    const { data, refetch } = useUnreadNotificationsQuery({
        variables: { limit, offset: 0 },
        fetchPolicy: 'network-only',
        pollInterval: 2000,
    });

    return { notifications: data?.notifications || [], refetch };
};

const useMarkNotificationAsRead = () => {
    const [markAsRead] = useMarkAsReadMutation();

    return async (notificationId: string) => {
        await markAsRead({ variables: { notificationId } });
    };
};

const Header: React.FC<HeaderProps> = ({ onOpen, user, title }: HeaderProps) => {
    const navigate = useNavigate();
    const cardBg = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("gray.400", "gray.700");
    const [logoutMutation] = useLogoutMutation();
    const { notifications, refetch } = useNotifications();
    const markNotificationAsRead = useMarkNotificationAsRead();
    const gradientBorder = useColorModeValue(
        "linear(to-r, purple.400, pink.400)",
        "linear(to-r, purple.600, pink.600)"
    );

    const handleNotificationsOpen = () => {
        refetch();
    };

    const handleLogout = async () => {
        await logoutMutation();
        window.location.reload();
    };

    const handleNotificationClick = async (notification: any) => {
        await markNotificationAsRead(notification.id);
        refetch();
        if (notification.link) {
            navigate(notification.link);
        }
    };

    return (
        <Flex
            as="header"
            align="center"
            justify="space-between"
            py="4"
            px="6"
            bg={cardBg}
            borderBottomWidth="1px"
            borderBottomColor={borderColor}
            position="sticky"
            top="0"
            zIndex="1"
        >
            <Flex align="center">
                <IconButton
                    icon={<MenuIcon />}
                    variant="ghost"
                    onClick={onOpen}
                    aria-label="Open Menu"
                    display={{ base: "inline-flex", md: "none" }}
                    mr="2"
                />
                <Heading 
                    as="h1" 
                    size={{ base: "md", md: "lg" }} 
                    color="purple.800"
                >
                    {title}
                </Heading>
            </Flex>
            <HStack spacing="4">
                <Popover placement="bottom" onOpen={handleNotificationsOpen}>
                    <PopoverTrigger>
                        <IconButton
                            aria-label="Notifications"
                            icon={
                                <Box position="relative">
                                    <BellIcon size={20} />
                                    {notifications.length > 0 && (
                                        <Badge
                                            position="absolute"
                                            top="-1"
                                            right="-1"
                                            borderRadius="full"
                                            bg="red.500"
                                            color="white"
                                            fontSize="0.6em"
                                            px="2"
                                            py="1px"
                                        >
                                            {notifications.length}
                                        </Badge>
                                    )}
                                </Box>
                            }
                            variant="ghost"
                        />
                    </PopoverTrigger>
                    <PopoverContent
                        width={{ base: '80vw', md: '280px' }}
                        mx="auto"
                        left={{ base: "50% !important", md: "unset" }}
                        transform={{ base: "translateX(-50%) !important", md: "translateX(-70%)" }}
                    >
                        <PopoverArrow />
                        <PopoverHeader fontWeight="bold">Notifications</PopoverHeader>
                        <PopoverBody maxH="300px" overflowY="auto">
                            {notifications.length === 0 ? (
                                <Text>No new notifications</Text>
                            ) : (
                                notifications.map((notification) => (
                                    <Box
                                        key={notification.id}
                                        mb="2"
                                        p="2"
                                        borderBottomWidth="1px"
                                        borderColor="gray.200"
                                        cursor="pointer"
                                        onClick={() => handleNotificationClick(notification)}
                                    >
                                        <Text fontWeight="bold" fontSize="sm">{notification.title}</Text>
                                        <Text fontSize="xs">{notification.content}</Text>
                                    </Box>
                                ))
                            )}
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                <Box
                    position="relative"
                    width="36px"
                    height="36px"
                    borderRadius="full"
                    bgGradient={gradientBorder}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Avatar
                        size="sm"
                        bg="purple.500"
                        color="white"
                        name={user.username}
                        src={user.profilePicture ? `${user.profilePicture}` : undefined}
                    />
                </Box>
                <Button variant="ghost" size="sm" onClick={handleLogout}>
                    <LogOut size={20} />
                </Button>
            </HStack>
        </Flex>
    );
};

export default Header;
