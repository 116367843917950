import React from 'react';
import { VStack, Box, useColorModeValue, Skeleton } from '@chakra-ui/react';
import QuickStats from '../../components/quick-stats-cards';
import ActiveTours from '../../components/tours-card';
import RecentActivity from '../../components/recent-activity-card';
import ParticipantsChat from '../../components/recent-messages-card';
import ShowChallenges from '../../components/challenges-card';
import { useAuth } from '../../context/auth';
import { LoadingProvider } from "../../context/loading";
import { GetChallengesDocument, GetHuntsDocument } from '../../graphql/generated';
import { useSmartPolling } from '../../hooks/useSmartPolling';


const DashboardContent: React.FC = () => {
  const { user } = useAuth();
  const userType = user?.userType || "FREE";
  const { loading: toursLoading, error: toursError } = useSmartPolling(GetHuntsDocument, {}, {
    baseInterval: 5000,
  });

  const { loading: challengesLoading, error: challengesError } = useSmartPolling(GetChallengesDocument, {}, {
    baseInterval: 5000,
  });

  if (toursLoading || challengesLoading) {
    return <Box p={6}><Skeleton height="400px" /></Box>;
  }

  if (toursError || challengesError) {
    return (
      <Box p={6}>
        <Skeleton height="400px" />
      </Box>
    );
  }

  return (
    <Box p={6}>
      <VStack spacing={6} align="stretch">
        <QuickStats userType={userType} />
        {/* Temporarily hidden:
        <TransactionCard />
        {userType === 'FREE' ? (
          <Flex direction={{ base: 'column', md: 'row' }} gap={6}>
            <Box flex="1">
              <BlurredCard title="Participants Chat" types={["PLUS"]}>
                <ParticipantsChat />
              </BlurredCard>
            </Box>
          </Flex>
        ) : (
          <>
            {userType === 'PLUS' && <ParticipantsChat />}
          </>
        )}
        */}
        <ActiveTours userType={userType} />
        <ShowChallenges userType={userType} />
        <ParticipantsChat />
        <RecentActivity userType={userType} />
      </VStack>
    </Box>
  );
};

const DashboardPage: React.FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  return (
    <LoadingProvider>
      <Box bg={bgColor} minH="100vh">
        <DashboardContent />
      </Box>
    </LoadingProvider>
  );
};

export default DashboardPage;