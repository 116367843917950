import React from "react"
import {
  HStack,
  Input,
  IconButton,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react"
import { Send } from "lucide-react"
import { isDarkColor } from "../helpers/color-util"

type MessageInputProps = {
  message: string
  setMessage: (message: string) => void
  sendMessage: () => void
  isSending: boolean
  onKeyPress: (e: React.KeyboardEvent) => void
  huntColor: string
}

export const MessageInput = ({
  message,
  setMessage,
  sendMessage,
  isSending,
  onKeyPress,
  huntColor,
}: MessageInputProps) => {
  const bgColor = useColorModeValue("white", "gray.800")
  const borderColor = useColorModeValue("gray.200", "gray.700")

  return (
    <HStack
      w="full"
      p={4}
      bg={bgColor}
      borderTop="1px"
      borderColor={borderColor}
      spacing={4}
      position="sticky"
      bottom={0}
    >
      <Input
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={onKeyPress}
        placeholder="Type your message..."
        size={{ base: "sm", md: "md" }}
        borderRadius="full"
        disabled={isSending}
        _focus={{
          borderColor: huntColor ?? "purple.500",
          boxShadow: `0 0 0 1px ${huntColor ?? "purple.500"}`,
        }}
      />
      <IconButton
        aria-label="Send message"
        icon={isSending ? <Spinner size="sm" /> : <Send size={20} />}
        color={"white"}
        bg={huntColor ?? "purple.500"}
        isRound
        size={{ base: "sm", md: "md" }}
        onClick={sendMessage}
        isDisabled={!message.trim() || isSending}
      />
    </HStack>
  )
} 