import React, { useEffect, useRef, useState } from 'react';
import { VStack, FormControl, FormLabel, Input, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Button, FormErrorMessage, Heading, Textarea, HStack, Box, Text, useColorModeValue, Spinner, InputGroup, InputRightElement, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, IconButton, Portal, SimpleGrid, ButtonGroup, useBreakpointValue } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { CreateChallengeInput} from '../graphql/generated';
import { AddIcon, DeleteIcon, CheckIcon, RepeatIcon } from '@chakra-ui/icons';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Wand2 } from 'lucide-react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import env from '../env';
import { useGenerateTextMutation, useGetGenerationsTodayQuery } from '../graphql/generated';
import { useToast } from '@chakra-ui/react';

const useTextGeneration = (
  name: keyof CreateChallengeInput,
  challenge: any,
  handleInputChange: (field: string, value: any) => void,
  hasRemainingCredits: boolean
) => {
  const [generateText] = useGenerateTextMutation();
  const [generatedText, setGeneratedText] = useState<string>("");
  const [editableText, setEditableText] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const toast = useToast();

  const generatePrompt = (action: string) => {
    const currentText = challenge[name] as string;
    const fieldType = name === 'title' ? 'title' : 'description';

    switch (action) {
      case 'generate':
        return `Generate a creative and engaging food location ${fieldType} that captures the unique aspects of a culinary destination.`;
      case 'longer':
        return `Make this food location ${fieldType} longer and more detailed while maintaining its essence: "${currentText}"`;
      case 'shorter':
        return `Make this food location ${fieldType} more concise while keeping the main points: "${currentText}"`;
      case 'rewrite':
        return `Rewrite this food location ${fieldType} in a different style while keeping the same meaning: "${currentText}"`;
      case 'improve':
        return `Improve this food location ${fieldType} by making it more engaging and appetizing: "${currentText}"`;
      default:
        return '';
    }
  };

  const handleGenerate = async (action: string) => {
    if (!hasRemainingCredits) {
      toast({
        title: "No credits remaining",
        description: "You've used all your AI generation credits for today.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsGenerating(true);
      const response = await generateText({
        variables: {
          input: generatePrompt(action)
        }
      });

      if (response.data?.generateText) {
        const text = response.data.generateText;
        setGeneratedText(text);
        setEditableText(text);
        setIsPopoverOpen(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message.replace(/^ApolloError: |^Error: /, "");
        toast({
          title: "Generation failed",
          description: message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const acceptGeneratedText = () => {
    handleInputChange(name, editableText);
    setGeneratedText("");
    setEditableText("");
  };

  return {
    generatedText,
    editableText,
    setEditableText,
    isGenerating,
    handleGenerate,
    acceptGeneratedText,
    setGeneratedText,
    isPopoverOpen,
    setIsPopoverOpen
  };
};


const useCurrentLocation = () => {
  const [location, setLocation] = useState<[number, number] | null>(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
          console.error("Error getting location:", error);
          // Default to US center if location access denied
          setLocation([-98.5795, 39.8283]);
        }
      );
    }
  }, []);

  return location;
};

type AddressSearchBoxProps = {
  value: string;
  onChange: (value: string) => void;
  accessToken: string;
};

const createGeocoder = (accessToken: string, currentLocation: [number, number]) => {
  return new MapboxGeocoder({
    accessToken,
    types: "address,poi",
    placeholder: "Search for an address",
    countries: "us",
    proximity: {
      longitude: currentLocation[0],
      latitude: currentLocation[1]
    },
    bbox: [-125.0, 24.396308, -66.93457, 49.384358],
    language: "en",
    marker: false // Disable default marker
  });
};

const injectCustomStyles = () => {
  const style = document.createElement("style");
  style.textContent = `
    .geocoder-container {
      position: relative;
      width: 100%;
      margin-bottom: 8px;
    }

    .mapboxgl-ctrl-geocoder {
      position: relative !important;
      box-shadow: none !important;
      max-width: 100% !important;
      width: 100% !important;
      min-width: unset !important;
      font-size: 16px;
    }

    .mapboxgl-ctrl-geocoder--input {
      height: var(--chakra-sizes-10) !important;
      padding: var(--chakra-space-3) var(--chakra-space-10) !important;
      border: 1px solid var(--chakra-colors-gray-300);
      border-radius: var(--chakra-radii-md);
      width: 100% !important;
      font-size: inherit;
      transition: all 0.2s;
    }

    .mapboxgl-ctrl-geocoder--input:focus {
      border-color: var(--chakra-colors-purple-500);
      box-shadow: 0 0 0 1px var(--chakra-colors-purple-500);
    }

    .suggestions-wrapper {
      position: absolute;
      width: 100%;
      bottom: 100%;
      left: 0;
      margin-bottom: 4px;
      z-index: 9999;
    }

    .mapboxgl-ctrl-geocoder .suggestions {
      position: relative !important;
      width: 100% !important;
      max-height: 200px;
      overflow-y: auto;
      border-radius: var(--chakra-radii-md);
      border: 1px solid var(--chakra-colors-gray-200);
      background-color: white;
      box-shadow: var(--chakra-shadows-lg);
    }

    .mapboxgl-ctrl-geocoder .suggestions > li {
      padding: var(--chakra-space-3) var(--chakra-space-4);
      cursor: pointer;
      font-size: 0.95em;
      border-bottom: 1px solid var(--chakra-colors-gray-100);
    }

    .mapboxgl-ctrl-geocoder .suggestions > li:last-child {
      border-bottom: none;
    }

    .mapboxgl-ctrl-geocoder .suggestions > li:hover,
    .mapboxgl-ctrl-geocoder .suggestions > .active {
      background-color: var(--chakra-colors-purple-50);
    }

    .mapboxgl-ctrl-geocoder--icon {
      top: 50% !important;
      transform: translateY(-50%) !important;
    }

    .mapboxgl-ctrl-geocoder--icon-search {
      left: var(--chakra-space-3) !important;
      width: 18px;
      height: 18px;
      fill: var(--chakra-colors-gray-500);
    }

    .mapboxgl-ctrl-geocoder--button {
      position: absolute;
      top: 50% !important;
      transform: translateY(-50%) !important;
      right: var(--chakra-space-3) !important;
      background: none;
      padding: 2px;
      border-radius: var(--chakra-radii-sm);
    }

    .mapboxgl-ctrl-geocoder--button:hover {
      background-color: var(--chakra-colors-gray-100);
    }

    @media (max-width: 480px) {
      .mapboxgl-ctrl-geocoder {
        font-size: 14px;
      }

      .mapboxgl-ctrl-geocoder--input {
        height: var(--chakra-sizes-9) !important;
        padding: var(--chakra-space-2) var(--chakra-space-9) !important;
      }

      .mapboxgl-ctrl-geocoder .suggestions {
        max-height: 150px;
      }

      .mapboxgl-ctrl-geocoder .suggestions > li {
        padding: var(--chakra-space-2) var(--chakra-space-3);
      }
    }
  `;
  document.head.appendChild(style);
  return style;
};

const AddressSearchBox = ({ value, onChange, accessToken }: AddressSearchBoxProps) => {
  const geocoderContainerRef = useRef<HTMLDivElement>(null);
  const geocoderRef = useRef<MapboxGeocoder | null>(null);
  const currentLocation = useCurrentLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!geocoderContainerRef.current || !currentLocation || !accessToken) return;

    const geocoderInstance = createGeocoder(accessToken, currentLocation);

    geocoderInstance.on("loading", () => setIsLoading(true));
    geocoderInstance.on("results", () => setIsLoading(false));
    geocoderInstance.on("error", () => setIsLoading(false));

    // Handle result selection
    geocoderInstance.on("result", (e) => {
      try {
        const selectedPlace = e.result.place_name;
        onChange(selectedPlace);
        
        // Explicitly update the input value
        const inputElement = geocoderContainerRef.current?.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLInputElement;
        if (inputElement) {
          inputElement.value = selectedPlace;
        }

        // Provide haptic feedback
        if (navigator.vibrate) {
          navigator.vibrate(50);
        }
      } catch (error) {
        console.error("Error handling geocoder result:", error);
      }
    });

    // Handle clear button click
    geocoderInstance.on("clear", () => {
      try {
        onChange("");
        const inputElement = geocoderContainerRef.current?.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLInputElement;
        if (inputElement) {
          inputElement.value = "";
        }
      } catch (error) {
        console.error("Error handling geocoder clear:", error);
      }
    });

    geocoderInstance.addTo(geocoderContainerRef.current);
    geocoderRef.current = geocoderInstance;

    // Set initial value if provided
    if (value) {
      const inputElement = geocoderContainerRef.current?.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = value;
      }
    }

    const style = injectCustomStyles();

    return () => {
      if (geocoderRef.current) {
        geocoderRef.current.onRemove();
        geocoderRef.current = null;
      }
      if (style?.parentNode) {
        style.parentNode.removeChild(style);
      }
    };
  }, [accessToken, currentLocation, onChange]);

  // Update input value when prop value changes
  useEffect(() => {
    if (geocoderContainerRef.current && value !== undefined) {
      const inputElement = geocoderContainerRef.current.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLInputElement;
      if (inputElement && inputElement.value !== value) {
        inputElement.value = value;
      }
    }
  }, [value]);

  return (
    <Box
      width="100%"
      position="relative"
      role="search"
      aria-label="Search for address"
      sx={{
        ".mapboxgl-ctrl-geocoder": {
          position: "relative !important",
        },
        ".suggestions-wrapper": {
          position: "absolute",
          width: "100%",
          bottom: "100%",
          left: 0,
          marginBottom: "4px",
          zIndex: 9999,
        }
      }}
    >
      <div
        ref={geocoderContainerRef}
        className="geocoder-container"
        aria-busy={isLoading}
      />
      {isLoading && (
        <Box
          position="absolute"
          right="40px"
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
        >
          <Spinner size="sm" color="purple.500" />
        </Box>
      )}
    </Box>
  );
};

type ChallengeFormProps = {
  challenge: any;
  handleInputChange: (field: string, value: any) => void;
  errors: any;
  handleQuizChange?: (index: number, field: string, value: any) => void;
  addQuizQuestion?: () => void;
  removeQuizQuestion?: (index: number) => void;
  addBasicGame?: () => void;
  removeBasicGame?: (index: number) => void;
  handleBasicGameChange?: (index: number, field: string, value: any) => void;
  setIsFormValid: (isValid: boolean) => void;
  isEditing?: boolean;
};

const ChallengeForm = ({ 
  challenge, 
  handleInputChange, 
  errors, 
  handleQuizChange, 
  addQuizQuestion, 
  removeQuizQuestion, 
  addBasicGame, 
  removeBasicGame, 
  handleBasicGameChange,
  setIsFormValid,
  isEditing = false
}: ChallengeFormProps) => {
  const validateForm = () => {
    const isValid = 
      !!challenge.title &&
      !!challenge.description &&
      !!challenge.address &&
      (challenge.gameType === 'QUIZ' 
        ? challenge.quiz && challenge.quiz.length > 0 && challenge.quiz.every((q: any) => 
            q.question && q.answers.length > 0 && q.correctAnswers.length > 0
          )
        : challenge.gameType === 'BASIC'
          ? challenge.basic && challenge.basic.length > 0 && challenge.basic.every((b: any) =>
              b.text && b.content && b.expectedContent
            )
          : true
      );
    
    setIsFormValid(isValid);
  };

  useEffect(() => {
    // Validate form when component mounts or challenge data changes
    validateForm();
  }, [challenge]);

  const { data: generationsData } = useGetGenerationsTodayQuery({
    pollInterval: 30000,
  });

  const hasRemainingCredits = (generationsData?.generationsToday.remaining ?? 0) > 0;

  const titleGeneration = useTextGeneration('title', challenge, handleInputChange, hasRemainingCredits);
  const descriptionGeneration = useTextGeneration('description', challenge, handleInputChange, hasRemainingCredits);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      p={6}
      bg={useColorModeValue('white', 'gray.800')}
      shadow="md"
      maxHeight="calc(100vh - 200px)"
      overflowY="auto"
    >
      <VStack spacing={6} align="stretch">
        <FormControl isInvalid={!!errors.title} isRequired>
          <FormLabel fontWeight="bold">Name</FormLabel>
          <InputGroup>
            <Input
              value={challenge.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              placeholder="Enter location name"
              bg="white"
              borderColor="gray.300"
              pr="40px"
            />
            <InputRightElement>
              <Box position="relative">
                <Popover
                  placement={useBreakpointValue({ base: "bottom", md: "right" })}
                  autoFocus={false}
                  closeOnBlur={true}
                  strategy="fixed"
                  gutter={8}
                  isLazy
                  lazyBehavior="keepMounted"
                  isOpen={titleGeneration.isPopoverOpen}
                  onClose={() => titleGeneration.setIsPopoverOpen(false)}
                >
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Generate text"
                      icon={<Wand2 size={16} />}
                      size="sm"
                      variant="ghost"
                      colorScheme="purple"
                      isLoading={titleGeneration.isGenerating}
                      onClick={() => titleGeneration.setIsPopoverOpen(true)}
                      isDisabled={!hasRemainingCredits}
                      title={!hasRemainingCredits ? "No AI credits remaining" : "Generate text"}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <Box
                      position="relative"
                      zIndex={2000}
                      sx={{
                        ".chakra-popover__content": {
                          zIndex: 2000,
                        },
                        ".chakra-popover__arrow": {
                          zIndex: 2000,
                        }
                      }}
                    >
                      <PopoverContent
                        width={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        maxWidth={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        borderRadius="xl"
                        shadow="xl"
                        _focus={{ outline: "none" }}
                        bg="white"
                        border="1px solid"
                        borderColor="gray.200"
                      >
                        <PopoverArrow bg="white" borderColor="gray.200" />
                        <PopoverBody p={4}>
                          <VStack spacing={2} align="stretch">
                            <Text fontSize="sm" fontWeight="bold" color="purple.600" mb={1}>
                              AI Text Generation
                            </Text>
                            <Button
                              size="sm"
                              leftIcon={<Wand2 size={14} />}
                              onClick={() => titleGeneration.handleGenerate('generate')}
                              isLoading={titleGeneration.isGenerating}
                              colorScheme="purple"
                              width="100%"
                            >
                              Generate New
                            </Button>
                            {challenge.title && (
                              <SimpleGrid columns={2} spacing={2}>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('longer')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Longer
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('shorter')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Shorter
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('rewrite')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Rewrite
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('improve')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Improve
                                </Button>
                              </SimpleGrid>
                            )}
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Box>
                  </Portal>
                </Popover>
              </Box>
            </InputRightElement>
          </InputGroup>
          {titleGeneration.generatedText && (
            <Box mt={2} p={3} borderRadius="md" borderWidth={1} borderColor="purple.200">
              <Input
                value={titleGeneration.editableText}
                onChange={(e) => titleGeneration.setEditableText(e.target.value)}
                fontSize="sm"
                mb={2}
                bg="white"
                _hover={{ bg: "white" }}
                _focus={{ bg: "white", borderColor: "purple.300" }}
              />
              <ButtonGroup size="sm" spacing={2}>
                <Button
                  colorScheme="purple"
                  leftIcon={<CheckIcon />}
                  onClick={titleGeneration.acceptGeneratedText}
                >
                  Accept
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    titleGeneration.setGeneratedText("");
                    titleGeneration.setEditableText("");
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    titleGeneration.setEditableText(titleGeneration.generatedText);
                  }}
                  leftIcon={<RepeatIcon />}
                >
                  Reset
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={!!errors.description} isRequired>
          <FormLabel fontWeight="bold">Description</FormLabel>
          <InputGroup>
            <Textarea
              value={challenge.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
              placeholder="Enter location description"
              bg="white"
              borderColor="gray.300"
              minHeight="100px"
              pr="40px"
            />
            <InputRightElement top="0" h="40px">
              <Box position="relative">
                <Popover
                  placement={useBreakpointValue({ base: "bottom", md: "right" })}
                  autoFocus={false}
                  closeOnBlur={true}
                  strategy="fixed"
                  gutter={8}
                  isLazy
                  lazyBehavior="keepMounted"
                  isOpen={descriptionGeneration.isPopoverOpen}
                  onClose={() => descriptionGeneration.setIsPopoverOpen(false)}
                >
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Generate text"
                      icon={<Wand2 size={16} />}
                      size="sm"
                      variant="ghost"
                      colorScheme="purple"
                      isLoading={descriptionGeneration.isGenerating}
                      onClick={() => descriptionGeneration.setIsPopoverOpen(true)}
                      isDisabled={!hasRemainingCredits}
                      title={!hasRemainingCredits ? "No AI credits remaining" : "Generate text"}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <Box
                      position="relative"
                      zIndex={2000}
                      sx={{
                        ".chakra-popover__content": {
                          zIndex: 2000,
                        },
                        ".chakra-popover__arrow": {
                          zIndex: 2000,
                        }
                      }}
                    >
                      <PopoverContent
                        width={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        maxWidth={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        borderRadius="xl"
                        shadow="xl"
                        _focus={{ outline: "none" }}
                        bg="white"
                        border="1px solid"
                        borderColor="gray.200"
                      >
                        <PopoverArrow bg="white" borderColor="gray.200" />
                        <PopoverBody p={4}>
                          <VStack spacing={2} align="stretch">
                            <Text fontSize="sm" fontWeight="bold" color="purple.600" mb={1}>
                              AI Text Generation
                            </Text>
                            <Button
                              size="sm"
                              leftIcon={<Wand2 size={14} />}
                              onClick={() => descriptionGeneration.handleGenerate('generate')}
                              isLoading={descriptionGeneration.isGenerating}
                              colorScheme="purple"
                              width="100%"
                            >
                              Generate New
                            </Button>
                            {challenge.description && (
                              <SimpleGrid columns={2} spacing={2}>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('longer')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Longer
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('shorter')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Shorter
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('rewrite')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Rewrite
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('improve')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Improve
                                </Button>
                              </SimpleGrid>
                            )}
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Box>
                  </Portal>
                </Popover>
              </Box>
            </InputRightElement>
          </InputGroup>
          {descriptionGeneration.generatedText && (
            <Box mt={2} p={3} borderRadius="md" borderWidth={1} borderColor="purple.200">
              <Textarea
                value={descriptionGeneration.editableText}
                onChange={(e) => descriptionGeneration.setEditableText(e.target.value)}
                fontSize="sm"
                mb={2}
                minHeight="100px"
                bg="white"
                _hover={{ bg: "white" }}
                _focus={{ bg: "white", borderColor: "purple.300" }}
              />
              <ButtonGroup size="sm" spacing={2}>
                <Button
                  colorScheme="purple"
                  leftIcon={<CheckIcon />}
                  onClick={descriptionGeneration.acceptGeneratedText}
                >
                  Accept
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    descriptionGeneration.setGeneratedText("");
                    descriptionGeneration.setEditableText("");
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    descriptionGeneration.setEditableText(descriptionGeneration.generatedText);
                  }}
                  leftIcon={<RepeatIcon />}
                >
                  Reset
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
        </FormControl>

          <FormControl 
            isInvalid={!!errors.address} 
            isRequired 
            flex={1} 
            position="relative"
            style={{ isolation: "isolate" }}
          >
            <FormLabel fontWeight="bold">Address</FormLabel>
            <Box 
              position="relative" 
              zIndex={1001}
              sx={{
                "&:focus-within": {
                  zIndex: 1002
                }
              }}
            >
              <AddressSearchBox
                accessToken={env.REACT_APP_MAPBOX_TOKEN}
                value={challenge.address}
                onChange={(result: string) => handleInputChange('address', result)}
              />
            </Box>
            {errors.address && <FormErrorMessage>{errors.address}</FormErrorMessage>}
          </FormControl>

        {challenge.gameType === 'QUIZ' && challenge.quiz && (
          <VStack spacing={6} align="stretch">
            <Heading size="md" fontWeight="bold">Quiz Questions</Heading>
            {challenge.quiz.map((q: any, index: number) => (
              <Box key={index} borderWidth="1px" borderRadius="md" p={4} bg="white">
                <HStack justifyContent="space-between" mb={4}>
                  <Heading size="sm" fontWeight="bold">Question {index + 1}</Heading>
                  <Button size="sm" colorScheme="red" onClick={() => removeQuizQuestion?.(index)}>
                    <DeleteIcon />
                  </Button>
                </HStack>

                <VStack spacing={4} align="stretch">
                  <FormControl isInvalid={!!errors[`quiz${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Question</FormLabel>
                    <Input
                      value={q.question}
                      onChange={(e) => handleQuizChange?.(index, 'question', e.target.value)}
                      placeholder="Enter question"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`quiz${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`quiz${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <Text fontWeight="bold">Answers</Text>
                  {q.answers.map((answer: any, answerIndex: number) => (
                    <FormControl key={answerIndex} isRequired>
                      <Input
                        value={answer}
                        onChange={(e) =>
                          handleQuizChange?.(
                            index,
                            'answers',
                            q.answers.map((a: any, i: number) => (i === answerIndex ? e.target.value : a))
                          )
                        }
                        placeholder={`Answer ${answerIndex + 1}`}
                        bg="white"
                        borderColor="gray.300"
                      />
                    </FormControl>
                  ))}

                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">Correct Answers</FormLabel>
                    <ReactSelect
                      isMulti
                      options={q.answers.map((answer: any, i: number) => ({ value: answer, label: answer }))}
                      value={q.correctAnswers.map((answer: any) => ({ value: answer, label: answer }))}
                      onChange={(selectedOptions) => {
                        const selectedAnswers = selectedOptions.map(option => option.value);
                        handleQuizChange?.(index, 'correctAnswers', selectedAnswers);
                      }}
                      placeholder="Select correct answers"
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: 'white',
                          borderColor: 'var(--chakra-colors-gray-300)',
                        }),
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold">Hint (optional)</FormLabel>
                    <Input
                      value={q.hint || ''}
                      onChange={(e) => handleQuizChange?.(index, 'hint', e.target.value)}
                      placeholder="Enter hint"
                      bg="white"
                      borderColor="gray.300"
                    />
                  </FormControl>
                </VStack>
              </Box>
            ))}
            <Button
              onClick={addQuizQuestion}
              leftIcon={<AddIcon />}
              variant="outline"
              colorScheme="blue"
            >
              Add Question
            </Button>
          </VStack>
        )}
        {challenge.gameType === 'BASIC' && challenge.basic && (
          // text, content, expectedContent
          <VStack spacing={6} align="stretch">
            <Heading size="md" fontWeight="bold">Basic Game</Heading>
            {challenge.basic.map((b: any, index: number) => (
              <Box key={index} borderWidth="1px" borderRadius="md" p={4} bg="white">
                <HStack justifyContent="space-between" mb={4}>
                  <Heading size="sm" fontWeight="bold">Basic Game {index + 1}</Heading>
                  <Button size="sm" colorScheme="red" onClick={() => removeBasicGame?.(index)}>
                    <DeleteIcon />
                  </Button>
                </HStack>
                <VStack spacing={4} align="stretch">
                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Text</FormLabel>
                    <Input
                      value={b.text}
                      onChange={(e) => handleBasicGameChange?.(index, 'text', e.target.value)}
                      placeholder="Enter text"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Content</FormLabel>
                    <Input
                      value={b.content}
                      onChange={(e) => handleBasicGameChange?.(index, 'content', e.target.value)}
                      placeholder="Enter content"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Expected Content</FormLabel>
                    <Input
                      value={b.expectedContent}
                      onChange={(e) => handleBasicGameChange?.(index, 'expectedContent', e.target.value)}
                      placeholder="Enter expected content"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>
                </VStack>
              </Box>
            ))}
            <Button
              onClick={addBasicGame}
              leftIcon={<AddIcon />}
              variant="outline"
              colorScheme="blue"
            >
              Add Basic Game
            </Button>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default ChallengeForm;
