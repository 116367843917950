const normalizeHexColor = (color: string): string => {
  const trimmed = color.trim();
  return trimmed.startsWith("#") ? trimmed : `#${trimmed}`;
};

const isValidHexColor = (color: string): boolean => {
  const hexPattern = /^#?([0-9A-Fa-f]{6})$/;
  return hexPattern.test(color);
};

export const isDarkColor = (color: string | null | undefined): boolean => {
  if (!color) return false;
  
  const normalizedColor = normalizeHexColor(color);
  
  if (!isValidHexColor(normalizedColor)) {
    return false; // or throw new Error("Invalid color format");
  }

  const hex = normalizedColor.slice(1);
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  
  return (r * 299 + g * 587 + b * 114) / 1000 < 128;
};
