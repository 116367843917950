import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";
import env from "../env";

export type Place = {
  latitude: number;
  longitude: number;
  name: string;
  address?: string;
};

type RadarMapEmbedProps = {
  onSelectPlace: (place: Place) => void;
  initialLocation?: { lat: number; lng: number };
  categories?: string[]; // Allow customization of place categories
  radius?: number; // Allow customization of search radius
};

const parseRadarPlace = async (p: any): Promise<Place> => {
  const lat = p.location?.coordinates[1] ?? 0;
  const lng = p.location?.coordinates[0] ?? 0;
  return { latitude: lat, longitude: lng, name: p.name ?? "Unknown", address: "" };
};

const fetchRadarPlaces = async (
  lat: number,
  lng: number,
  categories: string[],
  radius: number
): Promise<Place[]> => {
  try {
    // if user location is not available, use the default location of New York
    const res = await Radar.searchPlaces({
      near: { latitude: lat, longitude: lng } || { latitude: 40.7128, longitude: -74.006 },
      radius,
      categories,
      limit: 100,
    });
    return Promise.all((res.places || []).map(parseRadarPlace));
  } catch (err) {
    console.error("Error fetching places:", err);
    return [];
  }
};

const initMap = (
  containerId: string,
  onSelect: (place: Place) => void,
  initLoc?: { lat: number; lng: number },
  categories?: string[],
  radius?: number
): (() => void) => {
  Radar.initialize(env.REACT_APP_RADAR_TOKEN);

  const map = Radar.ui.map({
    container: containerId,
    center: initLoc ? [initLoc.lng, initLoc.lat] : [-74.006, 40.7128],
    zoom: 12,
    style: "radar-default-v1",
  });

  const handleSelect = (e: CustomEvent<Place>) => onSelect(e.detail);
  const mapRef: { current: any } = { current: null };
  mapRef.current = map;

  const updateMarkers = async (places: Place[]) => {
    if (!mapRef.current) return;
    mapRef.current.getMarkers().forEach((m: any) => m.remove());
      
    // Create bounds to fit all markers
    const bounds = new Radar.ui.maplibregl.LngLatBounds();
    if (places.length > 0) {
    places.forEach((p) => {
      bounds.extend([p.longitude, p.latitude]);
      const marker = Radar.ui.marker({
        color: "#9F7AEA",
        draggable: false,
        popup: {
          html: `
              <div style="padding: 8px">
                <strong>${p.name}</strong>
                <p>${p.address}</p>
                <button 
                  onclick='window.dispatchEvent(new CustomEvent("selectPlace", { detail: ${JSON.stringify(p)} }))'
                  style="background: #9F7AEA; color: white; padding: 4px 8px; border-radius: 4px; border: none; margin-top: 8px"
                >
                  Select Location
                </button>
              </div>
            `,
        },
        });
        marker.setLngLat([p.longitude, p.latitude]).addTo(mapRef.current!);
      });
    }

    // Fit map to bounds with padding
    if (places.length > 0) {
        mapRef.current.fitBounds(bounds, {
        padding: 50,
        maxZoom: 15
      });
    }
  };

  navigator.geolocation.getCurrentPosition(async (pos) => {
    if (!initLoc) map.setCenter([pos.coords.longitude, pos.coords.latitude]);
    const places = await fetchRadarPlaces(
      pos.coords.latitude,
      pos.coords.longitude,
      categories || ["restaurant"],
      radius || 10_000
    );
    updateMarkers(places);
  });

  window.addEventListener("selectPlace", handleSelect as EventListener);

  return () => {
    map.remove();
    window.removeEventListener("selectPlace", handleSelect as EventListener);
  };
};

export const RadarMapEmbed = ({
  onSelectPlace,
  initialLocation,
  categories,
  radius,
}: RadarMapEmbedProps) => {
  const [places, setPlaces] = useState<Place[]>([]);

  useEffect(() => {
    return initMap(
      "radar-map",
      onSelectPlace,
      initialLocation,
      categories,
      radius
    );
  }, [onSelectPlace, initialLocation, categories, radius]);

  return (
    <Box mt={4}>
      <div
        id="radar-map"
        style={{
          width: "100%",
          height: "300px",
          border: "1px solid",
          borderColor: "gray.200",
          borderRadius: "8px",
        }}
      />
    </Box>
  );
};