/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Drawer, Text, Link, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, useColorModeValue, useDisclosure, VStack, Container } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Sidebar from './sidebar';
import Header from './header';
import { useAuth } from '../../context/auth';
import CreateHuntDrawer from '../tour-drawer';
import CreateChallengeDrawer from '../challenge-drawer';
import OnboardingGuide from '../onboarding-guide';

const FOOTER_CONFIG = {
  links: [
    { label: "Terms of Service", href: "/terms" },
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Contact Us", href: "/contact" },
    { label: "Food Tour Guide", href: "/guide" },
    { label: "Creator Resources", href: "/resources" }
  ],
  supportEmail: "support@culinarycastle.com",
  companyName: "Culinary Castle"
} as const;

const FooterLinks = ({ color }: { color: string }) => (
  <Flex 
    gap={3} 
    wrap="wrap" 
    justify={{ base: "center", md: "flex-end" }}
  >
    {FOOTER_CONFIG.links.map((link, index) => (
      <>
        <Link
          key={link.label + index}
          as={RouterLink}
          to={link.href}
          fontSize="sm"
          color={color}
          _hover={{ 
            textDecoration: "underline",
            color: "purple.500"
          }}
          transition="color 0.2s ease"
        >
          {link.label}
        </Link>
        {index < FOOTER_CONFIG.links.length - 1 && (
          <Text color={color} mt={-0.5}>•</Text>
        )}
      </>
    ))}
  </Flex>
);

const GlobalFooter = () => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("purple.200", "gray.700");
  const textColor = useColorModeValue("purple.800", "gray.400");
  const linkColor = useColorModeValue("purple.600", "purple.400");

  return (
    <Box 
      as="footer" 
      bg={bgColor} 
      borderTop="1px" 
      borderColor={borderColor}
      py={6}
      position="relative"
    >
      <Container maxW="container.xl">
        <VStack spacing={6}>
          <Flex 
            direction={{ base: "column", md: "row" }} 
            justify="space-between" 
            align="center" 
            w="full"
            gap={6}
          >
            <VStack align={{ base: "center", md: "flex-start" }} spacing={2}>
              <Text fontSize="sm" color={textColor} fontWeight="medium">
                © 2024 {FOOTER_CONFIG.companyName}. All rights reserved.
              </Text>
              <Text fontSize="xs" color={textColor}>
                Discover your culinary adventures!
              </Text>
            </VStack>
            <FooterLinks color={linkColor} />
          </Flex>
          
        </VStack>
      </Container>
    </Box>
  );
};

interface DashboardLayoutProps {
  children: React.ReactNode;
  tourDrawerOpen: boolean;
  setTourDrawerOpen: (open: boolean) => void;
  locationDrawerOpen: boolean;
  setLocationDrawerOpen: (open: boolean) => void;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children, tourDrawerOpen, setTourDrawerOpen, locationDrawerOpen, setLocationDrawerOpen }) => {
  const { user } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const [selectedPage, setSelectedPage] = useState('Dashboard');
  const borderColor = useColorModeValue("gray.400", "gray.700");
  const [showOnboarding, setShowOnboarding] = useState(user?.onboardingStep !== 5);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith("/home")) {
      setSelectedPage("Dashboard");
    } else if (path.endsWith("/tours")) {
      setSelectedPage("Tours");
    } else if (path.endsWith("/participants")) {
      setSelectedPage("Participants");
    } else if (path.endsWith("/activity")) {
      setSelectedPage("Activity Log");
    } else if (path.endsWith("/settings")) {
      setSelectedPage("Settings");
    } else if (path.endsWith("/billing")) {
      setSelectedPage("Billing");
    } else if (path.endsWith("/home/places")) {
      setSelectedPage("Locations");
    } else if (path.includes("/locations/")) {
      setSelectedPage("Location");
    } else if (path.includes("/tours/")) {
      setSelectedPage("Tour");
    }
  }, [window.location.pathname]);

  return (<Flex flexDirection={{ base: "column", md: "row" }} height="100vh">
    <Box
      data-tour="sidebar"
      display={{ base: "none", md: "block" }}
      w="64"
      bg={bgColor}
      borderRight="1px"
      borderRightColor={borderColor}
    >
      <Sidebar user={user} appName="Culinary Castle" />
    </Box>
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent bgGradient="linear(to-b, purple.500, pink.500)" color="white">
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Sidebar user={user} appName="Culinary Castle" />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    <Box flex={1} overflow="auto">
      <Header onOpen={onOpen} user={user} title={selectedPage} />
      {children}
      <GlobalFooter />
    </Box>
    

      <CreateHuntDrawer
        isOpen={tourDrawerOpen || false}
        onClose={() => setTourDrawerOpen?.(false)}
      />
      <CreateChallengeDrawer
        isOpen={locationDrawerOpen || false}
        onClose={() => setLocationDrawerOpen?.(false)}
      />

      <OnboardingGuide
        isOpen={showOnboarding}
        onClose={() => setShowOnboarding(false)}
        openTourDrawer={() => setTourDrawerOpen(true)}
        openLocationDrawer={() => setLocationDrawerOpen(true)}
        closeLocationDrawer={() => setLocationDrawerOpen(false)}
        closeTourDrawer={() => setTourDrawerOpen(false)}
        onComplete={() => {
          setShowOnboarding(false)
        }}
        currentStep={user?.onboardingStep || 1}
      />
    </Flex>
  );
};

export default DashboardLayout;
