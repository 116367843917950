import React, { useEffect, useRef, useState } from "react"
import { useParams, useSearchParams, useLocation } from "react-router-dom"
import {
  VStack,
  Flex,
  Spinner,
  useToast,
  Container,
  useColorModeValue,
  Text,
} from "@chakra-ui/react"
import { useGetMessagesThreadQuery } from "../../graphql/generated"
import { useMessageUpdates } from "../../hooks/useMessageSubscription"
import { useMessageInput } from "../../hooks/useMessageInput"
import { MessageBubble } from "../../components/chat-bubble"
import { ChatHeader } from "../../components/chat-header"
import { MessageInput } from "../../components/chat-input"
import { MessageDateDivider } from "../../components/chat-date-divider"
import { useAuth } from "../../context/auth"

const ChatPage: React.FC = () => {
  const { tourId, participantId } = useParams()
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")
  const location = useLocation()
  const huntTitle = location.state?.huntTitle
  const huntColor = location.state?.huntColor
  const toast = useToast()
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [hasScrolled, setHasScrolled] = useState(false)

  const { data, loading, refetch } = useGetMessagesThreadQuery({
    variables: {
      participantId: participantId!,
      code,
      limit: 50,
    },
    pollInterval: 1000,
    onError: (error) => {
      toast({
        title: "Error loading messages",
        description: error.message,
        status: "error",
      })
    },
  })

  const { user } = useAuth()
  const participantEmail = location.state?.participantEmail
  const { message, setMessage, sendMessage, isSending } = useMessageInput({
    tourId: tourId!,
    participantId: participantId!,
    participantEmail,
    onMessageSent: refetch,
  })

  useMessageUpdates({
    participantId: participantId!,
    onNewMessage: refetch,
  })

  const scrollToBottom = (behavior: ScrollBehavior = "smooth") => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior })
    }
  }

  useEffect(() => {
    if (!hasScrolled && data?.message) {
      scrollToBottom("auto")
      setHasScrolled(true)
    }
  }, [data?.message, hasScrolled])

  useEffect(() => {
    if (hasScrolled) {
      scrollToBottom()
    }
  }, [data?.message?.length, hasScrolled])

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      sendMessage()
    }
  }

  const groupMessagesByDate = (messages: any[]) => {
    return messages.reduce((groups: any, message: any) => {
      const date = new Date(message.createdAt).toLocaleDateString()
      if (!groups[date]) {
        groups[date] = []
      }
      groups[date].push(message)
      return groups
    }, {})
  }

  return (
    <Container maxW="container.md" h="100vh" p={0}>
      <VStack h="full" spacing={0}>
        <ChatHeader
          participantUsername={data?.message[0]?.participantName ?? ""}
          participantEmail={participantEmail ?? ""}
          creatorUsername={data?.message[0]?.creatorUsername ?? ""}
          creatorEmail={data?.message[0]?.creatorEmail ?? ""}
          huntTitle={huntTitle ?? ""}
          isCreator={user?.isAuth === true}
          huntColor={huntColor ?? "purple.500"}
        />

        <VStack
          ref={scrollContainerRef}
          flex={1}
          w="full"
          spacing={6}
          p={{ base: 2, md: 4 }}
          overflowY="auto"
          bg={useColorModeValue("gray.50", "gray.900")}
          position="relative"
        >
          {loading ? (
            <Flex justify="center" align="center" h="full">
              <Spinner color={huntColor ?? "purple.500"} />
            </Flex>
          ) : (
            <>
              {!data?.message || data.message.length === 0 ? (
                <Flex justify="center" align="center" h="full">
                  <Text color="gray.500" fontSize="md">
                    No messages yet. Start the conversation!
                  </Text>
                </Flex>
              ) : (
                <>
                  {Object.entries(groupMessagesByDate(data.message)).map(
                    ([date, messages]) => (
                      <VStack key={date} w="full" spacing={4}>
                        <MessageDateDivider date={date} />
                        {(messages as any[]).map((msg: any) => (
                          <MessageBubble
                            key={msg.id}
                            message={msg}
                            isUser={user?.isAuth === true}
                            huntColor={huntColor ?? "purple.500"}
                          />
                        ))}
                      </VStack>
                    )
                  )}
                  <div ref={messagesEndRef} />
                </>
              )}
            </>
          )}
        </VStack>

        <MessageInput
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          isSending={isSending}
          onKeyPress={handleKeyPress}
          huntColor={huntColor ?? "purple.500"}
        />
      </VStack>
    </Container>
  )
}

export default ChatPage