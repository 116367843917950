import React, { useState } from 'react';
import { 
    Box, 
    Flex, 
    VStack, 
    Text, 
    Button, 
    Badge, 
    useColorModeValue, 
    useBreakpointValue,
    Tooltip,
    Divider
} from "@chakra-ui/react";
import { Trophy, Target, Clock, Users, Star, CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import CardSkeleton from './shared/card-skeleton';
import { useLoading } from "../context/loading";
import CardHeader from "./shared/card-header";
import CardWrapper from "./shared/card-wrapper";
import { EventType, GetEventsDocument } from '../graphql/generated';
import { capitalize } from 'lodash';
import { useSmartPolling } from '../hooks/useSmartPolling';
import { motion } from 'framer-motion';

type ActivityAction = {
  color: string;
  icon: typeof Trophy | typeof Target | typeof Users | typeof Star | typeof CheckCircle;
  label: string;
  description: (email: string, huntTitle: string) => string;
};

const RecentActivity = ({ userType }: { userType: string }) => {
    const navigate = useNavigate();
    const { isLoading } = useLoading();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [showAll, setShowAll] = useState(false);

    // Theme colors
    const hoverBg = useColorModeValue("gray.50", "gray.700");
    const timeColor = useColorModeValue("gray.500", "gray.400");
    const nameColor = useColorModeValue("purple.800", "purple.300");
    const contentColor = useColorModeValue("gray.700", "gray.300");
    const dividerColor = useColorModeValue("purple.200", "gray.700");

    const { data, loading, error} = useSmartPolling(GetEventsDocument, {
        filter: {
            onlyParticipant: true,
        },
        limit: 20,
        offset: 0
    }, {
        baseInterval: 1_000,
        onError: (error) => {
            console.error('Error fetching events:', error);
        }
    });

    const getActionDetails = (type: EventType): ActivityAction => {
        switch (type) {
            case EventType.HuntCompleted:
                return { 
                    color: 'green', 
                    icon: Trophy,
                    label: 'Tour Completed',
                    description: (email, huntTitle) => 
                        `${email} completed the "${huntTitle}" food tour adventure! 🎉`
                };
            case EventType.ParticipantJoined:
                return { 
                    color: 'purple', 
                    icon: Users,
                    label: 'New Explorer',
                    description: (email, huntTitle) => 
                        `${email} joined "${huntTitle}" as a new food explorer 🌟`
                };
            case EventType.ResponseSubmitted:
                return {
                    color: 'orange',
                    icon: CheckCircle,
                    label: 'Location Explored',
                    description: (email, huntTitle) =>
                        `${email} explored "${huntTitle}" 🍽️`
                };
            case EventType.VoteSubmitted:
                return {
                    color: 'blue',
                    icon: Star,
                    label: 'New Rating',
                    description: (email, huntTitle) =>
                        `${email} rated their experience at "${huntTitle}" ⭐`
                };
            default:
                return { 
                    color: 'gray', 
                    icon: Target,
                    label: 'Activity',
                    description: (email, huntTitle) => 
                        `${email} participated in "${huntTitle}"`
                };
        }
    };

    const formatTimeAgo = (dateString: string | null | undefined) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const now = new Date();
        const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
        
        if (diffInSeconds < 60) return 'just now';
        if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
        if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
        return `${Math.floor(diffInSeconds / 86400)}d ago`;
    };

    if (isLoading || loading) return <CardSkeleton rows={4} />;
    if (error) return null;

    // Filter and validate activities
    const allActivities = (data?.events || [])
        .filter((event: any) => 
            event.participant &&
            event.huntTitle &&
            !event.content?.startsWith('You')
        )
        .sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt || '').getTime();
            const dateB = new Date(b.createdAt || '').getTime();
            return dateB - dateA;
        });

    // Show all items if showAll is true, otherwise show only 5
    const activities = showAll ? allActivities : allActivities.slice(0, 3);
    const hasMoreActivities = allActivities.length > 3;

    return (
        <CardWrapper>
            <CardHeader title="Recent Activity">
                <Flex gap={2} width={isMobile ? "full" : "auto"} direction={isMobile ? "column" : "row"}>
                    {hasMoreActivities && userType === "FREE" && (
                        <Button
                            size="sm"
                            variant="outline"
                            color="purple.500"
                            borderColor={"purple.500"}
                            onClick={() => setShowAll(!showAll)}
                            width={isMobile ? "full" : "auto"}
                        >
                            {showAll ? "Show Less" : "Show More"}
                        </Button>
                    )}
                    <Button
                        size="sm"
                        color="purple.500"
                        variant="outline"
                        borderColor={"purple.500"}
                        onClick={() => navigate('/home/activity')}
                        width={isMobile ? "full" : "auto"}
                    >
                        View All
                    </Button>
                </Flex>
            </CardHeader>
            <VStack spacing={0} align="stretch" divider={<Divider borderColor={dividerColor} />}>
                {activities.length === 0 ? (
                    <Box p={4} textAlign="center">
                        <Text color={contentColor}>No recent activities.</Text>
                    </Box>
                ) : (
                    activities.map((activity: any) => {
                        const { color, icon: Icon, label, description } = getActionDetails(activity.type);
                        const timeAgo = formatTimeAgo(activity.createdAt);
                        const email = activity.participant?.email || '';
                        
                        return (
                            <Box
                                key={activity.id}
                                p={4}
                                _hover={{ bg: hoverBg }}
                                as={motion.div}
                                whileHover={{ x: 4 }}
                                cursor="pointer"
                                transition="all 0.2s"
                                onClick={() => navigate(activity.huntId ? `/home/tours/${activity.huntId}` : '/home/activity')}
                            >
                                <Flex 
                                    direction={isMobile ? "column" : "row"}
                                    gap={isMobile ? 3 : 4}
                                    align={isMobile ? "flex-start" : "center"}
                                    justify="space-between"
                                >
                                    <Flex align="center" gap={3} flex={1}>
                                        <Box flex="1">
                                            <Flex 
                                                gap={2} 
                                                align="center" 
                                                mb={1}
                                                wrap="wrap"
                                            >
                                                <Text 
                                                    fontSize="md" 
                                                    fontWeight="bold"
                                                    color={nameColor}
                                                >
                                                    {capitalize(email)}
                                                </Text>
                                                <Badge 
                                                    colorScheme={color}
                                                    px={2}
                                                    py={0.5}
                                                    borderRadius="full"
                                                    fontSize="xs"
                                                    display="flex"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <Icon size={12} />
                                                    {label}
                                                </Badge>
                                            </Flex>
                                            <Text 
                                                fontSize="sm" 
                                                color={contentColor}
                                                noOfLines={2}
                                            >
                                                {description(email, activity.huntTitle || '')}
                                            </Text>
                                        </Box>
                                    </Flex>
                                    <Tooltip label={new Date(activity.createdAt || "").toLocaleString()}>
                                        <Flex 
                                            align="center" 
                                            gap={1}
                                            minW="fit-content"
                                            opacity={0.8}
                                        >
                                            <Clock size={12} color={timeColor} />
                                            <Text fontSize="xs" color={timeColor}>
                                                {timeAgo}
                                            </Text>
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                            </Box>
                        );
                    })
                )}
            </VStack>
        </CardWrapper>
    );
};

export default RecentActivity;
