import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Text,
  VStack,
  Heading,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { Copy, Share2 } from 'lucide-react';
import { useGenerateShortLinkMutation } from '../graphql/generated';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  shareLink: string;
  color?: string;
}

const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose, shareLink, color }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [shortLink, setShortLink] = useState(shareLink);
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const [generateShortLink] = useGenerateShortLinkMutation({
    onCompleted: (data) => {
      setShortLink(data.generateShortLink);
    },
    onError: () => {
      setShortLink(shareLink);
    }
  });

  useEffect(() => {
    generateShortLink({ variables: { link: shareLink } });
  }, [shareLink, generateShortLink]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "🍽️ Discover an Amazing Food Tour Experience!",
          text: "I found this incredible food tour that takes you on a mouthwatering journey through hidden culinary gems. Come explore it with me!",
          url: shortLink
        });
        toast({
          title: 'Shared successfully!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        if ((error as Error).name !== 'AbortError') {
          handleCopy();
        }
      }
    } else {
      handleCopy();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shortLink);
    setIsCopied(true);
    toast({
      title: 'Link copied!',
      description: 'The tour link has been copied to your clipboard.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    setTimeout(() => setIsCopied(false), 2000);
  };

  const isDarkColor = (color: string) => {
    const hex = color.slice(1);
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return (r * 299 + g * 587 + b * 114) / 1000 < 128;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent 
        bg={bgColor} 
        borderRadius="xl" 
        boxShadow="xl"
        borderColor={color || "orange.500"}
        borderWidth={2}
        width={{ base: "90%", sm: "80%", md: "70%", lg: "50%" }}
        maxWidth={{ base: "90vw", sm: "80vw", md: "70vw", lg: "500px" }}
      >
        <ModalHeader borderBottomWidth="1px" py={4}>
          <Heading size="md" color={color || "orange.500"}>Share</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={6}>
          <VStack spacing={6} align="stretch">
            <Box>
              <Text fontSize="lg" fontWeight="medium" color={textColor} mb={2}>
                Invite friends to join the tour:
              </Text>
              <Text fontSize="md" color={useColorModeValue('gray.600', 'gray.400')}>
                Share this link to let others join this culinary tour!
              </Text>
            </Box>
            <InputGroup size="lg">
              <Input
                pr="4.5rem"
                type="text"
                value={shortLink}
                readOnly
                bg={useColorModeValue('gray.100', 'gray.700')}
                borderColor={useColorModeValue('gray.300', 'gray.600')}
                _hover={{ borderColor: useColorModeValue('gray.400', 'gray.500') }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleCopy}
                  style={{ backgroundColor: color || "orange.500", color: isDarkColor(color || "orange.500") ? "white" : "black" }}
                >
                  {isCopied ? 'Copied!' : <Copy size={18} />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </VStack>
        </ModalBody>
        <ModalFooter borderTopWidth="1px" py={4}>
          <Button
            leftIcon={<Share2 size={18} />}
            style={{ backgroundColor: color || "orange.500", color: isDarkColor(color || "orange.500") ? "white" : "black" }}
            onClick={handleShare}
            size="lg"
            width="full"
          >
            Share Tour
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
