import { useState } from "react"
import { useAuth } from "../context/auth"
import { useSendMessageToParticipantMutation, useSendMessageToCreatorMutation } from "../graphql/generated"

type UseMessageInputProps = {
  tourId: string
  participantId: string
  participantEmail?: string
  onMessageSent?: () => void
}

export const useMessageInput = ({
  tourId,
  participantId,
  participantEmail,
  onMessageSent
}: UseMessageInputProps) => {
  const [message, setMessage] = useState("")
  const { user } = useAuth()

  const [sendToParticipant, { loading: sendingToParticipant }] = useSendMessageToParticipantMutation()
  const [sendToCreator, { loading: sendingToCreator }] = useSendMessageToCreatorMutation()

  const sendMessage = async () => {
    if (!message.trim()) return

    try {
      if (user.id) {
        await sendToParticipant({
          variables: {
            huntId: tourId,
            participantEmail: participantEmail ?? "",
            content: message.trim()
          }
        })
      } else {
        await sendToCreator({
          variables: {
            huntId: tourId,
            participantId,
            content: message,
          },
        })
      }
      
      setMessage("")
      onMessageSent?.()
    } catch (error) {
      console.error("Failed to send message:", error)
    }
  }

  return {
    message,
    setMessage,
    sendMessage,
    isSending: sendingToParticipant || sendingToCreator
  }
} 