import { useState } from 'react';
import storage from '../helpers/storage';
import { AuthUser } from '../context/auth';

export const useUserState = () => {
  const getInitialState = (): AuthUser => {
    const storedUser = storage.get<AuthUser>('user');
    return storedUser || { isAuth: false };
  };

  const [user, setUser] = useState<AuthUser>(getInitialState);

  const updateUser = (newUser: AuthUser) => {
    setUser(newUser);
    storage.set('user', newUser);
  };

  const clearUser = () => {
    setUser({ isAuth: false });
    storage.remove('user');
  };

  return { user, updateUser, clearUser };
}; 