export const buttonBaseStyles = {
  transition: "all 0.2s ease-in-out",
  transform: "scale(1)",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  _hover: {
    transform: "scale(1.02)",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
  },
  _active: {
    transform: "scale(0.98)",
  }
};

export const primaryButtonStyles = {
  ...buttonBaseStyles,
  height: "auto",
  py: [3, 4],
  px: [4, 6],
  fontSize: ["md", "lg"],
  fontWeight: "600",
  borderRadius: "xl",
}; 