import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useLogoutMutation, CurrentUserDocument } from '../graphql/generated';
import { UserType } from '../graphql/generated';
import { useSmartPolling } from '../hooks/useSmartPolling';
import { useUserState } from '../hooks/useUserState';
export type AuthUser = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  profilePicture?: string;
  userType?: UserType;
  emailMessageNotifications?: boolean;
  emailFeedbackNotifications?: boolean;
  emailJoinNotifications?: boolean;
  emailChallengeNotifications?: boolean;
  emailPaymentNotifications?: boolean;
  emailReportNotifications?: boolean;
  emailPaymentReminderNotifications?: boolean;
  onboardingStep?: number;
  onboardingCompletedAt?: string;
  firstTimeAt?: string;
  isAuth: boolean;
}

interface AuthContextType {
  user: AuthUser;
  setUser: (user: AuthUser) => void;
  logout: () => void;
  checkAuthStatus: () => Promise<void>;
  updateProfilePicture: (profilePicture: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Extract user mapping logic into a pure function
const mapUserDataToAuthUser = (userData: any): AuthUser => ({
  isAuth: true,
  profilePicture: userData?.profilePicture,
  id: userData?.id,
  email: userData?.email,
  username: userData?.username,
  firstName: userData?.firstName,
  lastName: userData?.lastName,
  userType: userData?.userType,
  emailMessageNotifications: userData?.emailMessageNotifications,
  emailFeedbackNotifications: userData?.emailFeedbackNotifications,
  emailJoinNotifications: userData?.emailJoinNotifications,
  emailChallengeNotifications: userData?.emailChallengeNotifications,
  emailPaymentNotifications: userData?.emailPaymentNotifications,
  emailReportNotifications: userData?.emailReportNotifications,
  emailPaymentReminderNotifications: userData?.emailPaymentReminderNotifications,
  onboardingStep: userData?.onboardingStep,
  onboardingCompletedAt: userData?.onboardingCompletedAt,
  firstTimeAt: userData?.firstTimeAt,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user, updateUser, clearUser } = useUserState();
  
  const { data, refetch } = useSmartPolling(CurrentUserDocument, {}, {
    baseInterval: 2_500,
  });

  const [logoutMutation] = useLogoutMutation();

  useEffect(() => {
    if (!data?.me) return;
    const updatedUser = mapUserDataToAuthUser(data.me);
    updateUser(updatedUser);
  }, [data]);

  const logout = async () => {
    try {
      await logoutMutation();
      clearUser();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const checkAuthStatus = async () => {
    try {
      const { data } = await refetch();
      if (data?.me) {
        updateUser(mapUserDataToAuthUser(data.me));
      } else {
        clearUser();
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      clearUser();
    }
  };

  const updateProfilePicture = (profilePicture: string) => {
    updateUser({ ...user, profilePicture });
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser: updateUser, 
      logout, 
      checkAuthStatus, 
      updateProfilePicture 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};