import { VStack, Link, HStack, Icon, Text, Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { Navigation, Compass, ChevronDown } from "lucide-react";
import type { Coordinates } from "../../../helpers/map-util";
import { buttonBaseStyles, primaryButtonStyles } from "../../shared/button-styles";
import { geocodeAddress } from "../../../helpers/geocoding";
import { useEffect, useState } from "react";

type NavigationButtonsProps = {
  mapUrl: string;
  userLocation: Coordinates;
  isLoadingLocation: boolean;
  onFetchLocation: () => void;
  isDisabled?: boolean;
  challenges: Array<{ address: string; title: string }>;
  currentChallenge?: number;
};

export const NavigationButtons = ({
  mapUrl,
  userLocation,
  isLoadingLocation,
  onFetchLocation,
  isDisabled,
  challenges,
  currentChallenge = 0
}: NavigationButtonsProps) => {
  // Convert to async function and add error handling
  const getNavigationUrls = async () => {
    if (!userLocation || !challenges.length) return null;

    const origin = `${userLocation.latitude},${userLocation.longitude}`;
    const remainingChallenges = challenges.slice(currentChallenge);
    const destination = encodeURIComponent(remainingChallenges[0].address);

    // Google Maps URL with waypoints
    let googleUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
    if (remainingChallenges.length > 1) {
      const waypoints = remainingChallenges.slice(1)
        .map(challenge => encodeURIComponent(challenge.address))
        .join('|');
      googleUrl += `&waypoints=${waypoints}`;
    }

    // Apple Maps URL
    let appleUrl = `maps://?saddr=${origin}&daddr=${destination}`;
    if (remainingChallenges.length > 1) {
      appleUrl += remainingChallenges.slice(1)
        .map(challenge => `+to:${encodeURIComponent(challenge.address)}`)
        .join('');
    }

    try {
      const geoCodeDestination = await geocodeAddress(destination);
      const destinationPlaceId = encodeURIComponent(`${geoCodeDestination.lat},${geoCodeDestination.lon}`);
      const wazeUrl = `https://waze.com/ul?ll=${destination}&navigate=yes&from=${origin}&to=place.${destinationPlaceId}`;
      
      return { googleUrl, appleUrl, wazeUrl };
    } catch (error) {
      console.error('Failed to geocode address:', error);
      return { googleUrl, appleUrl }; 
    }
  };

  // Add state to store navigation URLs
  const [navigationUrls, setNavigationUrls] = useState<{
    googleUrl: string;
    appleUrl: string;
    wazeUrl?: string;
  } | null>(null);

  // Load URLs when component mounts or dependencies change
  useEffect(() => {
    const loadUrls = async () => {
      const urls = await getNavigationUrls();
      setNavigationUrls(urls);
    };
    loadUrls();
  }, [userLocation, challenges, currentChallenge]);

  // Detect platform
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAndroid = /Android/.test(navigator.userAgent);

  // Handle navigation click
  const handleNavigationClick = () => {
    if (!navigationUrls) return;

    if (isIOS) {
      // Try Apple Maps first on iOS
      window.location.href = navigationUrls.appleUrl;
      // Fallback to Google Maps after a short delay if Apple Maps doesn't open
      setTimeout(() => {
        window.location.href = navigationUrls.googleUrl;
      }, 500);
    } else {
      // Default to Google Maps on other platforms
      window.location.href = navigationUrls.googleUrl;
    }
  };

  return (
    <VStack spacing={3} mt={4}>
      {userLocation ? (
        <Menu>
          <MenuButton
            as={Button}
            width="100%"
            bg="blue.500"
            color="white"
            {...primaryButtonStyles}
            isDisabled={isDisabled || !navigationUrls}
            rightIcon={<ChevronDown />}
          >
            <HStack spacing={3} justify="center">
              <Icon as={Navigation} w={5} h={5} />
              <Text fontSize={["sm", "md"]} fontWeight="600">
                Navigate to Locations
              </Text>
            </HStack>
          </MenuButton>
          <MenuList
            borderRadius="xl"
            boxShadow="lg"
            p={2}
          >
            <MenuItem 
              borderRadius="lg"
              p={3}
              onClick={() => window.location.href = navigationUrls?.googleUrl || ''}
            >
              Open in Google Maps
            </MenuItem>
            {isIOS && (
              <MenuItem 
                borderRadius="lg"
                p={3}
                onClick={() => window.location.href = navigationUrls?.appleUrl || ''}
              >
                Open in Apple Maps
              </MenuItem>
            )}
            <MenuItem 
              borderRadius="lg"
              p={3}
              onClick={() => window.location.href = navigationUrls?.wazeUrl || ''}
            >
              Open in Waze
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Button
          leftIcon={<Icon as={Compass} w={5} h={5} />}
          colorScheme="blue"
          width="100%"
          {...primaryButtonStyles}
          onClick={onFetchLocation}
          isLoading={isLoadingLocation}
          loadingText="Getting Location..."
          {...(isDisabled && {
            opacity: 0.5,
            cursor: "not-allowed"
          })}
        >
          Use My Location
        </Button>
      )}
    </VStack>
  );
}; 