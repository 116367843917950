import React from "react";
import {
  Flex,
  Heading,
  Stack,
  useBreakpointValue,
  BoxProps,
  useColorModeValue,
} from "@chakra-ui/react";

type CardHeaderProps = BoxProps & {
  title: string;
  subHeader?: string;
  children?: React.ReactNode;
  centerContent?: boolean;
};

const CardHeader = ({ 
  title, 
  subHeader, 
  children, 
  centerContent = false,
  ...props 
}: CardHeaderProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const borderColor = useColorModeValue('purple.200', 'purple.700');
  const headerColor = useColorModeValue('purple.600', 'purple.200');
  const bgGradient = useColorModeValue(
    'linear(to-r, purple.50, transparent)',
    'linear(to-r, purple.900, transparent)'
  );

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      justifyContent={centerContent ? "center" : "space-between"}
      alignItems={isMobile ? "stretch" : "center"}
      p={{ base: 4, md: 6 }}
      borderBottomWidth="1px"
      borderColor={borderColor}
      bgGradient={bgGradient}
      {...props}
    >
      <Stack spacing={1}>
        <Heading
          size="md"
          color={headerColor}
          mb={isMobile && children ? 4 : 0}
          textAlign={centerContent || isMobile ? "center" : "left"}
          fontWeight="600"
          letterSpacing="tight"
        >
          {title}
        </Heading>
        {subHeader && (
          <Heading
            size="sm"
            color={headerColor}
            opacity={0.8}
            fontWeight="400"
            textAlign={centerContent || isMobile ? "center" : "left"}
          >
            {subHeader}
          </Heading>
        )}
      </Stack>
      {children && (
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={{ base: 2, md: 4 }}
          width={isMobile ? "100%" : "auto"}
          align={isMobile ? "stretch" : "center"}
        >
          {children}
        </Stack>
      )}
    </Flex>
  );
};

export default CardHeader; 