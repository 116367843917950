import { useEffect, useRef } from 'react';
import { DocumentNode, useQuery } from '@apollo/client';

interface SmartPollingOptions {
    baseInterval?: number;
    maxInterval?: number;
    backoffFactor?: number;
    onError?: (error: Error) => void;
}

export const useSmartPolling = (
    query: DocumentNode,
    variables = {},
    options: SmartPollingOptions = {}
) => {
    const {
        baseInterval = 5000,
        maxInterval = 30000,
        backoffFactor = 1.5,
        onError
    } = options;

    const currentInterval = useRef(baseInterval);
    const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const queryResult = useQuery(query, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
        const startPolling = () => {
            timeoutRef.current = setTimeout(() => {
                queryResult.refetch()
                    .then(() => {
                        // On success, reset interval to base
                        currentInterval.current = baseInterval;
                        startPolling();
                    })
                    .catch((error) => {
                        // On error, increase interval with backoff
                        currentInterval.current = Math.min(
                            currentInterval.current * backoffFactor,
                            maxInterval
                        );
                        onError?.(error);
                        startPolling();
                    });
            }, currentInterval.current);
        };

        startPolling();

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [query, baseInterval, maxInterval, backoffFactor, onError, queryResult]);

    return queryResult;
};
