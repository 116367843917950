import React, { useState } from 'react';
import { 
  VStack, 
  Heading, 
  Text, 
  Box, 
  Button, 
  Icon, 
  Spinner, 
  Image, 
  Flex,
  Progress,
  ButtonGroup,
  useColorModeValue,
  Card,
  CardBody,
  Badge,
  HStack,
  useBreakpointValue,
  Container,
  SimpleGrid,
  Link,
  Divider,
  Stack,
  Textarea
} from '@chakra-ui/react';
import { MapPin, ArrowLeft, ArrowRight, Camera, Trophy, Map, Play, Share2, ThumbsUp } from 'lucide-react';
import { CreateHuntFormData } from './tour-drawer';
import { useGetChallengeQuery, useGetMediaQuery } from '../graphql/generated';
import { motion } from 'framer-motion';
import { isDarkColor } from '../helpers/color-util';
import { NavigationButtons } from './game-states/intro-state/navigation-buttons';
import { generateMultiStopMapUrl } from '../helpers/map-util';

const MotionBox = motion(Box as any);
const MotionVStack = motion(VStack as any);

type PreviewState = 'intro' | 'playing' | 'voting' | 'finished';

const HuntPreview = ({ formData }: { formData: CreateHuntFormData }) => {
  const [previewState, setPreviewState] = useState<PreviewState>('intro');
  const [currentLocation, setCurrentLocation] = useState(0);
  const [votedRestaurant, setVotedRestaurant] = useState('');

  const { data: mediaData, loading: mediaLoading } = useGetMediaQuery({
    variables: {
      id: formData.backgroundMediaId || ''
    },
    skip: !formData.backgroundMediaId
  });

  const { data: logoMediaData, loading: logoMediaLoading } = useGetMediaQuery({
    variables: {
      id: formData.logoMediaId || ''
    },
    skip: !formData.logoMediaId
  });

  const { data: currentChallengeData } = useGetChallengeQuery({
    variables: {
      challengeId: formData.challenges[currentLocation] || ''
    },
    skip: !formData.challenges[currentLocation]
  });

  const challengeQueries = formData.challenges.map((challengeId, index) => {
    const challenge = currentChallengeData?.challenge;
    return { challenge };
  });

  const imageData = mediaData?.media || formData.image;
  const bgImage = imageData ? `${imageData}` : undefined;

  const logoImageData = logoMediaData?.media;
  const logoImage = logoImageData ? `${logoImageData}` : undefined;

  const currentChallenge = currentChallengeData?.challenge;

  const handleNext = () => {
    if (currentLocation === formData.challenges.length - 1) {
      setPreviewState('voting');
    } else {
      setCurrentLocation(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentLocation(prev => Math.max(0, prev - 1));
  };

  const handleStart = () => {
    setPreviewState('playing');
  };

  const handleVote = (restaurantName: string) => {
    setVotedRestaurant(restaurantName);
    setPreviewState('finished');
  };

  const handleStartOver = () => {
    setPreviewState('intro');
    setCurrentLocation(0);
    setVotedRestaurant('');
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const renderVotingState = () => (
    <MotionBox>
      <VStack spacing={6} align="stretch" p={{ base: 4, md: 6 }}>
        <VStack spacing={2} align="center" mb={4}>
          <Heading 
            fontSize={{ base: "2xl", md: "3xl" }}
            bgGradient={`linear(to-r, ${formData.color || "orange.500"}, purple.500)`}
            bgClip="text"
            textAlign="center"
            fontWeight="extrabold"
          >
            Time To Cast Your Vote!
          </Heading>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color="gray.600"
            textAlign="center"
            fontWeight="medium"
          >
            Which amazing spot won your foodie heart today? 🌟
          </Text>
          
          <Box
            mt={2}
            p={3}
            bg="blue.50"
            borderRadius="md"
            borderWidth="1px"
            borderColor="blue.200"
            width="100%"
          >
            <Text
              fontSize="sm"
              color="blue.700"
              textAlign="center"
            >
              Note: This is a preview. In the actual tour, all visited locations will be available for voting.
            </Text>
          </Box>
        </VStack>

        <VStack spacing={4} align="stretch" maxHeight="50vh" overflowY="auto">
          {formData.challenges.map((challengeId, index) => {
            const challenge = challengeQueries[index].challenge;
            
            return (
              <MotionBox
                key={challengeId}
                as="button"
                width="100%"
                onClick={() => handleVote(challenge?.title || "Unknown Location")}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                transition={{ duration: 0.2 }}
              >
                <Box
                  p={4}
                  borderRadius="lg"
                  border="2px solid"
                  borderColor={votedRestaurant === challenge?.title ? formData.color || "orange.500" : "gray.200"}
                  bg={votedRestaurant === challenge?.title ? `${formData.color}20` : "white"}
                  _hover={{
                    borderColor: formData.color || "orange.500",
                    bg: `${formData.color}10`
                  }}
                >
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold" fontSize="lg">
                      {challenge?.title || "Loading..."}
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {challenge?.address || "Loading address..."}
                    </Text>
                  </VStack>
                </Box>
              </MotionBox>
            );
          })}
        </VStack>

        <Button
          style={{
            backgroundColor: formData.color || "orange.500",
            color: formData.color && isDarkColor(formData.color) ? "white" : "black"
          }}
          onClick={() => setPreviewState('finished')}
          isDisabled={!votedRestaurant}
          width="100%"
          size="lg"
        >
          Submit Vote
        </Button>
      </VStack>
    </MotionBox>
  );

  const renderFinishedState = () => (
    <MotionBox>
      <VStack 
        spacing={{ base: 6, md: 8 }} 
        align="center" 
        justify="center" 
        p={{ base: 4, md: 6 }} 
        width="100%" 
        maxWidth="500px" 
        mx="auto"
      >
        <MotionBox
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          {logoImage ? (
            <Image 
              src={logoImage}
              alt="Tour Logo"
              w={{ base: 32, md: 40 }}
              h={{ base: 32, md: 40 }}
              objectFit="contain"
            />
          ) : (
            <Box 
              w={{ base: 32, md: 40 }}
              h={{ base: 32, md: 40 }}
              bg={`${formData.color || "orange.500"}20`}
              borderRadius="full"
            />
          )}
        </MotionBox>

        <MotionVStack
          spacing={{ base: 2, md: 3 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          width="100%"
        >
          <Heading 
            size={{ base: "lg", md: "xl" }}
            bgGradient={`linear(to-r, ${formData.color || "orange.500"}, purple.500)`}
            bgClip="text"
            textAlign="center"
            px={2}
          >
            Congratulations, Food Explorer! 🎉
          </Heading>
          
          <Text 
            fontSize={{ base: "md", md: "lg" }} 
            color="gray.600" 
            textAlign="center"
            px={2}
          >
            Thanks for previewing the tour! <br />
          </Text>

          <Box 
            p={{ base: 3, md: 4 }}
            bg={`${formData.color}15` || "orange.50"} 
            borderRadius="xl"
            borderWidth="2px"
            borderColor={formData.color || "orange.500"}
            width="100%"
          >
            <Text 
              fontSize={{ base: "sm", md: "md" }} 
              textAlign="center" 
              fontWeight="medium"
            >
              Your favorite spot: <br />
              <Text 
                as="span" 
                color={formData.color || "orange.500"} 
                fontSize={{ base: "md", md: "lg" }} 
                fontWeight="bold"
              >
                {votedRestaurant || "Not selected"}
              </Text>
            </Text>
          </Box>
        </MotionVStack>

        {formData.allowReviews && <Divider />}

        {formData.allowReviews && (
          <MotionVStack
            spacing={{ base: 3, md: 4 }}
            width="100%"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <Heading 
              size={{ base: "sm", md: "md" }} 
              color="gray.700"
            >
              Share Your Experience
            </Heading>
            
            <Textarea
              placeholder="Tell us about your food tour experience..."
              resize="none"
              borderColor="gray.200"
              _focus={{ borderColor: formData.color || "orange.500" }}
              height={{ base: "80px", md: "100px" }}
              fontSize={{ base: "sm", md: "md" }}
              p={3}
            />

            <Button
              leftIcon={<ThumbsUp size={18} />}
              width="100%"
              size={{ base: "sm", md: "md" }}
              style={{ 
                backgroundColor: formData.color || "orange.500",
                color: formData.color && isDarkColor(formData.color) ? "white" : "black"
              }}
            >
              Submit Feedback
            </Button>
          </MotionVStack>
        )}

        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 2, md: 3 }}
          width="100%"
          maxWidth="400px"
        >
          {formData.allowRetries && (
            <Button
              leftIcon={<Play size={18} />}
              style={{ 
                backgroundColor: formData.color || "orange.500", 
                color: formData.color && isDarkColor(formData.color) ? "white" : "black" 
              }}
              onClick={handleStartOver}
              width="100%"
              size={{ base: "sm", md: "md" }}
            >
              Start Over
            </Button>
          )}
          {formData.allowInvites && (
            <Button
              leftIcon={<Share2 size={18} />}
              onClick={() => {} /* Add share logic here */}
              variant="outline"
              width="100%"
              size={{ base: "sm", md: "md" }}
              style={{ borderColor: formData.color || "orange.500" }}
            >
              Share Results
            </Button>
          )}
        </Stack>
      </VStack>
    </MotionBox>
  );

  const renderContent = () => {
    const motionProps = {
      initial: { opacity: 0, y: 10 },
      animate: { opacity: 1, y: 0 },
      transition: { duration: 0.2 },
      exit: { opacity: 0, y: 10 }
    };

    return (
      <Box 
        maxHeight={{ base: "70vh", md: "600px" }} 
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: formData.color || 'purple.500',
            borderRadius: '24px',
          },
        }}
      >
        {previewState === 'intro' && (
          <MotionBox {...motionProps}>
            <Container maxW="container.md" py={[4, 6, 8]}>
              <VStack spacing={[4, 6, 8]} align="center" justify="center">
                {/* Tour Header */}
                <VStack spacing={4} align="center">
                  {logoImage && (
                    <Image 
                      src={logoImage} 
                      alt="Tour Logo" 
                      maxH="150px" 
                      mb={2} 
                    />
                  )}
                  <Heading 
                    fontSize={{ base: "2xl", md: "3xl" }}
                    bgGradient={`linear(to-r, ${formData.color || "orange.500"}, purple.500)`}
                    bgClip="text"
                    textAlign="center"
                    fontWeight="extrabold"
                  >
                    {formData.title}
                  </Heading>
                  <Text 
                    color="gray.600" 
                    textAlign="center"
                    fontSize={{ base: "md", md: "lg" }}
                  >
                    {formData.description}
                  </Text>
                </VStack>

                {/* Updated Location Card */}
                <Card width="100%" variant="outline" p={5}>
                  <CardBody>
                    <VStack spacing={4} align="stretch">
                      <HStack spacing={3}>
                        <Icon as={MapPin} color="red.500" boxSize={6} />
                        <VStack align="start" spacing={0}>
                          <Text fontWeight="bold" fontSize="lg">
                            {currentChallenge?.title || "First Stop"}
                          </Text>
                          <Text color="gray.600" fontSize="sm">
                            {currentChallenge?.address || `${formData.challenges.length} Amazing Locations`}
                          </Text>
                        </VStack>
                      </HStack>
                      <NavigationButtons
                        mapUrl={generateMultiStopMapUrl(formData.challenges, null)}
                        userLocation={null}
                        isLoadingLocation={false}
                        onFetchLocation={() => {}}
                        isDisabled={true}
                        challenges={[{address: formData.address, title: formData.title}]}
                        currentChallenge={0}
                      />
                    </VStack>
                  </CardBody>
                </Card>

                <Button
                  onClick={handleStart}
                  style={{
                    backgroundColor: formData.color || "orange.500",
                    color: formData.color && isDarkColor(formData.color) ? "white" : "black"
                  }}
                  width="100%"
                  maxWidth="300px"
                  size={["md", "lg"]}
                  fontSize={["md", "lg"]}
                  py={[4, 6]}
                  boxShadow="md"
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg"
                  }}
                  transition="all 0.2s"
                >
                  Get Started
                </Button>
              </VStack>
            </Container>
          </MotionBox>
        )}

        {previewState === 'playing' && (
          <MotionBox {...motionProps}>
            <VStack spacing={4} align="stretch" width="100%">
              <Box px={4} pt={4}>
                <Progress
                  value={(currentLocation / formData.challenges.length) * 100}
                  sx={{
                    '& > div': {
                      transition: 'all 0.3s ease-in-out',
                      backgroundColor: formData.color || "#805AD5" 
                    }
                  }}
                  size="sm"
                  borderRadius="full"
                  hasStripe
                  isAnimated
                  bg="gray.100"
                />
              </Box>

              <Box
                position="relative"
                height={{ base: "150px", md: "200px" }}
                width="100%"
                bg="gray.100"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="none"
              >
                <VStack spacing={1}>
                  <Icon as={Map} boxSize={6} color="gray.400" />
                  <Text color="gray.500" fontSize="xs">Map preview</Text>
                </VStack>
              </Box>

              <Box px={4} pb={4}>
                <Flex 
                  direction={{ base: "column", md: "row" }} 
                  gap={4}
                  bg="white"
                  borderRadius="xl"
                  overflow="hidden"
                  boxShadow="sm"
                  _dark={{ bg: "gray.800" }}
                >
                  {/* Location Details */}
                  <Box 
                    flex={1} 
                    p={4}
                    bg="gray.50"
                    _dark={{ bg: "gray.700" }}
                  >
                    <VStack spacing={3} align="stretch">
                      <Heading 
                        size="md"
                        color="orange.800"
                        textAlign="left"
                      >
                        {currentChallenge?.title || `Stop ${currentLocation + 1}`}
                      </Heading>
                      <Text 
                        color="gray.700" 
                        fontSize="sm" 
                        lineHeight="tall"
                      >
                        {currentChallenge?.description || "Location description will appear here"}
                      </Text>
                      <Flex 
                        alignItems="center" 
                        bg="blue.50" 
                        p={2} 
                        borderRadius="md"
                        _dark={{ bg: "blue.900" }}
                      >
                        <Icon as={MapPin} boxSize={4} color="blue.500" mr={2} />
                        <Link
                          href={`https://maps.google.com/?q=${encodeURIComponent(currentChallenge?.address || '')}`}
                          isExternal
                          display="flex"
                          alignItems="center"
                          color="blue.700"
                          fontSize="sm"
                          fontWeight="medium"
                          _hover={{ color: "blue.500", textDecoration: "none" }}
                        >
                          {currentChallenge?.address || "Location address will appear here"}
                        </Link>
                      </Flex>
                    </VStack>
                  </Box>

                  {/* Photo Upload Section */}
                  <Box 
                    flex={1} 
                    p={4}
                    borderLeft={{ base: "none", md: "1px" }}
                    borderTop={{ base: "1px", md: "none" }}
                    borderColor="gray.200"
                    _dark={{ borderColor: "gray.600" }}
                  >
                    <VStack spacing={3} align="stretch" w="100%">
                      <Button
                        style={{ 
                          backgroundColor: formData.color || "#805AD5",
                          color: formData.color && isDarkColor(formData.color) ? "white" : "black" 
                        }}
                        leftIcon={<Camera />}
                        isDisabled
                        size="sm"
                        width="100%"
                        height="36px"
                        _hover={{ 
                          transform: "translateY(-2px)",
                          boxShadow: "lg" 
                        }}
                        transition="all 0.2s"
                      >
                        Take Photo (0/2)
                      </Button>
                      <SimpleGrid 
                        spacing={2} 
                        w="100%"
                        minH="80px"
                        bg="gray.50"
                        borderRadius="md"
                        p={2}
                        placeItems="center"
                      >
                        <Text color="gray.400" fontSize="xs" textAlign="center">
                          Photos will appear here
                        </Text>
                      </SimpleGrid>
                    </VStack>
                  </Box>
                </Flex>

                {/* Progress and Next Location */}
                <Flex 
                  justify="space-between" 
                  align="center" 
                  mt={4}
                  direction={{ base: "column", sm: "row" }}
                  gap={3}
                >
                  <Text fontSize="sm" textAlign="center" color="gray.500">
                    {currentLocation + 1} / {formData.challenges.length}
                  </Text>

                  {currentLocation < formData.challenges.length - 1 && (
                    <MotionBox
                      bg="linear-gradient(135deg, #FF8C42 0%, #FFB566 100%)"
                      borderRadius="md" 
                      p={3}
                      m={1}
                      boxShadow="0 4px 6px rgba(0,0,0,0.1)"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      _hover={{ transform: "translateY(-2px)", boxShadow: "0 6px 8px rgba(0,0,0,0.15)" }}
                    >
                      <Flex align="center" justify="center" gap={2}>
                        <Text fontSize="xs" fontWeight="500" color="white">
                          Next up:
                        </Text>
                        <Text fontSize="xs" fontWeight="600" color="white">
                          {currentChallenge?.title || `Stop ${currentLocation + 2}`}
                        </Text>
                        <Icon as={ArrowRight} color="white" />
                      </Flex>
                    </MotionBox>
                  )}
                </Flex>
              </Box>
            </VStack>
          </MotionBox>
        )}

        {previewState === 'voting' && (
          renderVotingState()
        )}

        {previewState === 'finished' && (
          renderFinishedState()
        )}
      </Box>
    );
  };

  if (mediaLoading || logoMediaLoading) return <Spinner />;

  return (
    <Box position="relative">
      <Box
        bgImage={bgImage}
        bgColor={formData.color || "gray.500"}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        minHeight="500px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={4}
        position="relative"
        borderRadius="xl"
        overflow="hidden"
      >
        <Box
          bg="white"
          borderRadius="xl"
          maxWidth={{ base: "95vw", md: "600px" }}
          width="100%"
          overflow="hidden"
          boxShadow="xl"
          border="2px solid"
          borderColor={formData.color || "purple.500"}
        >
          {renderContent()}
        </Box>

        {previewState === 'playing' && (
          <Flex 
            mt={4} 
            width="100%" 
            maxWidth={{ base: "95vw", md: "600px" }}
            direction="column"
            gap={4}
          >
            <ButtonGroup 
              spacing={4} 
              width="100%" 
              display="flex"
              justifyContent="space-between"
            >
              <Button
                leftIcon={<ArrowLeft size={16} />}
                onClick={handlePrevious}
                isDisabled={currentLocation === 0}
                colorScheme="blackAlpha"
                size="md"
                flex={1}
                maxW="45%"
              >
                Previous
              </Button>
              <Button
                rightIcon={<ArrowRight size={16} />}
                onClick={handleNext}
                colorScheme="blackAlpha"
                size="md"
                flex={1}
                maxW="45%"
              >
                {currentLocation === formData.challenges.length - 1 ? "Finish" : "Next"}
              </Button>
            </ButtonGroup>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default HuntPreview;
