import React from "react";
import {
    Box,
    Heading,
    Text,
    VStack,
    HStack,
    Button,
    Icon,
    Badge,
    SimpleGrid,
    Card,
    CardHeader,
    CardBody,
    useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "../../context/auth";
import { useCreateBillingPortalSessionMutation } from "../../graphql/generated";
import {
    CreditCardIcon,
    ExternalLinkIcon,
} from "lucide-react";

type SubscriptionInfo = {
    status: "active" | "inactive" | "past_due";
    plan: "FREE" | "BASIC" | "PLUS";
    periodEnd: string;
    amount: number;
    hasConnectedAccount: boolean;
    hasSubscription: boolean;
};

const useBillingPortal = () => {
    const [createBillingPortalSession] = useCreateBillingPortalSessionMutation();

    return async () => {
        try {
            const { data } = await createBillingPortalSession();
            if (data?.createBillingPortalSession) {
                window.open(data.createBillingPortalSession, "_blank");
            } else {
                window.location.href = "/#pricing";
            }
        } catch (error) {
            window.location.href = "/#pricing";
        }
    };
};

const SubscriptionCard = ({ subscriptionInfo, onManageClick }: { subscriptionInfo: SubscriptionInfo, onManageClick: () => void }) => {
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('purple.200', 'purple.600');
    const textColor = useColorModeValue('purple.800', 'white');
    const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');

    const statusColors = {
        active: "green",
        inactive: "gray",
        past_due: "red",
    };

    const planColors = {
        FREE: "gray",
        BASIC: "purple",
        PLUS: "yellow",
        PRO: "orange",
    };
    if (subscriptionInfo.amount === 0) {
        // return a smaller card with only the manage subscription button
        return (
            <Card
                bg={cardBg}
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="xl"
                boxShadow="lg"
                transition="all 0.3s"
                height={"15vh"}
            >
                <CardBody p={4}>
                    <VStack spacing={3} align="stretch">
                        <HStack justify="space-between">
                            <Text fontSize="md" fontWeight="medium" color={textColor}>
                                Plan
                            </Text>
                            <Badge colorScheme="gray" px={2} py={0.5} borderRadius="full" fontSize="xs">
                                FREE
                            </Badge>
                        </HStack>

                        <Button
                            onClick={onManageClick}
                            colorScheme="purple"
                            size="md"
                            w="full"
                            rightIcon={<Icon as={ExternalLinkIcon} />}
                            bgGradient="linear(to-r, purple.400, purple.600)"
                            _hover={{
                                bgGradient: "linear(to-r, purple.500, purple.700)",
                                transform: "translateY(-1px)",
                                shadow: "md"
                            }}
                        >
                            Manage Subscription
                        </Button>
                    </VStack>
                </CardBody>
            </Card>
        );
    }

    return (
        <Card
            bg={cardBg}
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="xl"
            boxShadow="lg"
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-4px)', shadow: 'xl' }}
            height="full"
        >
            <CardHeader borderBottomWidth="1px" borderColor={borderColor}>
                <HStack justify="space-between" align="center">
                    <HStack spacing={3}>
                        <Icon as={CreditCardIcon} boxSize={6} color="purple.500" />
                        <Heading size="md" color={textColor}>Subscription</Heading>
                    </HStack>
                    <Badge
                        colorScheme={statusColors[subscriptionInfo.status]}
                        variant="subtle"
                        px={3}
                        py={1}
                        borderRadius="full"
                        textTransform="uppercase"
                        letterSpacing="wider"
                    >
                        {subscriptionInfo.status.replace("_", " ")}
                    </Badge>
                </HStack>
            </CardHeader>
            <CardBody>
                <VStack align="stretch" spacing={6}>
                    <SimpleGrid columns={2} spacing={4}>
                        <VStack align="start" spacing={2}>
                            <Text color={secondaryTextColor} fontSize="sm" fontWeight="medium">Current Plan</Text>
                            <Badge
                                colorScheme={planColors[subscriptionInfo.plan]}
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="md"
                            >
                                {subscriptionInfo.plan}
                            </Badge>
                        </VStack>
                        {subscriptionInfo.amount > 0 && (
                            <VStack align="start" spacing={2}>
                                <Text color={secondaryTextColor} fontSize="sm" fontWeight="medium">Period End</Text>
                                <Text fontWeight="semibold" color={textColor}>{subscriptionInfo.periodEnd}</Text>
                            </VStack>
                        )}
                    </SimpleGrid>

                    {subscriptionInfo.amount > 0 && (<Box>
                        <Text color={secondaryTextColor} fontSize="sm" fontWeight="medium" mb={2}>Monthly Amount</Text>

                        <Text fontSize="3xl" fontWeight="bold" color={textColor}>
                            ${subscriptionInfo.amount.toFixed(2)}
                            <Text as="span" fontSize="sm" color={secondaryTextColor} ml={1}>/month</Text>
                        </Text>

                    </Box>
                    )}

                    <Button
                        onClick={onManageClick}
                        rightIcon={<ExternalLinkIcon />}
                        colorScheme="purple"
                        size="lg"
                        w="full"
                        bgGradient="linear(to-r, purple.400, purple.600)"
                        _hover={{
                            bgGradient: "linear(to-r, purple.500, purple.700)",
                            transform: "translateY(-1px)",
                            shadow: "md"
                        }}
                    >
                        Manage Subscription
                    </Button>
                </VStack>
            </CardBody>
        </Card>
    );
};

const BillingPage: React.FC = () => {
    const { user: tempUser } = useAuth();
    const handleBillingPortal = useBillingPortal();
    const bgColor = useColorModeValue('gray.50', 'gray.800');
    type User = typeof tempUser & {
        externalAccountId: string | null;
        externalCustomerId: string | null;
    };
    const user: User = {
        ...tempUser,
        externalCustomerId: null,
        externalAccountId: null,
    };
    type UserType = "FREE" | "BASIC" | "PLUS";
    const subscriptionInfo: SubscriptionInfo = {
        status: user?.userType === "FREE" ? "inactive" : "active",
        plan: user?.userType === "FREE" ? "FREE" : user?.userType === "BASIC" ? "BASIC" : "PRO" as UserType,
        periodEnd: "December 31, 2024",
        amount: user?.userType === "FREE" ? 0 : user?.userType === "BASIC" ? 29.99 : 39.99,
        hasConnectedAccount: !!user?.externalAccountId,
        hasSubscription: !!user?.externalCustomerId,
    };

    return (
        <Box p={4} bg={bgColor} minH="100vh">
            <VStack spacing={8} align="stretch">

                <SubscriptionCard
                    subscriptionInfo={subscriptionInfo}
                    onManageClick={handleBillingPortal}
                />
                <Card
                    bg={useColorModeValue('purple.50', 'purple.900')}
                    borderRadius="xl"
                    boxShadow="md"
                >
                    <CardBody p={6}>
                        <VStack align="start" spacing={4}>
                            <Heading size="md" color="purple.700" _dark={{ color: 'purple.200' }}>
                                Need Help?
                            </Heading>
                            <Text color="purple.600" _dark={{ color: 'purple.300' }}>
                                If you have any questions about billing or your subscription, our support team is here to help.
                            </Text>
                            <Button
                                leftIcon={<Icon as={ExternalLinkIcon} />}
                                variant="link"
                                color="purple.600"
                                _dark={{ color: 'purple.200' }}
                                onClick={() => {
                                    window.location.href = "mailto:support@culinarycastle.com";
                                }}
                            >
                                Contact Support
                            </Button>
                        </VStack>
                    </CardBody>
                </Card>
            </VStack>
        </Box>
    );
};

export default BillingPage;
