import React, { useState } from 'react';
import {
    VStack, Box, Flex, Text, Icon, useColorModeValue, Badge, Avatar,
    Input, InputGroup, InputLeftElement, InputRightElement, Button,
    Tabs, TabList, TabPanels, Tab, TabPanel, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    useDisclosure, Textarea, SimpleGrid, Card, CardHeader, CardBody, CardFooter, Select,
    Tooltip, Menu, MenuButton, MenuList, MenuItem, IconButton, Spinner
} from '@chakra-ui/react';
import { Search, Mail, UserPlus, Clock, MoreVertical, Edit, Trash, Users, Upload } from 'lucide-react';
import { HuntDetails, useGetHuntsWithParticipantsQuery, useSendParticipantInviteMutation } from '../../graphql/generated';
import { format, isAfter } from 'date-fns';
import { FaTimes } from 'react-icons/fa';
import Banner from "../../components/shared/banner";

const ParticipantsPage: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedParticipant, setSelectedParticipant] = useState<any>(null);
    const [selectedHunt, setSelectedHunt] = useState<string>('');
    const [emails, setEmails] = useState('');
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [sendParticipantInvite, { loading: isSendingInvite }] = useSendParticipantInviteMutation();

    const bgColor = useColorModeValue('gray.50', 'gray.800');
    const cardBgColor = useColorModeValue('white', 'gray.700');
    const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const searchBgColor = useColorModeValue('white', 'gray.700');
    const searchBorderColor = useColorModeValue('gray.300', 'gray.600');
    const searchIconColor = useColorModeValue('gray.400', 'gray.500');
    const [inviteHuntId, setInviteHuntId] = useState<string>('');

    const [showBanner, setShowBanner] = useState(false);
    const [bannerProps, setBannerProps] = useState<{
        type: "success" | "error" | "info";
        title: string;
        message: string;
    }>({
        type: "success",
        title: "",
        message: ""
    });

    const { data, loading, error } = useGetHuntsWithParticipantsQuery({
        variables: {
            huntId: selectedHunt
        }
    });
    const { data: allHuntsData } = useGetHuntsWithParticipantsQuery({
        variables: {
            huntId: inviteHuntId
        },
    });

    if (loading) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }
    if (error) return <Text>Error loading participants: {error.message}</Text>;

    const formatDate = (dateString: string, includeTime: boolean = false) => {
        const formatString = includeTime ? 'MMM d, yyyy h:mm a' : 'MMM d, yyyy';
        return format(new Date(dateString), formatString);
    };

    const isHuntActive = (endDate: string) => {
        return isAfter(new Date(endDate), new Date());
    };

    const filteredtours = data?.huntParticipantsAndChallenges.map(hunt => ({
        ...hunt,
        participants: hunt.participants.filter(participant =>
            participant.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            participant.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            participant.email.toLowerCase().includes(searchQuery.toLowerCase())
        ).map(participant => ({
            ...participant,
            formattedJoinDate: formatDate(participant.startedAt, true)
        })),
        formattedEndDate: formatDate(hunt.endDate)
    })).filter(hunt => hunt.participants.length > 0) || [];

    const handleMessageParticipant = (participant: any) => {
        setSelectedParticipant(participant);
        onOpen();
    };

    const handleMessageAllParticipants = (hunt: HuntDetails) => {
        setSelectedParticipant({ name: `All participants in ${hunt.title}`, isGroup: true, huntId: hunt.id });
        onOpen();
    };

    const handleInvite = async () => {
        const emailList = emails.split(',').map(email => email.trim()).filter(email => email !== '');
        if (!(emailList.length > 0 && inviteHuntId)) {
            setBannerProps({
                type: "error",
                title: "Invalid Input",
                message: "Please enter valid email addresses and select a hunt."
            });
            setShowBanner(true);
            return;
        }

        try {
            const response = await sendParticipantInvite({
                variables: {
                    huntId: inviteHuntId,
                    emails: emailList
                }
            });

            if (response.data?.sendParticipantInvite) {
                setBannerProps({
                    type: "success",
                    title: "Invitations Sent",
                    message: "Successfully sent invitation(s)."
                });
                setShowBanner(true);
                setEmails('');
                setSelectedHunt('');
            } else {
                throw new Error("No invitations were sent");
            }
        } catch (error) {
            setBannerProps({
                type: "error",
                title: "Error",
                message: error instanceof Error ? error.message : "An unknown error occurred"
            });
            setShowBanner(true);
        }
    };

    const handleCsvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setCsvFile(file);
            console.log(`CSV file uploaded: ${file.name}`);
        }
    };

    const ParticipantCard = ({ participant, onMessage }: { participant: any, onMessage: (participant: any) => void }) => (
        <Card bg={cardBgColor} borderColor={borderColor} borderWidth="1px" boxShadow="md" transition="all 0.3s" _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}>
            <CardHeader>
                <Flex justify="space-between" align="center">
                    <Flex align="center" maxWidth="calc(100% - 40px)">
                        <Avatar size={["sm", "md"]} name={participant.name} src={`https://api.dicebear.com/6.x/initials/svg?seed=${participant.name}`} mr={3} display={["none", "none", "block"]} />
                        <Box>
                            <Text fontWeight="bold" fontSize={["sm", "md"]} isTruncated>{participant.name}</Text>
                            <Text fontSize={["xs", "sm"]} color={secondaryTextColor} isTruncated>{participant.email}</Text>
                        </Box>
                    </Flex>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            icon={<MoreVertical />}
                            variant="ghost"
                            aria-label="Options"
                            size="sm"
                            position="absolute"
                            top={2}
                            right={2}
                        />
                        <MenuList>
                            <MenuItem icon={<Edit />}>Edit Profile</MenuItem>
                            <MenuItem icon={<Trash />} color="red.500">Remove from Hunt</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </CardHeader>
            <CardBody>
                <Flex justify="space-between" align="center" flexDirection={["column", "row"]}>
                    <Badge colorScheme={participant.status === "Active" ? "green" : "red"} fontSize="0.8em" px={2} py={1} borderRadius="full" mb={[2, 0]}>
                        {participant.status}
                    </Badge>
                    <Flex align="center">
                        <Icon as={Clock} color={secondaryTextColor} mr={1} />
                        <Text fontSize="sm" color={secondaryTextColor}>Joined: {participant.formattedJoinDate}</Text>
                    </Flex>
                </Flex>
            </CardBody>
            <CardFooter>
                <Button
                    leftIcon={<Icon as={Mail} />}
                    colorScheme="blue"
                    variant="solid"
                    size="sm"
                    onClick={() => onMessage(participant)}
                    w="full"
                >
                    Message
                </Button>
            </CardFooter>
        </Card>
    );

    return (
        <Box p={[3, 6]} bg={bgColor} minHeight="100vh">
            <VStack spacing={6} align="stretch">
                <InputGroup size="md" flex={1}>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={Search} color={searchIconColor} boxSize={5} />
                    </InputLeftElement>
                    <Input
                        placeholder="Search participants..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        bg={searchBgColor}
                        borderColor={searchBorderColor}
                        _hover={{ borderColor: 'purple.400' }}
                        _focus={{ borderColor: 'purple.400', boxShadow: '0 0 0 1px #9F7AEA' }}
                        fontSize="md"
                        borderRadius="md"
                        pl={12}
                        pr={searchQuery ? 12 : 4}
                    />
                    {searchQuery && (
                        <InputRightElement>
                            <IconButton
                                size="sm"
                                onClick={() => setSearchQuery('')}
                                icon={<FaTimes />}
                                variant="ghost"
                                colorScheme="purple"
                                borderRadius="md"
                                aria-label="Clear search"
                            />
                        </InputRightElement>
                    )}
                </InputGroup>

                <Tabs colorScheme="purple" variant='enclosed' isFitted>
                    <TabList mb={4}>
                        <Tab fontSize={["xs", "sm", "md"]}>Active Participants</Tab>
                        <Tab fontSize={["xs", "sm", "md"]}>Completed tours</Tab>
                        <Tab fontSize={["xs", "sm", "md"]}>Invite Participants</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            {filteredtours.filter(hunt => isHuntActive(hunt.endDate)).length === 0 ? (
                                <Flex direction="column" align="center" justify="center" mb={8} p={6} bg="white" borderRadius="md" border="1px" borderColor={borderColor}>
                                    <Text fontSize="2xl" fontWeight="bold" color="purple.800" textAlign="center" mb={2} mt={2}>
                                        No Active Participants
                                    </Text>
                                    <Text fontSize="md" color="gray.600" textAlign="center" mb={2}>
                                        Invite participants to your tours to see them here!
                                    </Text>
                                </Flex>
                            ) : (
                                <Accordion allowMultiple>
                                    {filteredtours.filter(hunt => isHuntActive(hunt.endDate)).map((hunt) => (
                                        <AccordionItem
                                            key={hunt.id}
                                            mb={4}
                                            border="1px"
                                            borderColor={borderColor}
                                            borderRadius="md"
                                            bg={cardBgColor}
                                            _hover={{ boxShadow: 'md' }}
                                            transition="all 0.3s"
                                        >
                                            <AccordionButton>
                                                <Flex flex="1" align="center">
                                                    <Icon as={Users} mr={4} color="purple.500" />
                                                    <Box textAlign="left">
                                                        <Text fontWeight="bold" color="purple.500">{hunt.title}</Text>
                                                        <Text fontSize="sm" color={secondaryTextColor}>
                                                            {hunt.participants.length} participants | Ends: {hunt.formattedEndDate}
                                                        </Text>
                                                    </Box>
                                                </Flex>
                                                <AccordionIcon ml={4} />
                                            </AccordionButton>
                                            <AccordionPanel pb={4}>
                                                <SimpleGrid columns={[1, 2, 3]} spacing={[3, 6]}>
                                                    {hunt.participants.map((participant) => (
                                                        <ParticipantCard
                                                            key={participant.id}
                                                            participant={{
                                                                id: participant.id,
                                                                name: `${participant.firstName} ${participant.lastName}`,
                                                                email: participant.email,
                                                                status: participant.isBlocked ? "Inactive" : "Active",
                                                                formattedJoinDate: participant.formattedJoinDate
                                                            }}
                                                            onMessage={handleMessageParticipant}
                                                        />
                                                    ))}
                                                </SimpleGrid>
                                                <Button
                                                    mt={6}
                                                    leftIcon={<Icon as={Mail} />}
                                                    colorScheme="blue"
                                                    variant="outline"
                                                    onClick={() => handleMessageAllParticipants(hunt)}
                                                    w="full"
                                                    size={["sm", "md"]}
                                                >
                                                    Message All Participants
                                                </Button>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            )}
                        </TabPanel>

                        <TabPanel>
                            {filteredtours.filter(hunt => !isHuntActive(hunt.endDate)).length === 0 ? (
                                <Flex direction="column" align="center" justify="center" mb={8} p={6} bg="white" borderRadius="md" border="1px" borderColor={borderColor}>
                                    <Text fontSize="2xl" fontWeight="bold" color="purple.800" textAlign="center" mb={2} mt={2}>
                                        No Completed Tours Available
                                    </Text>
                                    <Text fontSize="md" color="gray.600" textAlign="center" mb={2}>
                                        Your completed tours will appear here once they're finished.
                                    </Text>
                                </Flex>
                            ) : (
                                <Accordion allowMultiple>
                                    {filteredtours.filter(hunt => !isHuntActive(hunt.endDate)).map((hunt) => (
                                        <AccordionItem
                                            key={hunt.id}
                                            mb={4}
                                            border="1px"
                                            borderColor={borderColor}
                                            borderRadius="md"
                                            bg={cardBgColor}
                                            _hover={{ boxShadow: 'md' }}
                                            transition="all 0.3s"
                                        >
                                            <AccordionButton>
                                                <Flex flex="1" align="center">
                                                    <Icon as={Users} mr={4} color="purple.500" />
                                                    <Box textAlign="left">
                                                        <Text fontWeight="bold" color="purple.500">{hunt.title}</Text>
                                                        <Text fontSize="sm" color={secondaryTextColor}>
                                                            {hunt.participants.length} participants | Ended: {hunt.formattedEndDate}
                                                        </Text>
                                                    </Box>
                                                </Flex>
                                                <AccordionIcon ml={4} />
                                            </AccordionButton>
                                            <AccordionPanel pb={4}>
                                                <SimpleGrid columns={[1, 2, 3]} spacing={[3, 6]}>
                                                    {hunt.participants.map((participant) => (
                                                        <ParticipantCard
                                                            key={participant.id}
                                                            participant={{
                                                                id: participant.id,
                                                                name: `${participant.firstName} ${participant.lastName}`,
                                                                email: participant.email,
                                                                status: participant.isBlocked ? "Inactive" : "Active",
                                                                formattedJoinDate: participant.formattedJoinDate
                                                            }}
                                                            onMessage={handleMessageParticipant}
                                                        />
                                                    ))}
                                                </SimpleGrid>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            )}
                        </TabPanel>

                        <TabPanel>
                            <VStack spacing={6} align="stretch">
                                <Flex align="center">
                                    <InputGroup flex="1" mr={2}>
                                        <InputLeftElement pointerEvents="none">
                                            <Icon as={Mail} color={searchIconColor} />
                                        </InputLeftElement>
                                        <Input
                                            placeholder="Enter email address(es), separated by commas"
                                            value={emails}
                                            onChange={(e) => setEmails(e.target.value)}
                                            size={["sm", "md"]}
                                        />
                                    </InputGroup>
                                    <Tooltip label="Upload CSV" placement="top">
                                        <Box>
                                            <Input
                                                type="file"
                                                accept=".csv"
                                                onChange={handleCsvUpload}
                                                display="none"
                                                id="csv-upload"
                                            />
                                            <IconButton
                                                as="label"
                                                htmlFor="csv-upload"
                                                aria-label="Upload CSV"
                                                icon={<Icon as={Upload} />}
                                                colorScheme="teal"
                                                variant="outline"
                                                cursor="pointer"
                                                size={["sm", "md"]}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Flex>
                                {csvFile && (
                                    <Text fontSize="sm" color="green.500">
                                        File uploaded: {csvFile.name}
                                    </Text>
                                )}
                                <Select
                                    placeholder="Select Tour"
                                    value={inviteHuntId}
                                    onChange={(e) => {
                                        e.preventDefault(); // Ensure no default action is triggered
                                        setInviteHuntId(e.target.value); // Update the state with the selected hunt
                                    }}
                                    size={["sm", "md"]}
                                >
                                    {allHuntsData?.huntParticipantsAndChallenges.filter(hunt => isHuntActive(hunt.endDate)).map((hunt) => (
                                        <option key={hunt.id} value={hunt.id}>{hunt.title}</option>
                                    ))}
                                </Select>
                                <Button
                                    leftIcon={<Icon as={UserPlus} />}
                                    colorScheme="purple"
                                    onClick={handleInvite}
                                    isLoading={isSendingInvite}
                                    loadingText="Sending..."
                                    isDisabled={(!emails && !csvFile) || !inviteHuntId || isSendingInvite}
                                    w="full"
                                    size={["sm", "md"]}
                                >
                                    Send Invitation(s)
                                </Button>
                            </VStack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Message to {selectedParticipant?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Textarea placeholder="Type your message here..." />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Send
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Banner
                isVisible={showBanner}
                onClose={() => setShowBanner(false)}
                title={bannerProps.title}
                message={bannerProps.message}
                type={bannerProps.type}
            />
        </Box>
    );
};

export default ParticipantsPage;
