import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Container,
  VStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Avatar,
  Tag,
  TagLabel,
  TagLeftIcon,
  SimpleGrid,
  Divider,
  Spinner,
  Select,
  Link as ChakraLink,
  Skeleton,
  SkeletonCircle,
  SkeletonText
} from "@chakra-ui/react";
import {
  FiSearch,
  FiClock,
  FiMapPin,
  FiMail,
  FiCheck,
  FiDollarSign,
  FiStar,
  FiEdit,
  FiTrash2,
  FiMessageSquare,
} from "react-icons/fi";
import { EventFilter, EventType } from "../../graphql/generated";
import { isToday, isYesterday, isThisWeek, isThisMonth, formatDistance } from "date-fns";
import { IconType } from "react-icons";
import { groupBy } from "lodash";
import { useGetEventsQuery } from "../../graphql/generated";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { Activity as ActivitiesIcon } from "lucide-react";
import debounce from "lodash/debounce";

// Type definitions
type Activity = {
  id: string;
  type: EventType;
  participant?: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    hunt: {
      id: string;
      title: string;
    }
  };
  content?: string;
  link?: string;
  huntTitle?: string;
  huntId?: string;
  createdAt: string;
  updatedAt: string;
};

type ActionCategory = "admin" | "participant";

type FilterType = {
  type: string;
  timeRange: string;
  userType: string;
  search: string;
};

type ActivityFiltersProps = {
  onFilterChange: (key: string, value: string) => void;
  onSearch: (value: string) => void;
  searchValue: string;
};

type BadgeDetails = {
  color: string;
  text: string;
};

// Helper to determine action category
const getActionCategory = (eventType: EventType): ActionCategory => {
  const adminActions = [
    "CHALLENGE_CREATED",
    "CHALLENGE_UPDATED",
    "CHALLENGE_DELETED",
    "INVITE_SENT",
    "INVITE_RESENT",
    "MESSAGE_SENT"
  ];
  
  return adminActions.includes(eventType) ? "admin" : "participant";
};

// Helper to get badge details
const getBadgeDetails = (action: EventType): BadgeDetails => {
  switch (action) {
    case EventType.ChallengeCreated:
      return { color: "blue", text: "Added Location" };
    case EventType.ChallengeUpdated:
      return { color: "orange", text: "Updated Location" };
    case EventType.InviteSent:
      return { color: "purple", text: "Sent Invite" };
    case EventType.InviteResent:
      return { color: "gray", text: "Resent Invite" };
    case EventType.InviteDelivered:
      return { color: "navy", text: "Delivered Invite" };
    case EventType.InviteOpened:
      return { color: "purple", text: "Opened Invite" };
    case EventType.InviteBounced:
      return { color: "red", text: "Bounced Invite" };
    case EventType.InviteClicked:
      return { color: "cyan", text: "Clicked Invite" };
    case EventType.ParticipantJoined:
      return { color: "green", text: "Joined Tour" };
    case EventType.HuntDeleted:
      return { color: "red", text: "Deleted Tour" };
    case EventType.MessageSent:
      return { color: "blue", text: "Sent Message" };
    case EventType.HuntStarted:
      return { color: "teal", text: "Started Tour" };
    case EventType.HuntCompleted:
      return { color: "green", text: "Completed Tour" };
    case EventType.ResponseSubmitted:
      return { color: "orange", text: "New Photo Submission" };
    case EventType.PaymentSuccessful:
      return { color: "green", text: "Payment Successful" };
    case EventType.PaymentFailed:
      return { color: "red", text: "Payment Failed" };
    case EventType.VoteSubmitted:
      return { color: "blue", text: "Submitted Vote" };
    default:
      return { color: "gray", text: "Action" };
  }
};

const getEventTypeOptions = () => [
  { value: "", label: "All Types" },
  { value: EventType.ChallengeCreated, label: "Location Added" },
  { value: EventType.ChallengeUpdated, label: "Location Updated" },
  { value: EventType.InviteSent, label: "Invite Sent" },
  { value: EventType.InviteResent, label: "Invite Resent" },
  { value: EventType.InviteClicked, label: "Invite Clicked" },
  { value: EventType.InviteDelivered, label: "Invite Delivered" },
  { value: EventType.InviteOpened, label: "Invite Opened" },
  { value: EventType.InviteBounced, label: "Invite Bounced" },
  { value: EventType.ParticipantJoined, label: "Participant Joined" },
  { value: EventType.VoteSubmitted, label: "Vote Submitted" },
  { value: EventType.MessageSent, label: "Message Sent" },
  { value: EventType.HuntStarted, label: "Tour Started" },
  { value: EventType.HuntCompleted, label: "Tour Completed" },
  { value: EventType.ResponseSubmitted, label: "Photo Submission" },
];

const ActivityFilters: React.FC<ActivityFiltersProps> = ({ 
  onFilterChange, 
  onSearch,
  searchValue 
}) => {
  const options = getEventTypeOptions();
  
  return (
    <Box mb={6} px={{ base: 2, md: 0 }}>
      <SimpleGrid 
        columns={{ base: 1, md: 2 }} 
        spacing={{ base: 3, md: 4 }}
        mb={6}
      >
        <InputGroup size={{ base: "sm", md: "md" }}>
          <InputLeftElement>
            <Icon as={FiSearch} color="gray.400" />
          </InputLeftElement>
          <Input 
            placeholder="Search activities..." 
            variant="filled"
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            _focus={{ borderColor: "purple.500", bg: "white" }}
          />
        </InputGroup>
        <Select
          size={{ base: "sm", md: "md" }}
          placeholder="Filter by type"
          onChange={(e) => onFilterChange("type", e.target.value)}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </SimpleGrid>
    </Box>
  );
};

const ActivityIcon: React.FC<{ action: EventType }> = ({ action }) => {
  const iconMap: Record<string, IconType> = {
    [EventType.ChallengeCreated]: FiMapPin,
    [EventType.ChallengeUpdated]: FiEdit,
    [EventType.ChallengeDeleted]: FiTrash2,
    [EventType.InviteSent]: FiMail,
    [EventType.InviteResent]: FiMail,
    [EventType.MessageSent]: FiMessageSquare,
    [EventType.HuntStarted]: FiClock,
    [EventType.HuntCompleted]: FiCheck,
    [EventType.ResponseSubmitted]: FiStar,
    [EventType.PaymentSuccessful]: FiDollarSign,
  };

  const IconComponent = iconMap[action] || FiClock;
  return <Icon as={IconComponent} />;
};

const ActivityCard: React.FC<{ activity: Activity }> = ({ activity }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.400");
  const badgeDetails = getBadgeDetails(activity.type);

  const isUserAction = activity.content?.startsWith('You');
  const userName = activity.participant ? 
    `${activity.participant.firstName} ${activity.participant.lastName}` : 
    `${activity.content}`;

  const handleHuntClick = (huntId: string) => {
    navigate(`/home/tours/${huntId}`);
  };

  return (
    <Box
      bg={cardBg}
      p={{ base: 3, md: 4 }}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      _hover={{ 
        transform: { base: "none", md: "translateY(-2px)" },
        shadow: { base: "none", md: "md" },
        borderColor: "purple.200"
      }}
      transition="all 0.2s"
      width="100%"
    >
      <Flex 
        align="flex-start"
        gap={3}
        direction={{ base: "column", sm: "row" }}
      >
        <Avatar
          size={{ base: "sm", md: "md" }}
          name={activity.participant?.email ?? userName}
          src={isUserAction && user?.profilePicture ? `${user.profilePicture}` : `/placeholder.svg?text=${activity.participant?.email.charAt(0)}`}
          bg={getActionCategory(activity.type) === "admin" ? "purple.500" : "blue.500"}
        />
        
        <Box flex={1} width="100%">
          <Flex 
            justify="space-between"
            align={{ base: "flex-start", sm: "center" }}
            direction={{ base: "column", sm: "row" }}
            mb={2}
            gap={{ base: 1, sm: 0 }}
          >
            <Text 
              fontWeight="medium"
              fontSize={{ base: "sm", md: "md" }}
              lineHeight="short"
            >
              {activity.content}
            </Text>
            <Text 
              fontSize="xs"
              color={textColor}
              mt={{ base: 1, sm: 0 }}
            >
              {formatDistance(new Date(activity.createdAt), new Date(), { addSuffix: true })}
            </Text>
          </Flex>
          
          <Flex 
            align={{ base: "flex-start", sm: "center" }}
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            gap={{ base: 2, sm: 0 }}
          >
            {activity.huntId && (
              <ChakraLink
                fontSize={{ base: "xs", sm: "sm" }}
                color="purple.500"
                fontWeight="medium"
                onClick={() => handleHuntClick(activity.huntId!)}
                cursor="pointer"
                _hover={{ textDecoration: "underline" }}
              >
                {activity.huntTitle}
              </ChakraLink>
            )}
            <Tag
              size="sm"
              variant="subtle"
              colorScheme={badgeDetails.color}
              borderRadius="full"
              px={2.5}
              py={1}
            >
              <Flex gap={1} align="center">
                <TagLeftIcon as={ActivityIcon} action={activity.type} boxSize={2.5} />
                <TagLabel fontSize={{ base: "xs", sm: "sm" }}>{badgeDetails.text}</TagLabel>
              </Flex>
            </Tag>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

const DateSection: React.FC<{ date: string; activities: Activity[] }> = ({ date, activities }) => {
  const sectionBg = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("purple.100", "purple.800");
  
  return (
    <Box mb={8}>
      <Flex align="center" mb={4}>
        <Divider flex={1} borderColor={borderColor} />
        <Text
          px={4}
          py={1}
          bg={sectionBg}
          fontSize="sm"
          fontWeight="medium"
          color="purple.500"
          borderRadius="full"
          border="1px"
          borderColor={borderColor}
        >
          {date}
        </Text>
        <Divider flex={1} borderColor={borderColor} />
      </Flex>
      
      <VStack spacing={4}>
        {activities.map((activity, index) => (
          <ActivityCard key={index} activity={activity} />
        ))}
      </VStack>
    </Box>
  );
};

const ActivitySkeleton: React.FC = () => {
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box
      bg={cardBg}
      p={4}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      width={{ base: "100%", md: "80%" }}
      mx="auto"
    >
      <Flex align="center" gap={4}>
        <SkeletonCircle size="12" />
        <Box flex={1}>
          <Flex justify="space-between" align="center" mb={2}>
            <Skeleton height="20px" width="150px" />
            <Skeleton height="16px" width="100px" />
          </Flex>
          <SkeletonText mt="2" noOfLines={2} spacing="4" skeletonHeight="3" />
          <Flex align="center" justify="space-between" mt={4}>
            <Skeleton height="16px" width="120px" />
            <Skeleton height="24px" width="100px" borderRadius="full" />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

const DateSectionSkeleton: React.FC = () => {
  const borderColor = useColorModeValue("purple.100", "purple.800");

  return (
    <Box mb={8}>
      <Flex align="center" mb={4}>
        <Divider flex={1} borderColor={borderColor} />
        <Skeleton height="24px" width="100px" borderRadius="full" mx={4} />
        <Divider flex={1} borderColor={borderColor} />
      </Flex>
      <VStack spacing={4}>
        {[1, 2, 3].map((i) => (
          <ActivitySkeleton key={i} />
        ))}
      </VStack>
    </Box>
  );
};

const InfiniteScroll: React.FC<{ 
  onLoadMore: () => void; 
  hasMore: boolean; 
  loading: boolean; 
}> = ({ 
  onLoadMore, 
  hasMore, 
  loading 
}) => {
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const currentLoading = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          loadMore();
        }
      },
      { threshold: 0.1, rootMargin: "100px" }
    );
    
    const loadMore = async () => {
      if (currentLoading.current) return;
      currentLoading.current = true;
      onLoadMore();
      currentLoading.current = false;
    }

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onLoadMore, hasMore, loading]);

  return (
    <div ref={loadMoreRef} style={{ height: "20px", margin: "20px 0" }}>
      {loading && <Spinner size="sm" color="purple.500" />}
      {!hasMore && <Text textAlign="center" color="gray.500" fontSize="sm">No more activities</Text>}
    </div>
  );
};

const ActivitiesPage: React.FC = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState<FilterType>({
    type: "",
    timeRange: "",
    userType: "all",
    search: ""
  });

  // Debounce search query to prevent too many re-renders
  const debouncedSearch = useMemo(
    () => debounce((query: string) => {
      setFilter(prev => ({ ...prev, search: query }));
    }, 300),
    []
  );

  // Handle search input
  const handleSearch = (value: string) => {
    setSearchQuery(value);
    debouncedSearch(value);
  };

  const eventFilter: EventFilter = useMemo(() => ({
    type: filter.type ? (filter.type as EventType) : undefined,
    onlyParticipant: filter.userType === "participant",
    huntId: undefined,
    participantId: undefined,
  }), [filter]);

  const { events, loading, error, loadMore, hasMore } = useEvents(eventFilter, searchQuery);

  // Group activities by date
  const groupedActivities = useMemo(() => {
    const sortedEvents = [...events].sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    
    return groupBy(sortedEvents, (activity) => {
      const date = new Date(activity.createdAt);
      if (isToday(date)) return "Today";
      if (isYesterday(date)) return "Yesterday";
      if (isThisWeek(date)) return "This Week";
      if (isThisMonth(date)) return "This Month";
      return "Earlier";
    });
  }, [events]);

  const handleFilterChange = (key: string, value: string) => {
    setFilter(prev => ({ ...prev, [key]: value }));
  };


  if (loading && events.length === 0) {
    return (
      <Box bg={bgColor} minH="100vh" py={8}>
        <Container maxW="5xl">
          <ActivityFilters 
            onFilterChange={handleFilterChange}
            onSearch={handleSearch}
            searchValue={searchQuery}
          />
          {[1, 2, 3].map((i) => (
            <DateSectionSkeleton key={i} />
          ))}
        </Container>
      </Box>
    );
  }

  return (
    <Box bg={bgColor} minH="100vh" py={8}>
      <Container maxW="5xl">
        <ActivityFilters 
          onFilterChange={handleFilterChange}
          onSearch={handleSearch}
          searchValue={searchQuery}
        />
        
        {error && (
          <Flex justify="center" align="center" minH="60vh">
            <Text>Error loading activities: {error.message}</Text>
          </Flex>
        )}
        
        {Object.entries(groupedActivities).map(([date, activities]) => (
          <DateSection key={date} date={date} activities={activities as Activity[]} />
        ))}
        
        <InfiniteScroll 
          onLoadMore={loadMore}
          hasMore={hasMore}
          loading={loading}
        />
        
        {!loading && events.length === 0 && (
          <Box
            display="flex" 
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minH="60vh"
            p={8}
            textAlign="center"
          >
            <Icon as={ActivitiesIcon} boxSize={12} color="gray.400" mb={4} />
            <Text fontSize="xl" fontWeight="medium" color="gray.600" mb={2}>
              {searchQuery ? "No matching activities found" : "No Activities Yet"}
            </Text>
            <Text color="gray.500">
              {searchQuery 
                ? "Try adjusting your search terms"
                : "Activities will appear here once you start creating and managing tours"}
            </Text>
          </Box>
        )}
      </Container>
    </Box>
  );
};

const useEvents = (filter: EventFilter, searchQuery: string) => {
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const PAGE_SIZE = 20;

  // Filter events based on search query
  const filterEvents = useCallback((searchQuery: string) => (events: Activity[]) => {
    if (!searchQuery) return events;
    
    const query = searchQuery.toLowerCase();
    return events.filter(event => 
      event.content?.toLowerCase().includes(query) ||
      event.participant?.firstName?.toLowerCase().includes(query) ||
      event.participant?.lastName?.toLowerCase().includes(query) ||
      event.participant?.email?.toLowerCase().includes(query) ||
      event.huntTitle?.toLowerCase().includes(query)
    );
  }, []);

  const { data, loading, error, fetchMore } = useGetEventsQuery({
    variables: {
      filter,
      limit: PAGE_SIZE,
      offset: 0
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true
  });

  // Apply search filter to events
  const filteredEvents = useMemo(() => 
    filterEvents(searchQuery)(data?.events as Activity[] || []),
    [data?.events, searchQuery, filterEvents]
  );

  const loadMore = useCallback(async () => {
    if (!hasMore || loading) return;

    const nextOffset = (page + 1) * PAGE_SIZE;
    
    try {
      const result = await fetchMore({
        variables: {
          filter,
          limit: PAGE_SIZE,
          offset: nextOffset
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          const newEvents = fetchMoreResult.events.filter(
            newEvent => !prev.events.some(
              existingEvent => existingEvent.id === newEvent.id
            )
          );

          if (newEvents.length === 0) {
            setHasMore(false);
            return prev;
          }

          if (newEvents.length < PAGE_SIZE) {
            setHasMore(false);
          }

          return {
            events: [...prev.events, ...newEvents]
          };
        }
      });

      setPage(prev => prev + 1);

      if (result.data.events.length < PAGE_SIZE) {
        setHasMore(false);
      }
    } catch (err) {
      console.error('Error loading more events:', err);
      setHasMore(false);
    }
  }, [page, hasMore, loading, fetchMore, filter]);

  // Reset pagination when filter or search changes
  useEffect(() => {
    setPage(0);
    setHasMore(true);
  }, [filter, searchQuery]);

  return {
    events: filteredEvents,
    loading,
    error,
    loadMore,
    hasMore: hasMore && filteredEvents.length >= PAGE_SIZE
  };
};

export default ActivitiesPage;
