import { useState, useEffect } from "react";
import {
    Box, Button, FormControl, Input, Heading, Text,
    Flex, InputGroup, InputLeftElement, Link,
    useColorModeValue, VStack, Icon, Image, Divider,
    IconButton,
} from "@chakra-ui/react";
import { FaArrowLeft, FaEnvelope, FaUser } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { useSignupMutation } from "../../graphql/generated";
import { z } from "zod";
import { CloseIcon } from "@chakra-ui/icons";
import { Avatar } from "@chakra-ui/react";
import env from "../../env";

const SignupSchema = z.object({
    email: z.string().email("Invalid email address"),
    username: z.string().min(1, "Username is required"),
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    profilePicture: z.instanceof(File).nullable(),
});

const VerificationBanner = ({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) => {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            zIndex="banner"
            transform={isVisible ? "translateY(0)" : "translateY(-100%)"}
            transition="transform 0.3s ease-in-out"
        >
            <Flex
                bg="purple.500"
                color="white"
                p={4}
                alignItems="center"
                justifyContent="center"
                boxShadow="md"
                borderBottomRadius="md"
                position="relative"
            >
                <Box textAlign="center">
                    <Text fontWeight="bold">Verification Email Sent</Text>
                    <Text fontSize="sm">Please check your email to complete the signup process.</Text>
                </Box>
                <IconButton
                    icon={<CloseIcon />}
                    aria-label="Close notification"
                    size="sm"
                    variant="ghost"
                    color="white"
                    onClick={onClose}
                    _hover={{ bg: "purple.600" }}
                    position="absolute"
                    right={2}
                    top="50%"
                    transform="translateY(-50%)"
                />
            </Flex>
        </Box>
    );
};

const ErrorBanner = ({ isVisible, onClose, message }: { isVisible: boolean; onClose: () => void; message: string }) => {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            zIndex="banner"
            transform={isVisible ? "translateY(0)" : "translateY(-100%)"}
            transition="transform 0.3s ease-in-out"
        >
            <Flex
                bg="red.500"
                color="white"
                p={4}
                alignItems="center"
                justifyContent="center"
                boxShadow="md"
                borderBottomRadius="md"
                position="relative"
            >
                <Box textAlign="center">
                    <Text fontWeight="bold">Error</Text>
                    <Text fontSize="sm">{message}</Text>
                </Box>
                <IconButton
                    icon={<CloseIcon />}
                    aria-label="Close notification"
                    size="sm"
                    variant="ghost"
                    color="white"
                    onClick={onClose}
                    _hover={{ bg: "red.600" }}
                    position="absolute"
                    right={2}
                    top="50%"
                    transform="translateY(-50%)"
                />
            </Flex>
        </Box>
    );
};

const handleImageUpload = async (file: File): Promise<string | null> => {
  try {
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
        mutation UploadMedia($files: [UploadMediaInput!]!) {
          uploadMedia(files: $files) {
            id
          }
        }
      `,
      variables: { 
        files: [{ 
          docType: file.type, 
          file: null 
        }] 
      }
    }));
    formData.append('map', JSON.stringify({ "0": ["variables.files.0.file"] }));
    formData.append('0', file);

    const response = await fetch(`${env.REACT_APP_SERVER_URL}/graphql`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
      headers: {
        'Apollo-Require-Preflight': 'true',
        'x-apollo-operation-name': 'uploadMedia',
      },
    });

    if (!response.ok) {
      throw new Error('Server responded with an error');
    }

    const data = await response.json();
    if (data.errors) {
      throw new Error(data.errors[0].message);
    }
    return data.data.uploadMedia[0].id;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

const BetaBanner = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="banner"
      bg="blue.500"
    >
      <Flex
        color="white"
        p={2}
        alignItems="center"
        justifyContent="center"
        boxShadow="md"
      >
        <Text fontSize="sm" fontWeight="medium">
          🎯 Beta Testing: Join our early access program! Limited features available
        </Text>
      </Flex>
    </Box>
  );
};

const GoogleSignupButton = () => {
  const handleGoogleSignup = () => {
    window.location.href = `${env.REACT_APP_SERVER_URL}/auth/google`;
  };

  return (
    <Button
      w="full"
      variant="outline"
      leftIcon={
        <Image
          src="/images/google.png"
          alt="Google Icon"
          height="20px"
          width="20px"
        />
      }
      onClick={handleGoogleSignup}
      borderColor="purple.200"
      color="purple.600"
      _hover={{ 
        bg: "gray.50",
        transform: "translateY(-2px)",
        boxShadow: "md"
      }}
    >
      Sign up with Google
    </Button>
  );
};

const SignupPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [signupFormData, setSignupFormData] = useState({
        email: "",
        username: "",
        firstName: "",
        lastName: "",
        profilePicture: null as File | null,
    });
    const [error, setError] = useState<string>("");
    const [showVerificationBanner, setShowVerificationBanner] = useState(false);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get("email");
        if (email) {
            setSignupFormData(prevData => ({ ...prevData, email }));
        }
    }, [location.search]);

    const [signupMutation] = useSignupMutation({
        onCompleted: (data) => {
            console.log("Signup mutation completed:", data);
            setShowVerificationBanner(true);
            // Don't navigate immediately, let the user see the verification banner
        },
        onError: (error) => {
            console.error("Signup mutation error:", error);
            setErrorMessage(error.message);
            setShowErrorBanner(true);
        },
    });

    useEffect(() => {
        if (showVerificationBanner) {
            const timer = setTimeout(() => {
                setShowVerificationBanner(false);
                navigate("/login");
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showVerificationBanner, navigate]);

    useEffect(() => {
        if (showErrorBanner) {
            const timer = setTimeout(() => {
                setShowErrorBanner(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showErrorBanner]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target;
        if (name === "profilePicture" && files) {
            setSignupFormData({ ...signupFormData, profilePicture: files[0] });
        } else {
            setSignupFormData({ ...signupFormData, [name]: value });
        }
        setError("");
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            SignupSchema.parse(signupFormData);
            setError("");

            // Only upload the image if a profile picture is selected
            const mediaId = signupFormData.profilePicture 
              ? await handleImageUpload(signupFormData.profilePicture) 
              : null;

            signupMutation({
                variables: {
                    input: {
                        email: signupFormData.email,
                        username: signupFormData.username,
                        firstName: signupFormData.firstName,
                        lastName: signupFormData.lastName,
                        mediaId, // This will be null if no profile picture was uploaded
                    },
                },
            });
        } catch (error) {
            if (error instanceof z.ZodError) {
                setError(error.errors[0].message);
            } else {
                setError("An error occurred during signup");
            }
        }
    };

    const handleCloseBanner = () => {
        setShowVerificationBanner(false);
        navigate("/login"); // Navigate to login after closing the banner
    };

    const handleCloseErrorBanner = () => {
        setShowErrorBanner(false);
    };

    const bgColor = useColorModeValue("white", "gray.900");
    const cardBg = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("purple.800", "purple.200");
    const secondaryTextColor = useColorModeValue("purple.600", "purple.300");

    return (
        <>
            <BetaBanner />
            <VerificationBanner isVisible={showVerificationBanner} onClose={handleCloseBanner} />
            <ErrorBanner isVisible={showErrorBanner} onClose={handleCloseErrorBanner} message={errorMessage} />
            <Flex 
                height="100vh" 
                bg={bgColor} 
                overflow="hidden"
                pt={{ base: "50px", md: "32px" }}
            >
                {/* Left side - Image */}
                <Box width={{ base: "0%", md: "60%" }} position="relative" overflow="hidden">
                    <Image
                        src="/images/phone.jpg"
                        alt="Signup background"
                        objectFit="cover"
                        w="100%"
                        filter="blur(2px)"
                        h="100%"
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        bg="blackAlpha.500"
                        zIndex="1"
                    />
                    <VStack
                        position="absolute"
                        bottom="8"
                        left="8"
                        zIndex="2"
                        align="flex-start"
                        spacing="3"
                    >
                        <Heading
                            color="white"
                            fontSize={{ base: "3xl", md: "4xl" }}
                            textShadow="0 2px 4px rgba(0,0,0,0.4)"
                        >
                            Culinary Castle
                        </Heading>
                        <Text
                            color="white"
                            fontSize={{ base: "lg", md: "xl" }}
                            textShadow="0 1px 2px rgba(0,0,0,0.4)"
                        >
                            Culinary adventures made by you
                        </Text>
                    </VStack>
                </Box>

                {/* Right side - Signup Form */}
                <Flex
                    width={{ base: "100%", md: "40%" }}
                    direction="column"
                    p={{ base: 4, md: 8 }}
                    overflowY="auto"
                    bg={cardBg}
                    borderRadius="lg"
                    boxShadow="md"
                >
                    <Button
                        leftIcon={<FaArrowLeft />}
                        variant="ghost"
                        alignSelf="flex-start"
                        onClick={() => {
                            window.location.href = `https://culinary-castle.com`
                        }}
                        mb={4}
                        color={textColor}
                        _hover={{ bg: "purple.50", color: "purple.600" }}
                    >
                        Back to Home
                    </Button>

                    <VStack spacing={6} align="stretch" width="100%" maxW="400px" mx="auto" mt={14}>
                        <VStack spacing={2} align="center">
                            <Image
                                src="/images/logo.png"
                                alt="Culinary Castle Logo"
                                w="80px"
                                h="auto"
                                mb={4}
                            />
                            <Heading color={textColor} fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold">
                                Create an Account
                            </Heading>
                            <Text color={secondaryTextColor} fontSize="sm" textAlign="center">
                                Enter your details to get started
                            </Text>
                        </VStack>

                        <form onSubmit={handleSubmit}>
                            <VStack spacing={4}>
                                <FormControl isInvalid={!!error}>
                                    <InputGroup size="md">
                                        <InputLeftElement pointerEvents="none">
                                            <Icon as={FaEnvelope} color="purple.400" />
                                        </InputLeftElement>
                                        <Input
                                            type="email"
                                            name="email"
                                            value={signupFormData.email}
                                            onChange={handleChange}
                                            placeholder="Email"
                                            bg={cardBg}
                                            borderColor="purple.200"
                                            _hover={{ borderColor: "purple.300" }}
                                            _focus={{ borderColor: "purple.500", boxShadow: "0 0 0 1px purple.500" }}
                                        />
                                    </InputGroup>
                                    <InputGroup size="md" mt={4}>
                                        <InputLeftElement pointerEvents="none">
                                            <Icon as={FaUser} color="purple.400" />
                                        </InputLeftElement>
                                        <Input
                                            type="text"
                                            name="username"
                                            value={signupFormData.username}
                                            onChange={handleChange}
                                            placeholder="Username"
                                            bg={cardBg}
                                            borderColor="purple.200"
                                            _hover={{ borderColor: "purple.300" }}
                                            _focus={{ borderColor: "purple.500", boxShadow: "0 0 0 1px purple.500" }}
                                        />
                                    </InputGroup>
                                    <InputGroup size="md" mt={4}>
                                        <InputLeftElement pointerEvents="none">
                                            <Icon as={FaUser} color="purple.400" />
                                        </InputLeftElement>
                                        <Input
                                            type="text"
                                            name="firstName"
                                            value={signupFormData.firstName}
                                            onChange={handleChange}
                                            placeholder="First Name"
                                            bg={cardBg}
                                            borderColor="purple.200"
                                            _hover={{ borderColor: "purple.300" }}
                                            _focus={{ borderColor: "purple.500", boxShadow: "0 0 0 1px purple.500" }}
                                        />
                                    </InputGroup>
                                    <InputGroup size="md" mt={4}>
                                        <InputLeftElement pointerEvents="none">
                                            <Icon as={FaUser} color="purple.400" />
                                        </InputLeftElement>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            value={signupFormData.lastName}
                                            onChange={handleChange}
                                            placeholder="Last Name"
                                            bg={cardBg}
                                            mb={4}
                                            borderColor="purple.200"
                                            _hover={{ borderColor: "purple.300" }}
                                            _focus={{ borderColor: "purple.500", boxShadow: "0 0 0 1px purple.500" }}
                                        />
                                    </InputGroup>
                                    <FormControl>
                                        <Flex
                                            direction="column"
                                            align="center"
                                            justify="center"
                                            borderWidth={2}
                                            borderStyle="dashed"
                                            borderColor="purple.200"
                                            borderRadius="md"
                                            p={4}
                                            bg="gray.50"
                                            _hover={{ bg: "gray.100" }}
                                            transition="all 0.2s"
                                            cursor="pointer"
                                            onClick={() => document.getElementById('profilePicture')?.click()}
                                        >
                                            {signupFormData.profilePicture ? (
                                                <Avatar
                                                    size="xl"
                                                    src={URL.createObjectURL(signupFormData.profilePicture)}
                                                    mb={2}
                                                />
                                            ) : (
                                                <Icon as={FaUser} boxSize={6} color="purple.400" mb={2} />
                                            )}
                                            <Text fontSize="sm" fontWeight="medium" color="purple.600">
                                                {signupFormData.profilePicture ? 'Change Profile Picture' : 'Upload Profile Picture'}
                                            </Text>
                                            <Input
                                                type="file"
                                                id="profilePicture"
                                                name="profilePicture"
                                                accept="image/*"
                                                onChange={handleChange}
                                                hidden
                                            />
                                        </Flex>
                                    </FormControl>
                                    {error && <Text color="red.500" fontSize="xs" mt={1}>{error}</Text>}
                                </FormControl>
                                <VStack spacing={4} width="full">
                                    <Button
                                        type="submit"
                                        width="full"
                                        colorScheme="purple"
                                        size="md"
                                        fontSize="sm"
                                        fontWeight="bold"
                                        bg="purple.500"
                                        _hover={{ bg: "purple.600", transform: "translateY(-2px)", boxShadow: "md" }}
                                        transition="all 0.2s"
                                    >
                                        Sign Up
                                    </Button>
                                    <GoogleSignupButton />
                                </VStack>
                            </VStack>
                        </form>

                        <Text fontSize="xs" color={secondaryTextColor} textAlign="center">
                            By signing up, you agree to our Terms of Service and Privacy Policy.
                        </Text>

                        <Flex align="center" my={2}>
                            <Divider flex={1} borderColor="purple.200" />
                            <Text px={3} color={secondaryTextColor} fontSize="xs">OR</Text>
                            <Divider flex={1} borderColor="purple.200" />
                        </Flex>

                        <Text fontSize="sm" color={secondaryTextColor} textAlign="center">
                            Already have an account?{" "}
                            <Link
                                color="purple.500"
                                fontWeight="bold"
                                onClick={() => navigate("/login")}
                                _hover={{ textDecoration: "underline" }}
                            >
                                Log In
                            </Link>
                        </Text>

                        <VStack spacing={2} mt={2}>
                            <Text fontSize="xs" color={secondaryTextColor} textAlign="center">
                                🔒 Your data is protected with industry-standard encryption. 🔒
                            </Text>
                            <Text fontSize="xs" color={secondaryTextColor} textAlign="center">
                                We never share your information without your permission.
                            </Text>
                        </VStack>
                    </VStack>
                </Flex>
            </Flex>
        </>
    );
};

export default SignupPage;
