import Radar  from 'radar-sdk-js';
import env from '../env';

export const geocodeAddress = async (address: string): Promise<{ lat: number; lon: number }> => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`);
  
      if (response.ok) {
        const data = await response.json();
        const { lat, lon } = data[0];
        return { lat: parseFloat(lat), lon: parseFloat(lon) };
      }
      throw new Error('No results found');
    } catch (error) {
      console.error('Geocoding error:', error);
      throw error;
    }
  };


Radar.initialize(env.REACT_APP_RADAR_TOKEN);

export async function reverseGeocode(latitude: number, longitude: number) {
  try {
    const address = await Radar.reverseGeocode({
      latitude: latitude,
      longitude: longitude,
      layers: ['address'],
    });
    return address.addresses[0].formattedAddress;
  } catch (err) {
    console.error(err);
    return null;
  }
}