import { useEffect, useState } from "react";
import { useUpdateProfileMutation } from "../graphql/generated";
import Joyride, { CallBackProps, Step, STATUS, Placement } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { Text, useColorModeValue, VStack, HStack, useBreakpointValue } from "@chakra-ui/react";

interface OnboardingGuideProps {
  isOpen: boolean;
  onClose: () => void;
  openTourDrawer: () => void;
  openLocationDrawer: () => void;
  closeLocationDrawer: () => void;
  closeTourDrawer: () => void;
  onComplete: () => void;
  currentStep: number;
}

interface TooltipContentProps {
  title: string;
  description: string;
  description2?: string;
  tip: string;
  icon: string;
}

const useOnboardingSteps = (
  openTourDrawer: () => void,
  openLocationDrawer: () => void,
  updateStep: (step: number) => Promise<void>
): Step[] => {
  const textColor = useColorModeValue("black", "white");
  const secondaryTextColor = useColorModeValue("gray.800", "gray.500");
  const accentColor = useColorModeValue("purple.500", "purple.300");
  const isMobile = useBreakpointValue({ base: true, md: false });

  const TooltipContent = ({ title, description, description2, tip, icon }: TooltipContentProps) => (
    <VStack spacing={2} align={isMobile ? "center" : "start"} p={isMobile ? 0 : 1}>
      <HStack spacing={2} justify={isMobile ? "center" : "flex-start"} width="full">
        <Text as="span" fontSize={isMobile ? "lg" : "xl"} lineHeight={1}>
          {icon}
        </Text>
        <Text
          fontSize={isMobile ? "md" : "lg"}
          fontWeight="600"
          color={textColor}
          letterSpacing="tight"
        >
          {title}
        </Text>
      </HStack>
      <Text
        fontSize={isMobile ? "xs" : "sm"}
        color={secondaryTextColor}
        lineHeight="tall"
        letterSpacing="wide"
        textAlign={isMobile ? "center" : "left"}
      >
        {description}
      </Text>
      {description2 && (
        <Text
          fontSize={isMobile ? "xs" : "sm"}
          color={secondaryTextColor}
          lineHeight="tall"
          letterSpacing="wide"
          textAlign={isMobile ? "center" : "left"}
        >
          {description2}
        </Text>
      )}
      <Text
        fontSize={isMobile ? "xs" : "sm"}
        color={accentColor}
        fontStyle="italic"
        lineHeight="tall"
        letterSpacing="wide"
        textAlign={isMobile ? "center" : "left"}
      >
        {tip}
      </Text>
    </VStack>
  );

  const getStepPlacement = (defaultPlacement: Placement): Placement | "center" => {
    return isMobile ? "center" : defaultPlacement;
  };

  return [
    {
      target: '[data-tour="sidebar"]',
      content: (
        <TooltipContent
          icon="🏰"
          title="Welcome to Your Food Tour HQ!"
          description="Your journey to becoming a successful food tour creator starts here! This dashboard is your command center for creating unforgettable culinary experiences."
          description2="Let's explore how you can bring your food tour vision to life."
          tip="Pro tip: You can always revisit this guide from the help menu!"
        />
      ),
      disableBeacon: true,
      placement: getStepPlacement("right-start"),
      spotlightPadding: isMobile ? 4 : 8,
      floaterProps: {
        disableAnimation: isMobile
      }
    },
    {
      target: '[data-tour="locations-nav"]',
      content: (
        <TooltipContent
          icon="🗺️"
          title="Curate Your Food Spots"
          description="Start by adding the hidden gems and popular eateries that make your city special."
          description2="Each location you add becomes a potential stop in your food tours."
          tip="Pro tip: Add detailed descriptions to make each location irresistible!"
        />
      ),
      disableBeacon: true,
      placement: getStepPlacement("right"),
      spotlightPadding: isMobile ? 2 : 4,
      floaterProps: {
        disableAnimation: isMobile
      }
    },
    {
      target: '[data-tour="tours-nav"]',
      content: (
        <TooltipContent
          icon="🍽️"
          title="Craft Your Food Adventure"
          description="Connect your curated locations to create an unforgettable food journey."
          description2="Design experiences that tell a story through taste, culture, and local flavors!"
          tip="Pro tip: Consider the walking distance between locations when creating your tour."
        />
      ),
      disableBeacon: true,
      placement: getStepPlacement("right"),
      spotlightPadding: isMobile ? 2 : 4,
      floaterProps: {
        disableAnimation: isMobile
      }
    },
    {
      target: '[data-tour="activity-nav"]',
      content: (
        <TooltipContent
          icon="📋"
          title="Watch Your Tours Thrive"
          description="Monitor bookings, reviews, and engagement in real-time."
          description2="See how food enthusiasts are discovering and enjoying your carefully crafted experiences!"
          tip="Pro tip: Regularly check your activity feed to engage with your tour participants."
        />
      ),
      disableBeacon: true,
      placement: getStepPlacement("right"),
      spotlightPadding: isMobile ? 2 : 4,
      floaterProps: {
        disableAnimation: isMobile
      }
    }
  ];
};

const OnboardingGuide = ({
  isOpen,
  onClose,
  openTourDrawer,
  openLocationDrawer,
  closeLocationDrawer,
  closeTourDrawer,
  onComplete,
  currentStep
}: OnboardingGuideProps) => {
  const [updateProfile] = useUpdateProfileMutation();
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(currentStep - 1);
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const updateStep = async (step: number) => {
    try {
      await updateProfile({
        variables: {
          input: {
            onboardingStep: step
          }
        }
      });
    } catch (error) {
      console.error('Error updating step:', error);
    }
  };

  const steps = useOnboardingSteps(openTourDrawer, openLocationDrawer, updateStep);

  useEffect(() => {
    setRun(isOpen);
    setStepIndex(currentStep - 1);
  }, [isOpen, currentStep]);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (type === "step:after") {
      // Handle forward navigation
      if (action === "next") {
        const nextStep = index + 2;
        await updateStep(nextStep);
        
        switch(index) {
          case 0:
            navigate('/home/places');
            setTimeout(() => openLocationDrawer(), 300);
            break;
          case 1:
            navigate('/home/tours');
            setTimeout(() => openTourDrawer(), 300);
            break;
          case 2:
            navigate('/home/activity');
            closeLocationDrawer();
            closeTourDrawer();
            break;
          case 3:
            navigate('/home');
            setTimeout(() => onComplete(), 300);
            break;
        }
      }
      // Handle back navigation
      else if (action === "prev" && index > 0) {
        const prevStep = index;
        await updateStep(prevStep);
        
        switch(index) {
          case 1:
            navigate('/home');
            closeLocationDrawer();
            break;
          case 2:
            navigate('/home/places');
            closeTourDrawer();
            setTimeout(() => openLocationDrawer(), 300);
            break;
          case 3:
            navigate('/home/tours');
            setTimeout(() => openTourDrawer(), 300);
            break;
        }
      }
    }

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
      await updateStep(5);
      onComplete();
      onClose();
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      showProgress
      showSkipButton
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        options: {
          arrowColor: useColorModeValue("#FFFFFF", "#1A202C"),
          backgroundColor: useColorModeValue("#FFFFFF", "#1A202C"),
          overlayColor: "rgba(0, 0, 0, 0.75)",
          primaryColor: "#805AD5",
          textColor: useColorModeValue("#1A202C", "#FFFFFF"),
          zIndex: 10000,
          spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
          beaconSize: isMobile ? 28 : 36,
          width: isMobile ? 290 : 360
        },
        buttonNext: {
          backgroundColor: "#805AD5",
          padding: isMobile ? "10px 16px" : "12px 24px",
          fontSize: isMobile ? "13px" : "14px",
          fontWeight: "600",
          borderRadius: "24px",
          transition: "all 0.2s ease-in-out",
          outline: "none",
          border: "none",
          touchAction: "manipulation"
        },
        buttonBack: {
          color: "#805AD5",
          marginRight: isMobile ? 12 : 16,
          fontSize: isMobile ? "13px" : "14px",
          fontWeight: "600",
          padding: isMobile ? "10px 0" : "12px 0",
          background: "none",
          border: "none",
          outline: "none",
          touchAction: "manipulation",
          minHeight: "44px"
        },
        buttonSkip: {
          color: useColorModeValue("#4A5568", "#A0AEC0"),
          fontSize: isMobile ? "13px" : "14px",
          padding: isMobile ? "10px 0" : "12px 0",
          background: "none",
          border: "none",
          outline: "none",
          touchAction: "manipulation",
          minHeight: "44px"
        },
        tooltip: {
          backgroundColor: useColorModeValue(
            "rgba(255, 255, 255, 0.98)",
            "rgba(26, 32, 44, 0.98)"
          ),
          backdropFilter: "blur(8px)",
          borderRadius: isMobile ? "12px" : "16px",
          boxShadow: useColorModeValue(
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            "0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.18)"
          ),
          border: "1px solid",
          borderColor: useColorModeValue(
            "rgba(226, 232, 240, 0.8)",
            "rgba(45, 55, 72, 0.8)"
          ),
          maxWidth: isMobile ? "calc(100vw - 32px)" : "360px",
          margin: isMobile ? "0 auto" : undefined,
          transform: isMobile ? "translateX(-50%)" : undefined,
          left: isMobile ? "50%" : undefined,
        },
        tooltipContainer: {
          textAlign: isMobile ? "center" : "left",
          padding: isMobile ? "2px" : "4px",
          width: "100%"
        },
        tooltipContent: {
          padding: isMobile ? "12px 16px" : "16px 20px",
          textAlign: isMobile ? "center" : "left"
        },
        tooltipTitle: {
          margin: 0
        },
        tooltipFooter: {
          marginTop: isMobile ? 6 : 8,
          borderTop: "1px solid",
          borderColor: useColorModeValue(
            "rgba(226, 232, 240, 0.8)",
            "rgba(45, 55, 72, 0.8)"
          ),
          paddingTop: isMobile ? 6 : 8,
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-start",
          width: "100%"
        }
      }}
    />
  );
};

export default OnboardingGuide; 