import React, { useEffect, useState } from 'react';
import './app.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Login as LoginPage, Signup as SignupPage, Verify as VerifyPage } from './pages/auth';
import { useAuth } from './context/auth';
import { Spinner, Flex } from '@chakra-ui/react';
import DashboardLayout from './components/shared/layout';
import { LoadingProvider } from "./context/loading";
import {
  TourDetailPage,
  DashboardPage,
  ParticipantsPage,
  ActivitiesPage,
  SettingsPage,
  BillingPage,
  ChatPage,
  ToursPage,
  LocationDetailPage,
  LocationsPage
} from './pages/dashboard';


const useAuthCheck = () => {
  const { user, checkAuthStatus } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const initAuth = async () => {
      await checkAuthStatus();
      setIsLoading(false);
    };
    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const email = params.get('email');

    if (token && email) {
      window.history.replaceState(null, '', '/verify');
    }
  }, [location]);

  return { user, isLoading };
};

function App() {
  const { user, isLoading } = useAuthCheck();
  const [, setShowOnboarding] = useState(false);
  const [tourDrawerOpen, setTourDrawerOpen] = useState(false);
  const [locationDrawerOpen, setLocationDrawerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (user?.isAuth && !isLoading && location.pathname.startsWith('/home')) {
      // Only show onboarding on dashboard pages and check for first visit
      const hasSeenOnboarding = localStorage.getItem('hasSeenOnboarding');
      if (!hasSeenOnboarding) {
        setShowOnboarding(true);
      }
    }
  }, [user?.isAuth, isLoading, location.pathname]);


  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" color="purple.500" />
      </Flex>
    );
  }

  return (
    <LoadingProvider>
      <Routes>
        <Route path="/login" element={user?.isAuth ? <Navigate to="/home" replace /> : <LoginPage />} />
        <Route path="/signup" element={user?.isAuth ? <Navigate to="/home" replace /> : <SignupPage />} />
        <Route
          path="/verify"
          element={
            user?.isAuth ? (
              <Navigate to="/home" replace />
            ) : (
              <VerifyPage />
            )
          }
        />
        <Route
          path="/home/*"
          element={
            user?.isAuth ? (
              <DashboardLayout
                tourDrawerOpen={tourDrawerOpen}
                setTourDrawerOpen={setTourDrawerOpen}
                locationDrawerOpen={locationDrawerOpen}
                setLocationDrawerOpen={setLocationDrawerOpen}
              >
                <Routes>
                  <Route index element={<DashboardPage />} />
                  <Route path="tours" element={<ToursPage />} />
                  <Route path="billing" element={<BillingPage />} />
                  <Route path="participants" element={<ParticipantsPage />} />
                  <Route path="activity" element={<ActivitiesPage />} />
                  <Route path="settings" element={<SettingsPage />} />
                  <Route path="places" element={<LocationsPage />} />
                  <Route path="tours/:tourId" element={<TourDetailPage />} />
                  <Route path="locations/:locationId" element={<LocationDetailPage />} />
                  <Route path="messages/:tourId/:participantId" element={<ChatPage />} />
                </Routes>
              </DashboardLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </LoadingProvider>
  );
}

export default App;