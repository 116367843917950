import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Heading,
  Text,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';
import {
  useCreateChallengeMutation,
  useUpdateChallengeMutation,
  GameType,
  GameDifficulty,
} from '../graphql/generated';
import { RadarMapEmbed, Place } from './radar-map-embed';
import { reverseGeocode } from '../helpers/geocoding';
import ChallengePreview from './challenge-preview';
import ChallengeForm from './challenge-form';
import SuccessBanner from './success-banner';
import { useForwardGeocode } from '../hooks/useForwardGeocode';

type DrawerProps = {
  onClose: () => void;
  isOpen: boolean;
  initialData?: any;
  isEditing?: boolean;
};

type Challenge = {
  title: string;
  description: string;
  address: string;
};

type FormErrors = {
  title?: string;
  description?: string;
  points?: string;
  address?: string;
};

const useDrawerLogic = (initialData: any, isEditing: boolean, onClose: () => void) => {
  const initialState: Challenge = { title: "", description: "", address: "" };
  const [challenge, setChallenge] = useState<Challenge>(() => {
    if (isEditing && initialData) {
      return {
        title: initialData.title || "",
        description: initialData.description || "",
        address: initialData.address || "",
      };
    }
    return initialState;
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [activeView, setActiveView] = useState<'edit' | 'preview'>('edit');
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const resetForm = () => {
    if (!isEditing) setChallenge(initialState);
    else if (initialData) {
      setChallenge({
        title: initialData.title || "",
        description: initialData.description || "",
        address: initialData.address || "",
      });
    }
    setErrors({});
    setActiveView('edit');
    setIsFormValid(false);
  };

  const handleInputChange = (field: string, value: any) => {
    setChallenge((p) => ({ ...p, [field]: value }));
    setErrors((p) => ({ ...p, [field]: '' }));
  };

  return {
    challenge,
    errors,
    activeView,
    showSuccessBanner,
    isFormValid,
    setActiveView,
    setShowSuccessBanner,
    setIsFormValid,
    handleInputChange,
    resetForm,
    setErrors,
  };
};

export default function CreateChallengeDrawer({
  onClose,
  isOpen,
  initialData,
  isEditing = false,
}: DrawerProps) {
  const toast = useToast();
  const [createChallenge, { loading: createLoading }] = useCreateChallengeMutation();
  const [updateChallenge, { loading: updateLoading }] = useUpdateChallengeMutation();
  const loading = createLoading || updateLoading;

  const {
    challenge,
    errors,
    activeView,
    showSuccessBanner,
    isFormValid,
    setActiveView,
    setShowSuccessBanner,
    setIsFormValid,
    handleInputChange,
    setErrors,
    resetForm,
  } = useDrawerLogic(initialData, isEditing, onClose);

  useEffect(() => {
    if (!isOpen) resetForm();
  }, [isOpen]);

  const handleSelectPlace = useCallback(
    async (place: Place) => {
      const addr = await reverseGeocode(place.latitude, place.longitude);
      handleInputChange('address', addr);
      handleInputChange('title', place.name);
    },
    [reverseGeocode, handleInputChange]
  );

  async function handleSubmit() {
    if (!validateForm()) return;
    try {
      if (isEditing && initialData?.id) {
        await updateChallenge({
          variables: {
            id: initialData.id,
            input: {
              title: challenge.title,
              description: challenge.description,
              address: challenge.address,
            },
          },
        });
      } else {
        await createChallenge({
          variables: {
            input: {
              ...challenge,
              points: 10,
              gameType: GameType.Basic,
              gameDifficulty: GameDifficulty.Medium,
              basic: [
                {
                  text: 'Enter photos or videos that best describe this location',
                  content: 'Give the location a name',
                  expectedContent: 'Enter the name of the location',
                },
              ],
            },
          },
        });
      }
      setShowSuccessBanner(true);
      resetForm();
      onClose();
    } catch (err) {
      console.error(err);
      toast({
        title: 'Error',
        description: 'Failed to save changes',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  function handleCloseBanner() {
    setShowSuccessBanner(false);
  }

  function canShowPreview() {
    return !!challenge.title && !!challenge.address;
  }

  function validateForm() {
    const newErrors: Record<string, string> = {};
    if (!challenge.title) newErrors.title = 'Title is required';
    if (!challenge.description) newErrors.description = 'Description is required';
    if (!challenge.address) newErrors.address = 'Address is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const address = isEditing && initialData?.address ? initialData.address : undefined;
  const coords = useForwardGeocode(address);

  return (
    <>
      {showSuccessBanner && (
        <SuccessBanner
          title={isEditing ? 'Location Updated' : 'Location Added'}
          message={
            isEditing
              ? 'Your location has been successfully updated.'
              : 'Your new location has been successfully added.'
          }
          onClose={handleCloseBanner}
        />
      )}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="0.5px" bg="purple.50" p={3}>
            <Heading size="md" color="purple.600">
              {isEditing ? 'Edit Location' : 'Register New Location'}
            </Heading>
            <Text fontSize="xs" color="gray.600" mt={1}>
              {isEditing
                ? 'Update location details'
                : 'Register a new location for your tour'}
            </Text>
          </DrawerHeader>
          <DrawerBody p={0} bg="purple.50">
            {activeView === 'edit' && (
              <Box p={4}>
                <RadarMapEmbed
                  onSelectPlace={handleSelectPlace}
                  initialLocation={coords ? { lat: coords.lat, lng: coords.lng } : undefined}
                />
                <br />
                <ChallengeForm
                  challenge={challenge}
                  errors={errors}
                  handleInputChange={handleInputChange}
                  setIsFormValid={setIsFormValid}
                  isEditing={isEditing}
                />
              </Box>
            )}
            {activeView === 'preview' && (
              <Box p={4}>
                {canShowPreview() ? (
                  <ChallengePreview
                    challenge={challenge}
                    color="orange.500"
                    onUploadMedia={() => {}}
                  />
                ) : (
                  <Box p={4} textAlign="center">
                    <Text color="gray.500">
                      Please fill out the required fields to see the preview
                    </Text>
                  </Box>
                )}
              </Box>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="purple"
              onClick={handleSubmit}
              isLoading={loading}
              isDisabled={!isFormValid}
            >
              {isEditing ? 'Save Changes' : 'Register Location'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
