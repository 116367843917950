import React, { useCallback, useState, useEffect } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Switch,
  Text,
  Button,
  Tooltip,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Divider,
  Spinner,
  Grid,
  GridItem,
  Box,
  useColorModeValue,
  Flex,
  Image,
  Link,
  IconButton,
  InputGroup,
  InputRightElement,
  ButtonGroup,
  PopoverArrow,
  useBreakpointValue,
  Portal,
  SimpleGrid,
} from '@chakra-ui/react';
import { InfoIcon, CheckIcon, LockIcon, SmallCloseIcon, RepeatIcon } from '@chakra-ui/icons';
import { HexColorPicker } from 'react-colorful';
import { CreateHuntFormData } from './tour-drawer';
import { Challenge } from '../graphql/generated';
import { Select } from 'chakra-react-select';
import { useDropzone } from 'react-dropzone';
import env from '../env';
import { useToast } from '@chakra-ui/react';
import { format, addDays, differenceInDays } from 'date-fns';
import { useGetMediaQuery, useGenerateTextMutation, useGetGenerationsTodayQuery } from '../graphql/generated';
import { Wand2 } from 'lucide-react';

type TourFormProps = {
  formData: CreateHuntFormData;
  setFormData: React.Dispatch<React.SetStateAction<CreateHuntFormData>>;
  errors: Partial<CreateHuntFormData>;
  setErrors: React.Dispatch<React.SetStateAction<Partial<CreateHuntFormData>>>;
  challengesData: any;
  challengesLoading: boolean;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  isPremiumUser: boolean;
  isEditing?: boolean;
};

const useTextGeneration = (
  name: keyof CreateHuntFormData,
  formData: CreateHuntFormData,
  setFormData: React.Dispatch<React.SetStateAction<CreateHuntFormData>>,
  hasRemainingCredits: boolean
) => {
  const [generateText] = useGenerateTextMutation();
  const [generatedText, setGeneratedText] = useState<string>("");
  const [editableText, setEditableText] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const toast = useToast();

  const generatePrompt = (action: string) => {
    const currentText = formData[name] as string;
    const fieldType = name === 'title' ? 'title' : 'description';

    switch (action) {
      case 'generate':
        return `Generate a creative and engaging food tour ${fieldType} that captures the essence of a culinary adventure.`;
      case 'longer':
        return `Make this food tour ${fieldType} longer and more detailed while maintaining its essence: "${currentText}"`;
      case 'shorter':
        return `Make this food tour ${fieldType} more concise while keeping the main points: "${currentText}"`;
      case 'rewrite':
        return `Rewrite this food tour ${fieldType} in a different style while keeping the same meaning: "${currentText}"`;
      case 'improve':
        return `Improve this food tour ${fieldType} by making it more engaging and professional: "${currentText}"`;
      default:
        return '';
    }
  };

  const handleGenerate = async (action: string) => {
    if (!hasRemainingCredits) {
      toast({
        title: "No credits remaining",
        description: "You've used all your AI generation credits for today.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsGenerating(true);
      const response = await generateText({
        variables: {
          input: generatePrompt(action)
        }
      });

      if (response.data?.generateText) {
        const text = response.data.generateText;
        setGeneratedText(text);
        setEditableText(text);
        setIsPopoverOpen(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        // remove ApolloError: or Error: from the message
        const message = error.message.replace(/^ApolloError: |^Error: /, "");
        toast({
          title: "Generation failed",
          description: message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const acceptGeneratedText = () => {
    setFormData(prev => ({ ...prev, [name]: editableText }));
    setGeneratedText("");
    setEditableText("");
  };

  return {
    generatedText,
    editableText,
    setEditableText,
    isGenerating,
    handleGenerate,
    acceptGeneratedText,
    setGeneratedText,
    isPopoverOpen,
    setIsPopoverOpen
  };
};

const TourForm = ({
  formData,
  setFormData,
  errors,
  setErrors,
  challengesData,
  challengesLoading,
  setIsFormValid,
  isPremiumUser,
  isEditing = false,
}: TourFormProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isLogoUploading, setIsLogoUploading] = useState(false);

  const { data: generationsData } = useGetGenerationsTodayQuery({
    pollInterval: 30000,
  });

  const hasRemainingCredits = (generationsData?.generationsToday.remaining ?? 0) > 0;

  const titleGeneration = useTextGeneration('title', formData, setFormData, hasRemainingCredits);
  const descriptionGeneration = useTextGeneration('description', formData, setFormData, hasRemainingCredits);

  const handleInputChange = (name: keyof CreateHuntFormData) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleChallengesChange = (selectedOptions: any) => {
    setFormData((prev: any) => ({ ...prev, challenges: selectedOptions.map((option: any) => option.value) }));
  };

  const handleColorChange = (color: string) => {
    setFormData((prev: any) => ({ ...prev, color }));
  };

  const handleImageUpload = async (file: File, isLogo: boolean = false) => {
    if (isLogo) {
      setIsLogoUploading(true);
    } else {
      setIsUploading(true);
    }

    try {
      const formData = new FormData();
      formData.append('operations', JSON.stringify({
        query: `
          mutation UploadMedia($files: [UploadMediaInput!]!) {
            uploadMedia(files: $files) {
              id
            }
          }
        `,
        variables: {
          files: [{
            docType: file.type,
            file: null
          }]
        }
      }));
      formData.append('map', JSON.stringify({ "0": ["variables.files.0.file"] }));
      formData.append('0', file);

      const response = await fetch(`${env.REACT_APP_SERVER_URL}/graphql`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
        headers: {
          'Apollo-Require-Preflight': 'true',
          'x-apollo-operation-name': 'uploadMedia',
        },
      });

      if (!response.ok) {
        throw new Error('Server responded with an error');
      }

      const data = await response.json();
      if (data.errors) {
        throw new Error(data.errors[0].message);
      }
      if (isLogo) {
        setFormData((prev) => ({ ...prev, logoMediaId: data.data.uploadMedia[0]?.id }));
      } else {
        setFormData((prev) => ({ ...prev, backgroundMediaId: data.data.uploadMedia[0]?.id }));
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      if (isLogo) {
        setIsLogoUploading(false);
      } else {
        setIsUploading(false);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      handleImageUpload(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const textAreaColor = useColorModeValue('white', 'gray.700');
  const textAreaBorderColor = useColorModeValue('gray.300', 'gray.600');
  const inputColor = useColorModeValue('white', 'gray.700');
  const inputBorderColor = useColorModeValue('gray.300', 'gray.600');

  const isMobile = useBreakpointValue({ base: true, md: false });

  const renderFormField = (
    label: string,
    name: keyof CreateHuntFormData,
    type: string = 'text',
    isRequired: boolean = false,
    placeholder: string = ''
  ) => {
    const generation = name === 'title' ? titleGeneration : descriptionGeneration;
    const {
      generatedText,
      editableText,
      setEditableText,
      isGenerating,
      handleGenerate,
      acceptGeneratedText,
      setGeneratedText,
      isPopoverOpen,
      setIsPopoverOpen
    } = generation;

    return (
      <FormControl isRequired={isRequired} isInvalid={!!errors[name]} position="relative">
        <FormLabel fontWeight="bold">{label}</FormLabel>
        <InputGroup>
          {type === 'textarea' ? (
            <Textarea
              name={name}
              value={formData[name] as string}
              placeholder={placeholder}
              onChange={handleInputChange(name)}
              bg={textAreaColor}
              borderColor={textAreaBorderColor}
              pr="40px"
              minH={{ base: "120px", md: "100px" }}
              fontSize={{ base: "sm", md: "md" }}
              _focus={{
                borderColor: "purple.400",
                boxShadow: "0 0 0 1px var(--chakra-colors-purple-400)"
              }}
            />
          ) : (
            <Input
              name={name}
              type={type}
              value={formData[name] as string}
              onChange={handleInputChange(name)}
              bg={inputColor}
              placeholder={placeholder}
              borderColor={inputBorderColor}
              pr="40px"
            />
          )}
          <InputRightElement
            style={{ 
              zIndex: 2,
              position: "absolute",
              right: "8px",
              height: "100%"
            }}
          >
            <Box position="relative" zIndex={2000}>
              <Popover
                placement={isMobile ? "bottom" : "right"}
                autoFocus={false}
                closeOnBlur={true}
                strategy="fixed"
                gutter={8}
                modifiers={[
                  {
                    name: 'preventOverflow',
                    options: {
                      padding: 8,
                      boundary: 'viewport'
                    },
                  }
                ]}
                isLazy
                lazyBehavior="keepMounted"
                isOpen={isPopoverOpen}
                onClose={() => setIsPopoverOpen(false)}
              >
                <PopoverTrigger>
                  <IconButton
                    aria-label="Generate text"
                    icon={<Wand2 size={16} />}
                    size="sm"
                    variant="ghost"
                    colorScheme="purple"
                    isLoading={isGenerating}
                    onClick={() => setIsPopoverOpen(true)}
                    isDisabled={!hasRemainingCredits}
                    title={!hasRemainingCredits ? "No AI credits remaining" : "Generate text"}
                  />
                </PopoverTrigger>
                <Portal containerRef={undefined}>
                  <Box
                    position="relative"
                    zIndex={2000}
                  >
                    <PopoverContent
                      width={isMobile ? "calc(100vw - 32px)" : "300px"}
                      maxWidth={isMobile ? "calc(100vw - 32px)" : "300px"}
                      borderRadius="xl"
                      shadow="xl"
                      _focus={{ outline: "none" }}
                      position="relative"
                      bg={"white"}
                      border="1px solid"
                      borderColor={"gray.200"}
                      mx={isMobile ? 4 : 0}
                      mt={isMobile ? 2 : 0}
                      zIndex={2000}
                    >
                      <PopoverArrow
                        bg={"white"}
                        borderColor={"gray.200"}
                      />
                      <PopoverBody p={4}>
                        <VStack spacing={2} align="stretch">
                          <Text
                            fontSize="sm"
                            fontWeight="bold"
                            color="purple.600"
                            mb={1}
                          >
                            AI Text Generation
                          </Text>
                          <Button
                            size="sm"
                            leftIcon={<Wand2 size={14} />}
                            onClick={() => handleGenerate('generate')}
                            isLoading={isGenerating}
                            colorScheme="purple"
                            width="100%"
                          >
                            Generate New
                          </Button>
                          {formData[name] && (
                            <SimpleGrid columns={2} spacing={2}>
                              <Button
                                size="sm"
                                onClick={() => handleGenerate('longer')}
                                isLoading={isGenerating}
                                variant="outline"
                                colorScheme="purple"
                              >
                                Longer
                              </Button>
                              <Button
                                size="sm"
                                onClick={() => handleGenerate('shorter')}
                                isLoading={isGenerating}
                                variant="outline"
                                colorScheme="purple"
                              >
                                Shorter
                              </Button>
                              <Button
                                size="sm"
                                onClick={() => handleGenerate('rewrite')}
                                isLoading={isGenerating}
                                variant="outline"
                                colorScheme="purple"
                              >
                                Rewrite
                              </Button>
                              <Button
                                size="sm"
                                onClick={() => handleGenerate('improve')}
                                isLoading={isGenerating}
                                variant="outline"
                                colorScheme="purple"
                              >
                                Improve
                              </Button>
                            </SimpleGrid>
                          )}
                        </VStack>
                      </PopoverBody>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>
            </Box>
          </InputRightElement>
        </InputGroup>
        {generatedText && (
          <Box mt={2} p={3} borderRadius="md" borderWidth={1} borderColor="purple.200">
            {type === 'textarea' ? (
              <Textarea
                value={editableText}
                onChange={(e) => setEditableText(e.target.value)}
                fontSize="sm"
                mb={2}
                minH="100px"
                bg="white"
                _hover={{ bg: "white" }}
                _focus={{ bg: "white", borderColor: "purple.300" }}
              />
            ) : (
              <Input
                value={editableText}
                onChange={(e) => setEditableText(e.target.value)}
                fontSize="sm"
                mb={2}
                bg="white"
                _hover={{ bg: "white" }}
                _focus={{ bg: "white", borderColor: "purple.300" }}
              />
            )}
            <ButtonGroup size="sm" spacing={2}>
              <Button
                colorScheme="purple"
                leftIcon={<CheckIcon />}
                onClick={acceptGeneratedText}
              >
                Accept
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  setGeneratedText("");
                  setEditableText("");
                }}
              >
                Dismiss
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  setEditableText(generatedText);
                }}
                size="sm"
                leftIcon={<RepeatIcon />}
              >
                Reset
              </Button>
            </ButtonGroup>
          </Box>
        )}
        <FormErrorMessage>{errors[name] as any}</FormErrorMessage>
      </FormControl>
    );
  };

  const renderSwitchField = (label: string, name: keyof CreateHuntFormData, tooltipText: string) => (
    <FormControl display="flex" alignItems="center" justifyContent="space-between" minH="40px">
      <FormLabel mb="0" flex="1">
        {label}
        <Tooltip label={tooltipText} placement="top">
          <Icon as={InfoIcon} ml={1} w={3} h={3} />
        </Tooltip>
      </FormLabel>
      <Switch
        isChecked={formData[name] as boolean}
        onChange={(e) => setFormData((prev: any) => ({ ...prev, [name]: e.target.checked }))}
        colorScheme="purple"
      />
    </FormControl>
  );

  const renderColorPicker = () => (
    <FormControl >
      <FormLabel fontWeight="bold">Color</FormLabel>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          <Button
            w="100%"
            h="40px"
            bg={formData.color || 'gray.100'}
            _hover={{ bg: formData.color || 'gray.200' }}
            borderWidth={1}
          >
            {formData.color || 'Choose color'}
          </Button>
        </PopoverTrigger>
        <PopoverContent width="auto">
          <PopoverBody p={4}>
            <VStack spacing={4}>
              <HexColorPicker color={formData.color} onChange={handleColorChange} />
              <Input
                value={formData.color}
                onChange={(e) => handleColorChange(e.target.value)}
                placeholder="#RRGGBB"
                maxLength={7}
              />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </FormControl>
  );

  const { data: backgroundData } = useGetMediaQuery({
    variables: { id: formData.backgroundMediaId || '' },
    skip: !formData.backgroundMediaId
  });

  const { data: logoData } = useGetMediaQuery({
    variables: { id: formData.logoMediaId || '' },
    skip: !formData.logoMediaId
  });

  const renderImageUpload = (isLogo: boolean = false) => (
    <FormControl>
      <FormLabel fontWeight="bold">{isLogo ? "Logo" : "Background"}</FormLabel>
      <Box
        borderWidth={2}
        borderStyle="dashed"
        borderColor={isDragActive ? "purple.500" : "gray.300"}
        borderRadius="md"
        p={4}
        textAlign="center"
        cursor="pointer"
        transition="all 0.2s"
        _hover={{ borderColor: "purple.500" }}
        position="relative"
      >
        <input
          type="file"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              handleImageUpload(file, isLogo);
            }
          }}
          accept="image/*"
          style={{ display: 'none' }}
          id={isLogo ? "logo-upload" : "background-upload"}
        />
        <label htmlFor={isLogo ? "logo-upload" : "background-upload"}>
          {(isLogo ? isLogoUploading : isUploading) ? (
            <Spinner size="sm" />
          ) : formData[isLogo ? 'logoMediaId' : 'backgroundMediaId'] ? (
            <VStack spacing={2} position="relative" width="100%">
              <IconButton
                aria-label="Remove image"
                icon={<SmallCloseIcon />}
                size="xs"
                variant="outline"
                colorScheme="red"
                position="absolute"
                top={-2}
                right={-2}
                borderRadius="full"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFormData(prev => ({
                    ...prev,
                    [isLogo ? 'logoMediaId' : 'backgroundMediaId']: null
                  }));
                }}
                zIndex={2}
              />
              <Flex
                align="center"
                justify="center"
                width="100%"
                mt={2}
              >
                <CheckIcon color="green.500" mr={2} />
                <Text>{isLogo ? "Logo" : "Background image"} uploaded successfully</Text>
              </Flex>
              <Image
                src={isLogo ? `${logoData?.media}` : `${backgroundData?.media}`}
                alt={isLogo ? "Logo" : "Background"}
                maxH="100px"
                objectFit="contain"
              />
            </VStack>
          ) : (
            <Text>Drag and drop {isLogo ? "a logo" : "a background image"} here, or click to select</Text>
          )}
        </label>
      </Box>
    </FormControl>
  );

  const validateForm = useCallback(() => {
    const requiredFields = ['title', 'startDate', 'endDate'];
    const isValid = requiredFields.every(field => !!formData[field as keyof CreateHuntFormData]) &&
      formData.challenges.length > 0;

    if (isEditing && !formData.id) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(isValid);
  }, [formData, setIsFormValid, isEditing]);

  useEffect(() => {
    validateForm();
  }, [formData, validateForm]);

  const toast = useToast();

  const handleDateChange = (name: "startDate" | "endDate") => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const currentDate = new Date();
    const selectedDate = new Date(value);

    if (name === "startDate") {
      if (selectedDate < currentDate) {
        toast({
          title: "Invalid date",
          description: "Start date cannot be in the past",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const endDate = new Date(formData.endDate);
      if (endDate < selectedDate) {
        setFormData((prev) => ({ ...prev, endDate: value }));
      }

      // Limit end date for free users
      if (!isPremiumUser) {
        const maxEndDate = addDays(selectedDate, 7);
        if (endDate > maxEndDate) {
          setFormData((prev) => ({
            ...prev,
            endDate: format(maxEndDate, "yyyy-MM-dd'T'HH:mm")
          }));
          toast({
            title: "Date range adjusted",
            description: "Free users are limited to a 7-day tour duration",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } else if (name === "endDate") {
      const startDate = new Date(formData.startDate);
      if (selectedDate < startDate) {
        toast({
          title: "Invalid date",
          description: "End date cannot be before the start date",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Limit tour duration for free users
      if (!isPremiumUser && differenceInDays(selectedDate, startDate) > 7) {
        toast({
          title: "Invalid date range",
          description: "Free users are limited to a 7-day tour duration",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const renderDateTimeFields = () => {
    if (!isPremiumUser && isEditing) {
      return (
        <Text fontSize="sm" color="gray.500" mb={4}>
          Date and time editing is a premium feature. Please <Link color="purple.500" href="/#pricing">upgrade</Link> to modify tour duration.
        </Text>
      );
    }

    return (
      <Flex direction={{ base: "column", md: "row" }} gap={4}>
        <FormControl isRequired isInvalid={!!errors.startDate} flex={1} maxW={{ base: "100%", md: "200px" }}>
          <FormLabel fontWeight="bold">Start Date and Time</FormLabel>
          <Input
            type="datetime-local"
            name="startDate"
            value={formData.startDate}
            onChange={handleDateChange("startDate")}
            min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
            bg={inputColor}
            borderColor={inputBorderColor}
          />
          <FormErrorMessage>{errors.startDate}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.endDate} flex={1} maxW={{ base: "100%", md: "200px" }}>
          <FormLabel fontWeight="bold">End Date and Time</FormLabel>
          <Input
            type="datetime-local"
            name="endDate"
            value={formData.endDate}
            onChange={handleDateChange("endDate")}
            min={formData.startDate}
            max={!isPremiumUser && formData.startDate ? format(addDays(new Date(formData.startDate), 7), "yyyy-MM-dd'T'HH:mm") : undefined}
            bg={inputColor}
            borderColor={inputBorderColor}
          />
          <FormErrorMessage>{errors.endDate}</FormErrorMessage>
        </FormControl>
      </Flex>
    );
  };

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      p={{ base: 3, md: 6 }}
      bg={useColorModeValue("white", "gray.800")}
      shadow="md"
      height="100%"
      maxHeight={{ base: "100%", md: "calc(100vh - 200px)" }}
      overflowY="auto"
      position="relative"
      mb={{ base: 4, md: 0 }}
      sx={{
        "-webkit-overflow-scrolling": "touch",
        overscrollBehavior: "none",
        WebkitOverflowScrolling: "touch",
        paddingBottom: { base: "inherit", md: "inherit" }
      }}
    >
      <VStack spacing={6} align="stretch">
        <Grid 
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} 
          gap={{ base: 4, md: 6 }}
        >
          <GridItem colSpan={{ base: 1, md: 2 }}>
            {renderFormField('Title', 'title', 'text', true, 'Enter title')}
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            {renderFormField('Description', 'description', 'textarea', false, 'Enter description')}
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <FormControl isRequired isInvalid={!!errors.challenges}>
              <FormLabel fontWeight="bold">
                Locations
                <Tooltip label="Participants will be automatically directed to the nearest location first">
                  <Icon as={InfoIcon} ml={1} w={3} h={3} />
                </Tooltip>
              </FormLabel>
              {challengesLoading ? (
                <Spinner />
              ) : (
                <Select
                  isMulti
                  name="challenges"
                  options={challengesData?.challenges.map((challenge: Challenge) => ({
                    value: challenge.id,
                    label: challenge.title,
                  }))}
                  placeholder="Select locations"
                  value={challengesData?.challenges
                    .filter((challenge: Challenge) => formData.challenges.includes(challenge.id))
                    .map((challenge: Challenge) => ({
                      value: challenge.id,
                      label: challenge.title,
                    })) || []}
                  onChange={(selectedOptions: any) =>
                    setFormData({
                      ...formData,
                      challenges: selectedOptions.map((option: any) => option.value),
                    })
                  }
                  closeMenuOnSelect={false}
                  chakraStyles={{
                    multiValue: (provided: any) => ({
                      ...provided,
                      backgroundColor: 'purple.100',
                    }),
                    multiValueLabel: (provided: any) => ({
                      ...provided,
                      color: 'purple.800',
                      fontWeight: 'bold',
                    }),
                    multiValueRemove: (provided: any) => ({
                      ...provided,
                      color: 'purple.600',
                      ':hover': {
                        backgroundColor: 'purple.200',
                        color: 'purple.800',
                      },
                    }),
                  }}
                />
              )}
              <FormErrorMessage>
                {errors.challenges?.[0]}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            {renderImageUpload(false)} {/* Background Image */}
          </GridItem>
          <GridItem>
            {renderImageUpload(true)} {/* Logo */}
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            {renderColorPicker()}
          </GridItem>
        </Grid>

        <Divider />
        <FormLabel fontSize="lg" fontWeight="bold">
          Duration
          <Tooltip label={isPremiumUser ?
            "Set the start and end dates for your tour" :
            "Upgrade to Basic or Plus to set tour duration"}>
            <Icon as={isPremiumUser ? InfoIcon : LockIcon} ml={1} w={3} h={3} />
          </Tooltip>
        </FormLabel>
        {renderDateTimeFields()}
        {!isPremiumUser && !isEditing && (
          <Text fontSize="sm" color="gray.500">
            Free users are limited to a 7-day tour duration. <Link color="purple.500" href="https://culinary-castle.com/pricing">Upgrade to Basic or Plus</Link> for longer tours.
          </Text>
        )}

        <Divider />
        <Box mb={{ base: 2, md: 4 }}>
          <Text fontSize="lg" fontWeight="bold" color={useColorModeValue("purple.700", "white")}>Settings</Text>
          <VStack spacing={2} align="stretch" mt={2}>
            {renderSwitchField('Public Tour', 'isPublic', 'Make this tour visible to all users')}
            {renderSwitchField('Allow Reviews', 'allowReviews', 'The participants will be emailed a link to leave a review after the participant has completed the tour.')}
            {renderSwitchField('Allow Retries', 'allowRetries', 'Let participants attempt tours multiple times')}
            {formData.isPublic && renderSwitchField('Allow Invites', 'allowInvites', 'Enable participants to invite others to join')}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default TourForm;
