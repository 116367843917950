import React from "react";
import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";

type CardWrapperProps = BoxProps & {
  children: React.ReactNode;
  isHoverable?: boolean;
};

const CardWrapper = ({ children, isHoverable = false, ...props }: CardWrapperProps) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue('purple.200', 'purple.700');
  const shadowColor = useColorModeValue('rgba(136, 106, 212, 0.1)', 'rgba(136, 106, 212, 0.2)');

  return (
    <Box
      borderWidth="1px"
      borderRadius="xl"
      borderColor={borderColor}
      boxShadow={`0 4px 12px ${shadowColor}`}
      overflow="hidden"
      bg={bgColor}
      w="100%"
      mb={4}
      transition="all 0.2s ease-in-out"
      _hover={isHoverable ? {
        transform: 'translateY(-2px)',
        boxShadow: `0 8px 16px ${shadowColor}`,
        borderColor: 'purple.400'
      } : undefined}
      {...props}
    >
      {children}
    </Box>
  );
};

export default CardWrapper; 