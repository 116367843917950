import React, { useState } from 'react';
import {
    Box,
    VStack,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    useColorModeValue,
    Avatar,
    AvatarBadge,
    IconButton,
    useToast,
    Flex,
    Text,
    HStack,
    Switch,
    SimpleGrid,
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CameraIcon } from 'lucide-react';
import { CloseIcon } from '@chakra-ui/icons';
import { AuthUser, useAuth } from '../../context/auth';
import { useUpdateProfileMutation } from '../../graphql/generated';
import env from "../../env";

const SettingsPage: React.FC = () => {
    const { user, setUser, updateProfilePicture } = useAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        username: user.username || '',
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        profilePicture: user.profilePicture || '',
        mediaId: '',
        emailMessageNotifications: user.emailMessageNotifications ?? false,
        emailFeedbackNotifications: user.emailFeedbackNotifications ?? false,
        emailJoinNotifications: user.emailJoinNotifications ?? false,
        emailChallengeNotifications: user.emailChallengeNotifications ?? false,
        emailPaymentNotifications: user.emailPaymentNotifications ?? false,
        emailReportNotifications: user.emailReportNotifications ?? false,
        emailPaymentReminderNotifications: user.emailPaymentReminderNotifications ?? false,
        onboardingStep: user.onboardingStep ?? 0,
        onboardingCompletedAt: user.onboardingCompletedAt ?? null,
        firstTimeAt: user.firstTimeAt ?? null,
    });
    const toast = useToast();
    const [updateProfile] = useUpdateProfileMutation();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('purple.200', 'purple.700');
    const textColor = useColorModeValue('purple.800', 'purple.100');
    const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleImageUpload = async (file: File): Promise<string | null> => {
        try {
          const formData = new FormData();
          formData.append('operations', JSON.stringify({
            query: `
              mutation UploadMedia($files: [UploadMediaInput!]!) {
                uploadMedia(files: $files) {
                  id
                }
              }
            `,
            variables: { 
              files: [{ 
                docType: file.type, 
                file: null 
              }] 
            }
          }));
          formData.append('map', JSON.stringify({ "0": ["variables.files.0.file"] }));
          formData.append('0', file);
      
          const response = await fetch(`${env.REACT_APP_SERVER_URL}/graphql`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
            headers: {
              'Apollo-Require-Preflight': 'true',
              'x-apollo-operation-name': 'uploadMedia',
            },
          });
      
          if (!response.ok) {
            throw new Error('Server responded with an error');
          }
      
          const data = await response.json();
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.uploadMedia[0].id;
        } catch (error) {
          console.error('Error uploading file:', error);
          return null;
        }
      };

    const handleProfilePictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            
            const mediaId = await handleImageUpload(file);
            
            if (mediaId) {
                setFormData(prev => ({
                    ...prev,
                    mediaId
                }));

                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result as string;
                    setFormData(prev => ({
                        ...prev,
                        profilePicture: base64String.split(',')[1]
                    }));
                };
                reader.readAsDataURL(file);
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to upload profile picture',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await updateProfile({
                variables: {
                    input: {
                        username: formData.username,
                        email: formData.email,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        mediaId: formData.mediaId || undefined,
                        emailMessageNotifications: formData.emailMessageNotifications,
                        emailFeedbackNotifications: formData.emailFeedbackNotifications,
                        emailJoinNotifications: formData.emailJoinNotifications,
                        emailChallengeNotifications: formData.emailChallengeNotifications,
                        emailPaymentNotifications: formData.emailPaymentNotifications,
                        emailReportNotifications: formData.emailReportNotifications,
                        emailPaymentReminderNotifications: formData.emailPaymentReminderNotifications,
                        onboardingStep: formData.onboardingStep,
                        onboardingCompleted: formData.onboardingCompletedAt ? true : false,
                        isFirstTime: formData.firstTimeAt ? true : false,
                    }
                }
            });

            if (result.data?.updateProfile) {
                const updatedUser = { ...user, ...result.data.updateProfile };
                setUser(updatedUser as AuthUser);
                if (formData.profilePicture) {
                    updateProfilePicture(formData.profilePicture);
                }
                setIsEditing(false);
                toast({
                    title: 'Profile updated',
                    description: 'Your profile has been updated successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error instanceof Error ? error.message : 'Failed to update profile',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleCancel = () => {
        setFormData({
            username: user.username || '',
            email: user.email || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            profilePicture: user.profilePicture || '',
            mediaId: '',
            emailMessageNotifications: user.emailMessageNotifications ?? false,
            emailFeedbackNotifications: user.emailFeedbackNotifications ?? false,
            emailJoinNotifications: user.emailJoinNotifications ?? false,
            emailChallengeNotifications: user.emailChallengeNotifications ?? false,
            emailPaymentNotifications: user.emailPaymentNotifications ?? false,
            emailReportNotifications: user.emailReportNotifications ?? false,
            emailPaymentReminderNotifications: user.emailPaymentReminderNotifications ?? false,
            onboardingStep: user.onboardingStep ?? 0,
            onboardingCompletedAt: user.onboardingCompletedAt ?? null,
            firstTimeAt: user.firstTimeAt ?? null,
        });
        setIsEditing(false);
    };

    const handleToggle = (name: string) => {
        setFormData(prev => ({
            ...prev,
            [name]: !prev[name as keyof typeof prev],
        }));
    };

    const NotificationSettings = () => (
        <Box 
            mt={6} 
            bg="white" 
            borderRadius="lg" 
            p={6}
            boxShadow="sm"
            borderWidth="1px"
            borderColor={borderColor}
        >
            <Flex 
                direction="column" 
                gap={4}
            >
                <Flex 
                    justify="space-between" 
                    align="center"
                    pb={4}
                    borderBottom="1px"
                    borderColor={borderColor}
                >
                    <Text fontSize="lg" fontWeight="semibold" color={textColor}>
                        Email Notifications
                    </Text>
                    {isEditing && (
                        <Text fontSize="sm" color="gray.500">
                            Toggle to manage your notifications
                        </Text>
                    )}
                </Flex>
                
                <SimpleGrid 
                    columns={{ base: 1, md: 2 }} 
                    spacing={6}
                    width="100%"
                >
                    {[
                        { id: "emailMessageNotifications", label: "Messages", icon: "💬" },
                        { id: "emailFeedbackNotifications", label: "Feedback", icon: "📝" },
                        { id: "emailJoinNotifications", label: "Joins", icon: "👥" },
                        { id: "emailChallengeNotifications", label: "Visits", icon: "🎯" },
                        // { id: "emailPaymentNotifications", label: "Payments", icon: "💰" },
                        { id: "emailReportNotifications", label: "Reports", icon: "📊" },
                        { id: "emailPaymentReminderNotifications", label: "Reminders", icon: "⏰" },
                    ].map(({ id, label, icon }) => (
                        <FormControl 
                            key={id}
                            display="flex" 
                            alignItems="center"
                            bg={bgColor}
                            p={4}
                            borderRadius="md"
                            transition="all 0.2s"
                            _hover={{
                                bg: isEditing ? "gray.100" : undefined
                            }}
                        >
                            <HStack flex={1}>
                                <Text fontSize="lg" mr={2}>{icon}</Text>
                                <FormLabel htmlFor={id} mb={0} flex={1} cursor={isEditing ? "pointer" : "default"}>
                                    {label}
                                </FormLabel>
                            </HStack>
                            <Switch
                                id={id}
                                isChecked={formData[id as keyof typeof formData] as boolean}
                                onChange={() => handleToggle(id)}
                                isDisabled={!isEditing}
                                colorScheme="purple"
                                size="lg"
                            />
                        </FormControl>
                    ))}
                </SimpleGrid>
            </Flex>
        </Box>
    );

    return (
        <Box bg={bgColor} minH="100vh" p={{ base: 4, md: 8 }}>
            <VStack spacing={8} align="stretch" maxWidth="800px" mx="auto">
                <Flex justifyContent="space-between" alignItems="center">
                    <Heading size="lg" color="purple.600">Profile Settings</Heading>
                    {isEditing ? (
                        <HStack>
                            <Button
                                leftIcon={<CloseIcon />}
                                onClick={handleCancel}
                                variant="outline"
                                colorScheme="purple"
                            >
                                Cancel
                            </Button>
                            <Button
                                leftIcon={<CheckIcon />}
                                colorScheme="purple"
                                onClick={handleSubmit}
                            >
                                Save Changes
                            </Button>
                        </HStack>
                    ) : (
                        <Button
                            leftIcon={<EditIcon size={18} />}
                            onClick={() => setIsEditing(true)}
                            colorScheme="purple"
                            variant="solid"
                            size="md"
                            borderRadius="md"
                            bgGradient="linear(to-r, purple.400, purple.600)"
                            _hover={{
                                bgGradient: "linear(to-r, purple.500, purple.700)",
                                transform: "translateY(-1px)",
                                shadow: "md"
                            }}
                        >
                            Edit Profile
                        </Button>
                    )}
                </Flex>

                <Box
                    bg={cardBg}
                    borderRadius="xl"
                    borderWidth="1px"
                    borderColor={borderColor}
                    overflow="hidden"
                    boxShadow="sm"
                >
                    <Flex
                        direction="column"
                        alignItems="center"
                        bg="purple.50"
                        p={8}
                        borderBottom="1px"
                        borderColor={borderColor}
                    >
                        <Box position="relative">
                            <Avatar
                                size="2xl"
                                src={formData.profilePicture ? `${formData.profilePicture}` : undefined}
                                name={`${formData.firstName} ${formData.lastName}`}
                                bg="white"
                                color="purple.500"
                                boxShadow="lg"
                            >
                                <AvatarBadge
                                    as={IconButton}
                                    size="sm"
                                    rounded="full"
                                    top="-10px"
                                    colorScheme="purple"
                                    aria-label={isEditing ? "Change Avatar" : "Edit Profile"}
                                    icon={isEditing ? <CameraIcon size={20} /> : <EditIcon size={20} />}
                                    onClick={() => {
                                        if (isEditing) {
                                            document.getElementById('profilePictureInput')?.click();
                                        } else {
                                            setIsEditing(true);
                                        }
                                    }}
                                />
                            </Avatar>
                            <Input
                                id="profilePictureInput"
                                type="file"
                                accept="image/*"
                                onChange={handleProfilePictureChange}
                                hidden
                            />
                        </Box>
                        <VStack mt={4} spacing={1}>
                            <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                                {formData.firstName} {formData.lastName}
                            </Text>
                            <Text fontSize="md" color={secondaryTextColor}>
                                {formData.email}
                            </Text>
                        </VStack>
                    </Flex>

                    <Box p={6}>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Username</FormLabel>
                                <Input
                                    name="username"
                                    value={formData.username}
                                    onChange={handleInputChange}
                                    isReadOnly={!isEditing}
                                    bg={isEditing ? 'white' : 'gray.100'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    isReadOnly={!isEditing}
                                    bg={isEditing ? 'white' : 'gray.100'}
                                />
                            </FormControl>
                            <Flex width="100%" gap={4}>
                                <FormControl>
                                    <FormLabel>First Name</FormLabel>
                                    <Input
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        isReadOnly={!isEditing}
                                        bg={isEditing ? 'white' : 'gray.100'}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        isReadOnly={!isEditing}
                                        bg={isEditing ? 'white' : 'gray.100'}
                                    />
                                </FormControl>
                            </Flex>
                        </VStack>
                    </Box>
                </Box>

                                    <NotificationSettings />

            </VStack>
        </Box>
    );
};

export default SettingsPage;