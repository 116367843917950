import { GetMessagesThreadDocument } from '../graphql/generated';
import { useSmartPolling } from './useSmartPolling';

export const useRecentMessages = () => {
  const { data, loading, error } = useSmartPolling(
    GetMessagesThreadDocument,
    {
      participantId: "all", // Special flag to get all recent messages
      limit: 3,
      offset: 0
    },
    {
      baseInterval: 2500,
    }
  );

  const messages = data?.message || [];

  return {
    messages,
    isLoading: loading,
    error
  };
}; 