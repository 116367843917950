import React, { useState } from 'react';
import {
  VStack, Box, Flex, Text, Badge, Button, useColorModeValue, IconButton, Icon, Input, InputGroup, InputLeftElement, InputRightElement, HStack, ModalFooter, FormControl, ModalBody, FormLabel, ModalCloseButton, ModalHeader, ModalContent, ModalOverlay, Modal, Center, SimpleGrid, Skeleton, Card, CardBody, Progress, Divider, Tooltip,
  Heading
} from '@chakra-ui/react';
import { FaTrash, FaCompass, FaPlus, FaSearch, FaTimes, FaShare, FaCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import CreateHuntDrawer from '../../components/tour-drawer';
import { useRemoveHuntMutation, useGetHuntsQuery, GetHuntsDocument } from '../../graphql/generated';
import { useAuth } from '../../context/auth';
import { AlertTriangle } from 'lucide-react';
import TourListSkeleton from '../../components/shared/tour-list-skeleton';
import Banner from "../../components/shared/banner";
import { Calendar, Clock } from 'lucide-react';
import { format, isAfter, isBefore, isToday } from 'date-fns';
import ShareModal from '../../components/share-tour-modal';
import env from '../../env';
import { useSmartPolling } from '../../hooks/useSmartPolling';

type RemoveModalProps = {
  isOpen: boolean;
  onClose: () => void;
  huntId: string;
  huntTitle: string;
  removeHuntTitle: string;
  setRemoveHuntTitle: (value: string) => void;
  onConfirm: (huntId: string, huntTitle: string) => void;
};

const RemoveModal: React.FC<RemoveModalProps> = ({
  isOpen,
  onClose,
  huntId,
  huntTitle,
  removeHuntTitle,
  setRemoveHuntTitle,
  onConfirm
}) => {
  const modalBg = useColorModeValue('white', 'purple.800');
  const borderColor = useColorModeValue('purple.200', 'purple.600');
  const warningBg = useColorModeValue('red.50', 'red.900');
  const inputBorderColor = useColorModeValue('purple.300', 'purple.600');

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size={{ base: "sm", md: "xl" }}
      isCentered
    >
      <ModalOverlay 
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
      />
      <ModalContent
        bg={modalBg}
        borderRadius="xl"
        mx={4}
      >
        <ModalHeader 
          borderBottom="1px" 
          borderColor={borderColor}
          py={3}
          px={4}
        >
          <Flex align="center">
            <Icon as={AlertTriangle} mr={2} color="red.500" boxSize={5} />
            <Text fontSize={{ base: "md", md: "xl" }}>Remove Tour</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton size="md" />

        <ModalBody py={4} px={4}>
          <VStack spacing={3} align="stretch">
            <Text fontSize={{ base: "sm", md: "md" }}>
              Are you sure you want to remove this tour? This action cannot be undone.
            </Text>
            <Box
              p={3}
              bg={warningBg}
              borderRadius="md"
              borderWidth="1px"
              borderColor="red.500"
            >
              <Text color="red.500" fontSize={{ base: "xs", md: "sm" }} fontWeight="medium">
                Warning: All data associated with this tour will be permanently deleted.
              </Text>
            </Box>
            <FormControl>
              <FormLabel fontSize={{ base: "sm", md: "md" }}>
                Enter "{huntTitle}" to confirm deletion:
              </FormLabel>
              <Input
                size={{ base: "sm", md: "md" }}
                value={removeHuntTitle}
                onChange={(e) => setRemoveHuntTitle(e.target.value)}
                placeholder={huntTitle}
                borderColor={inputBorderColor}
                _hover={{ borderColor: 'red.400' }}
                _focus={{ borderColor: 'red.400', boxShadow: '0 0 0 1px var(--chakra-colors-red-400)' }}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter 
          borderTop="1px" 
          borderColor={borderColor}
          py={3}
          px={4}
        >
          <Button
            variant="ghost"
            mr={3}
            size={{ base: "sm", md: "md" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            size={{ base: "sm", md: "md" }}
            onClick={() => onConfirm(huntId, huntTitle)}
            isDisabled={removeHuntTitle !== huntTitle}
          >
            Delete Tour
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const useShareTour = () => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareData, setShareData] = useState<{ id: string, title: string }>({ id: '', title: '' });

  const handleShareTour = (huntId: string, huntTitle: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setShareData({ id: huntId, title: huntTitle });
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
    setShareData({ id: '', title: '' });
  };

  return {
    isShareModalOpen,
    shareData,
    handleShareTour,
    closeShareModal
  };
};

const getTourStatus = (startDate: string, endDate: string) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isBefore(now, start)) return { label: "Upcoming", color: "blue" };
  if (isAfter(now, end)) return { label: "Completed", color: "gray" };
  if (isToday(start)) return { label: "Starting Today", color: "green" };
  if (isToday(end)) return { label: "Ending Today", color: "orange" };
  return { label: "Active", color: "green" };
};

const ToursPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateHuntDrawerOpen, setIsCreateHuntDrawerOpen] = useState(false);
  const [huntToRemove, setHuntToRemove] = useState<{ huntId: string, huntTitle: string } | null>(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [removeHuntTitle, setRemoveHuntTitle] = useState('');
  const [removeHunt] = useRemoveHuntMutation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerProps, setBannerProps] = useState<{
    type: "success" | "error" | "info";
    title: string;
    message: string;
  }>({
    type: "success",
    title: "",
    message: ""
  });
  const { isShareModalOpen, shareData, handleShareTour, closeShareModal } = useShareTour();
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('purple.200', 'purple.600');
  const textColor = useColorModeValue('purple.800', 'white');
  const secondaryTextColor = useColorModeValue('gray.800', 'gray.400');
  const searchBgColor = useColorModeValue('white', 'gray.700');
  const searchBorderColor = useColorModeValue('gray.300', 'gray.600');
  const searchIconColor = useColorModeValue('gray.400', 'gray.500');

  const { loading, error, data } = useSmartPolling(GetHuntsDocument, {
    limit: user.userType === 'FREE' ? 10 : user.userType === 'PLUS' ? 20 : 100,
    offset: 0
  }, {
    baseInterval: 5000, // Poll every 5 seconds
    maxInterval: 30000, // Max interval of 30 seconds
    onError: (error) => {
      console.error('Error polling tours:', error);
      setBannerProps({
        type: "error",
        title: "Connection Error",
        message: "Having trouble updating tour data. Will retry shortly."
      });
      setShowBanner(true);
    }
  });

  const tourCount = data?.hunts?.length || 0;
  const maxTours = user.userType === 'FREE' ? 3 : user.userType === 'BASIC' ? 10 : user.userType === 'PLUS' ? 20 : 100;

  const filteredTours = data?.hunts.filter((hunt: any) => {
    const matchesSearch = hunt.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          hunt?.description?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = !activeFilter || getTourStatus(hunt.startDate, hunt.endDate).label === activeFilter;
    return matchesSearch && matchesFilter;
  }) || [];

  const handleHuntClick = (huntId: string) => {
    navigate(`/home/tours/${huntId}`);
  };

  const handleEditHunt = (huntId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/home/tours/${huntId}`);
  };

  const handleRemoveHunt = (huntId: string, huntTitle: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setHuntToRemove({ huntId, huntTitle });
    setIsRemoveModalOpen(true);
  };

  const handleShareHunt = (huntId: string, huntTitle: string, event: React.MouseEvent) => {
    handleShareTour(huntId, huntTitle, event);
  };

  const confirmRemoveHunt = async (huntId: string, huntTitle: string) => {
    if (huntId && removeHuntTitle === huntTitle) {
      try {
        await removeHunt({ variables: { huntId } });
        setBannerProps({
          type: "success",
          title: "Tour Removed",
          message: "The tour has been successfully deleted."
        });
        setShowBanner(true);
        setIsRemoveModalOpen(false);
        setHuntToRemove(null);
        setRemoveHuntTitle('');
      } catch (error) {
        setBannerProps({
          type: "error",
          title: "Error",
          message: "Failed to remove the tour. Please try again."
        });
        setShowBanner(true);
      }
    }
  };

  if (loading) return (
    <Box p={4} bg={bgColor} minH="100vh">
      <Flex mb={6} gap={2}>
        <Skeleton height="40px" flex={1} borderRadius="md" />
        <Skeleton height="40px" width="40px" borderRadius="md" />
      </Flex>
      <TourListSkeleton />
    </Box>
  );

  if (error) return (
    <Center height="100vh">
      <Text color="red.500">Error loading tours: {error.message}</Text>
    </Center>
  );

  return (
    <Box p={4} bg={bgColor} minH="100vh">
      <Banner
        isVisible={showBanner}
        onClose={() => setShowBanner(false)}
        title={bannerProps.title}
        message={bannerProps.message}
        type={bannerProps.type}
      />
      <Box mb={8}>
        <VStack spacing={4} align="stretch">
          <Flex 
            justify="space-between" 
            align="center" 
            wrap={{ base: "wrap", md: "nowrap" }}
            gap={4}
          >
            <Heading 
              size={{ base: "lg", md: "xl" }}
              bgGradient="linear(to-r, purple.600, pink.500)"
              bgClip="text"
            >
              My Tours
            </Heading>
            {tourCount < maxTours && (
              <Button
                onClick={() => setIsCreateHuntDrawerOpen(true)}
                colorScheme="purple"
                size={{ base: "sm", md: "md" }}
                leftIcon={<Icon as={FaPlus} />}
                bgGradient="linear(to-r, purple.400, purple.600)"
                _hover={{
                  bgGradient: "linear(to-r, purple.500, purple.700)",
                  transform: "translateY(-1px)",
                  shadow: "md"
                }}
              >
                Add New Tour
              </Button>
            )}
          </Flex>

          <InputGroup size={{ base: "md", md: "lg" }}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color={searchIconColor} />
            </InputLeftElement>
            <Input
              placeholder="Search tours by title or description..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              bg={searchBgColor}
              borderColor={searchBorderColor}
              _hover={{ borderColor: 'purple.400' }}
              _focus={{ 
                borderColor: 'purple.400', 
                boxShadow: '0 0 0 1px var(--chakra-colors-purple-400)' 
              }}
              borderRadius="lg"
              fontSize={{ base: "sm", md: "md" }}
            />
            {searchTerm && (
              <InputRightElement>
                <IconButton
                  size="sm"
                  onClick={() => setSearchTerm('')}
                  icon={<FaTimes />}
                  variant="ghost"
                  colorScheme="purple"
                  aria-label="Clear search"
                />
              </InputRightElement>
            )}
          </InputGroup>

          {/* Optional: Add filter chips/tags here */}
          <HStack spacing={2} flexWrap="wrap">
            <Text fontSize="sm" color={secondaryTextColor}>
              Quick Filters:
            </Text>
            {["Active", "Upcoming", "Completed"].map((filter) => (
              <Button
                key={filter}
                size="sm"
                variant={activeFilter === filter ? "solid" : "outline"}
                colorScheme="purple"
                borderRadius="full"
                onClick={() => setActiveFilter(activeFilter === filter ? null : filter)}
              >
                {filter}
              </Button>
            ))}
          </HStack>
        </VStack>
      </Box>

      {filteredTours.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minH="60vh"
          p={8}
          textAlign="center"
        >
          <Icon as={FaCompass} boxSize={12} color="gray.400" mb={4} />
          <Text fontSize="xl" fontWeight="medium" color="gray.600" mb={2}>
            No Tours Yet
          </Text>
          <Text color="gray.500">
            Start your culinary journey by creating your first food tour
          </Text>
        </Box>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {filteredTours.map((hunt: any) => {
            const status = getTourStatus(hunt.startDate, hunt.endDate);
            const progressColor = hunt.progress >= 75 ? "green" : hunt.progress >= 50 ? "blue" : "purple";
            
            return (
              <Card
                key={hunt.id}
                bg={cardBgColor}
                borderRadius="xl"
                boxShadow="lg"
                overflow="hidden"
                transition="all 0.3s"
                _hover={{ 
                  transform: 'translateY(-4px)', 
                  shadow: 'xl',
                  borderColor: 'purple.400' 
                }}
                cursor="pointer"
                onClick={() => handleHuntClick(hunt.id)}
                position="relative"
                borderWidth="1px"
                borderColor={borderColor}
              >
                {/* Status Badge - Positioned absolutely */}
                <Badge
                  position="absolute"
                  top={4}
                  right={4}
                  colorScheme={status.color}
                  borderRadius="full"
                  px={3}
                  py={1}
                  fontSize="xs"
                  textTransform="uppercase"
                  letterSpacing="wider"
                >
                  {status.label}
                </Badge>

                <CardBody p={6}>
                  <VStack align="stretch" spacing={4}>
                    {/* Title and Public/Private Badge */}
                    <VStack align="start" spacing={2}>
                      <Text 
                        fontWeight="bold" 
                        fontSize={{ base: "lg", md: "xl" }}
                        color={textColor}
                        noOfLines={1}
                        pr={20} // Make room for status badge
                      >
                        {hunt.title}
                      </Text>
                      <HStack spacing={2}>
                        <Badge
                          colorScheme={hunt.isPublic ? "green" : "purple"}
                          borderRadius="full"
                          px={2}
                          py={0.5}
                          fontSize="xs"
                        >
                          {hunt.isPublic ? "Public" : "Private"}
                        </Badge>
                        <Text fontSize="sm" color={secondaryTextColor}>
                          {hunt.challengeCount} locations
                        </Text>
                      </HStack>
                    </VStack>

                    {/* Description */}
                    <Text 
                      color={secondaryTextColor} 
                      fontSize="sm"
                      noOfLines={2}
                    >
                      {hunt.description}
                    </Text>

                    <Divider />

                    {/* Progress Section */}
                    <VStack align="stretch" spacing={2}>
                      <HStack justify="space-between">
                        <Text fontSize="sm" fontWeight="medium" color={textColor}>
                          Tour Progress
                        </Text>
                        <Text fontSize="sm" color={`${progressColor}.500`} fontWeight="bold">
                          {hunt.progress}%
                        </Text>
                      </HStack>
                      <Progress 
                        value={hunt.progress} 
                        size="sm" 
                        colorScheme={progressColor}
                        borderRadius="full"
                        hasStripe
                        isAnimated
                      />
                    </VStack>

                    {/* Stats Grid */}
                    <SimpleGrid columns={2} spacing={4}>
                      <VStack align="start" spacing={1}>
                        <HStack spacing={2}>
                          <Icon as={Calendar} boxSize={4} color={`${status.color}.500`} />
                          <Text fontSize="xs" color={secondaryTextColor}>
                            Start Date
                          </Text>
                        </HStack>
                        <Text fontSize="sm" fontWeight="medium" color={textColor}>
                          {format(new Date(hunt.startDate), 'MMM dd, yyyy')}
                        </Text>
                      </VStack>
                      <VStack align="start" spacing={1}>
                        <HStack spacing={2}>
                          <Icon as={Clock} boxSize={4} color={`${status.color}.500`} />
                          <Text fontSize="xs" color={secondaryTextColor}>
                            End Date
                          </Text>
                        </HStack>
                        <Text fontSize="sm" fontWeight="medium" color={textColor}>
                          {format(new Date(hunt.endDate), 'MMM dd, yyyy')}
                        </Text>
                      </VStack>
                    </SimpleGrid>

                    {/* Participants Preview */}
                    <HStack justify="space-between" align="center">
                      <Text fontSize="sm" color={secondaryTextColor}>
                        {hunt.participantCount} {hunt.participantCount === 1 ? 'participant' : 'participants'}
                      </Text>
                    </HStack>

                    {/* Action Buttons */}
                    <HStack spacing={2} justify="flex-end" pt={2}>
                      {hunt.isPublic && (
                        <Tooltip label="Share Tour" hasArrow>
                          <IconButton
                            aria-label="Share tour"
                            icon={<FaShare />}
                            size="sm"
                            colorScheme="blue"
                            variant="ghost"
                            onClick={(e) => handleShareHunt(hunt.id, hunt.title, e)}
                          />
                        </Tooltip>
                      )}
                      <Tooltip label="Manage Tour" hasArrow>
                        <IconButton
                          aria-label="Manage tour"
                          icon={<FaCog />}
                          size="sm"
                          variant="ghost"
                          colorScheme="purple"
                          onClick={(e) => handleEditHunt(hunt.id, e)}
                        />
                      </Tooltip>
                      {user.userType !== 'FREE' && (
                        <Tooltip label="Remove Tour" hasArrow>
                          <IconButton
                            aria-label="Remove tour"
                            icon={<FaTrash />}
                            size="sm"
                            colorScheme="red"
                            variant="ghost"
                            onClick={(e) => handleRemoveHunt(hunt.id, hunt.title, e)}
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </VStack>
                </CardBody>
              </Card>
            );
          })}
        </SimpleGrid>
      )}

      <ShareModal
        isOpen={isShareModalOpen}
        onClose={closeShareModal}
        shareLink={`${env.REACT_APP_EXPLORER_URL}/${shareData.id}/join`}
        color={"purple.600"}
      />

      {/* Keep existing RemoveModal and CreateHuntDrawer */}
      {huntToRemove && (
        <RemoveModal
          isOpen={isRemoveModalOpen}
          onClose={() => {
            setIsRemoveModalOpen(false);
            setHuntToRemove(null);
            setRemoveHuntTitle('');
          }}
          huntId={huntToRemove.huntId}
          huntTitle={huntToRemove.huntTitle}
          removeHuntTitle={removeHuntTitle}
          setRemoveHuntTitle={setRemoveHuntTitle}
          onConfirm={confirmRemoveHunt}
        />
      )}
      <CreateHuntDrawer
        isOpen={isCreateHuntDrawerOpen}
        onClose={() => setIsCreateHuntDrawerOpen(false)}
      />
    </Box>
  );
};

export default ToursPage;