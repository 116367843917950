import React, { useState } from 'react';
import {
    Box, Flex, VStack, Text, Button, Avatar,
    useColorModeValue, Icon, useBreakpointValue, Badge,
    Divider, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, SimpleGrid, Textarea
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MessageSquare} from 'lucide-react';
import { format } from 'date-fns';
import CardSkeleton from './shared/card-skeleton';
import { useLoading } from "../context/loading";
import CardHeader from "./shared/card-header";
import CardWrapper from "./shared/card-wrapper";
import { useRecentMessages } from '../hooks/useRecentMessages';
import { Message, useMarkMessagesAsReadMutation, useSendMessageToParticipantMutation } from '../graphql/generated';
import { useToast } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import { motion } from 'framer-motion';

const formatMessageDate = (timestamp: string): string => {
    return format(new Date(timestamp), "MMM d, h:mm a");
};

const MessageModal = ({ isOpen, onClose, recipientEmail, huntId, originalMessage }: {
    isOpen: boolean;
    onClose: () => void;
    recipientEmail?: string;
    huntId: string;
    originalMessage?: string;
}) => {
    const [message, setMessage] = useState("");
    const toast = useToast();
    const [sendMessage] = useSendMessageToParticipantMutation();
    const [isMobile] = useMediaQuery("(max-width: 48em)");

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
const secondaryTextColor = useColorModeValue("gray.600", "gray.400");
    const handleSend = async () => {
        if (!message.trim()) {
            toast({
                title: "Invalid input",
                description: "Please enter a message.",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return;
        }

        try {
            await sendMessage({
                variables: {
                    huntId,
                    participantEmail: recipientEmail!,
                    content: message.trim()
                }
            });

            toast({
                title: "Message sent",
                description: "Message sent successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setMessage("");
            onClose();
        } catch (error) {
            toast({
                title: "Error sending message",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size={isMobile ? "sm" : "2xl"}
        >
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px)"
            />
            <ModalContent
                bg={bgColor}
                borderRadius="xl"
                boxShadow="xl"
                mx={isMobile ? 4 : "auto"}
            >
                <ModalHeader
                    borderBottom="1px"
                    borderColor={borderColor}
                    py={isMobile ? 3 : 4}
                    px={isMobile ? 4 : 6}
                >
                    <Flex align="center">
                        <Icon as={MessageSquare} mr={2} color="purple.500" />
                        <Text fontSize={isMobile ? "md" : "xl"}>Send Message</Text>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody py={isMobile ? 4 : 6} px={isMobile ? 4 : 6}>
                    <VStack spacing={6} align="stretch">
                        {originalMessage && (
                            <Box
                                p={3}
                                bg={bgColor}
                                borderRadius="md"
                                borderLeft="4px"
                                borderColor={borderColor}
                            >
                                <Text fontSize="sm" color={secondaryTextColor}>
                                    Reply to:
                                </Text>
                                <Text mt={1}>{originalMessage}</Text>
                            </Box>
                        )}
                        
                        <Box>
                            <Text mb={2} fontWeight="medium" color="purple.500" fontSize={isMobile ? "sm" : "md"}>
                                Send a message to {recipientEmail}
                            </Text>
                            <Textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type your message here..."
                                size={isMobile ? "md" : "lg"}
                                minH="150px"
                                borderColor={borderColor}
                                _hover={{ borderColor: 'purple.400' }}
                                _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)' }}
                            />
                        </Box>

                        <Box>
                            <Text mb={3} fontWeight="medium" color="purple.500" fontSize={isMobile ? "sm" : "md"}>
                                Quick Responses
                            </Text>
                            <SimpleGrid columns={2} spacing={2}>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("Thank you for participating in our food tour!")}
                                >
                                    Thank You
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("How are you enjoying the food tour so far?")}
                                >
                                    Check-in
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("Don't forget to complete the remaining stops!")}
                                >
                                    Reminder
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("Please let us know if you need any assistance.")}
                                >
                                    Support
                                </Button>
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </ModalBody>

                <ModalFooter
                    borderTop="1px"
                    borderColor={borderColor}
                    py={isMobile ? 3 : 4}
                    px={isMobile ? 4 : 6}
                >
                    <Button
                        variant="outline"
                        mr={3}
                        onClick={onClose}
                        size={isMobile ? "md" : "lg"}
                    >
                        Cancel
                    </Button>
                    <Button
                        colorScheme="purple"
                        onClick={handleSend}
                        isDisabled={!message.trim()}
                        size={isMobile ? "md" : "lg"}
                        leftIcon={<Icon as={MessageSquare} />}
                    >
                        {isMobile ? "Send" : "Send Message"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const ChatMessageCard = ({
    message,
    handleReply,
    isMobile,
    onMessageClick
}: {
    message: Message;
    handleReply: (message: Message) => void;
    isMobile: boolean;
    onMessageClick: (message: Message) => void;
}) => (
    <Flex
        p={4}
        direction={isMobile ? "column" : "row"}
        gap={isMobile ? 3 : 0}
        _hover={{ bg: useColorModeValue("gray.50", "gray.700") }}
        cursor="pointer"
        as={motion.div}
        whileHover={{ x: 4 }}
        width="100%"
        onClick={() => onMessageClick(message)}
    >
        <Box flex="1">
            <Flex align="center" gap={3}>
                <Avatar
                    size="sm"
                    name={message.participantName || 'Anonymous'}
                    bg="purple.500"
                    color="white"
                />
                <Box>
                    <Text fontWeight="bold" fontSize="md" color={useColorModeValue("purple.800", "white")}>
                        {message.participantName || 'Anonymous'}
                    </Text>
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.300")}>
                        {message.huntTitle}
                    </Text>
                </Box>
            </Flex>
            <Text 
                fontSize="sm" 
                color={useColorModeValue("gray.600", "gray.300")}
                mt={2}
                ml={10}
            >
                {message.content}
            </Text>
        </Box>
        <Flex 
            width={isMobile ? "100%" : "auto"}
            justify={isMobile ? "space-between" : "flex-end"}
            align="center"
            gap={3}
        >
            <Badge colorScheme="purple" fontSize="xs">
                {formatMessageDate(message.createdAt)}
            </Badge>
            <Button
                leftIcon={<Icon as={MessageSquare} />}
                colorScheme="purple"
                variant="ghost"
                size="sm"
                onClick={(e) => {
                    e.stopPropagation();
                    handleReply(message);
                }}
            >
                Reply
            </Button>
        </Flex>
    </Flex>
);

const ParticipantsChat = () => {
    const secondaryTextColor = useColorModeValue("gray.600", "gray.400");
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
    const [replyMessage, setReplyMessage] = useState('');
    const { isLoading: isLoadingContext } = useLoading();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { messages, isLoading } = useRecentMessages();
    const dividerColor = useColorModeValue("purple.200", "gray.700");
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState<Message | null>(null);
    const navigate = useNavigate();
    const [markMessagesAsRead] = useMarkMessagesAsReadMutation();

    const handleReply = (message: Message) => {
        setSelectedParticipant(message);
        setIsMessageModalOpen(true);
    };

    const handleMessageClick = async (message: Message) => {
        try {
            await markMessagesAsRead({
                variables: {
                    huntId: message.huntId,
                    participantId: message.participantId
                }
            });

            navigate(`/home/messages/${message.huntId}/${message.participantId}`, {
                state: {
                    participantEmail: message.participantEmail,
                    participantId: message.participantId,
                    huntColor: "purple.500"
                }
            });
        } catch (error) {
            console.error("Error marking messages as read:", error);
        }
    };

    if (isLoading || isLoadingContext) return <CardSkeleton rows={3} />;

    return (
        <CardWrapper>
            <CardHeader title="Recent Messages"/>
            <VStack spacing={0} align="stretch" divider={<Divider borderColor={dividerColor} />}>
                {!messages || messages.length === 0 ? (
                    <Flex 
                        p={6} 
                        direction="column" 
                        align="center" 
                        gap={3}
                    >
                        <Text color={secondaryTextColor} textAlign="center">
                            No messages found. Start a conversation to see messages here.
                        </Text>
                    </Flex>
                ) : (
                    messages.map((message: any) => (
                        <ChatMessageCard
                            key={message.id}
                            message={message}
                            handleReply={handleReply}
                            isMobile={isMobile || false}
                            onMessageClick={handleMessageClick}
                        />
                    ))
                )}
            </VStack>

            <MessageModal
                isOpen={isMessageModalOpen}
                onClose={() => {
                    setIsMessageModalOpen(false);
                    setSelectedParticipant(null);
                }}
                recipientEmail={selectedParticipant?.participantEmail}
                huntId={selectedParticipant?.huntId || ''}
                originalMessage={selectedParticipant?.content}
            />
        </CardWrapper>
    );
};

export default ParticipantsChat;
